<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table title="Vaccinations"
                          [columns]="['Vaccine','Disease Targeted','Number Of Series Doses','Next Date']"
                          [rowFields]="['vaccine','diseaseTargeted','numSeriesDoses','nextDate']"
                          [data]="vaccinations" [addComponent]="addComp" [expandComponent]="expandComp"
                          [deleteFun]="deleteFun" [isLoading]="isLoading"  [openModalFun]="openModalFun" [isPreview]="false" />
    </ng-container>
  </nz-content>
</nz-layout>
