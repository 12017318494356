import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { IAddPregnancyHistory } from '../../../../../../../interfaces/PregnancyHistory/IAddPregnancyHistory';

@Component({
  standalone: true,
  selector: 'app-pregnancy-history-add-edit',
  templateUrl: './pregnancy-history-add-edit.component.html',
  styleUrls: ['./pregnancy-history-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class PregnancyHistoryAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editPregnancyHistoryId?: number;

  pregnancyHistorySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  pregnancyHistoryForm: FormGroup = new FormGroup({});

  constructor(
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.pregnancyHistoryForm = this.formBuilder.group({
      statusValue: [null, Validators.required],
      descriptionValue: [null, Validators.required],
      outcomeDateValue: [null, Validators.required],
    });

    this.pregnancyHistoryService.pregnancyHistoryEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editPregnancyHistoryId = data?.id;

          this.pregnancyHistoryForm.patchValue({
            statusValue: data?.status,
            descriptionValue: data?.description,
            outcomeDateValue: data?.outcomeDate,
          });
        } else {
          this.isEdit = false;
          this.editPregnancyHistoryId = undefined;

          this?.pregnancyHistoryForm.reset();
        }
      });

    this.pregnancyHistoryService.pregnancyHistoryModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.pregnancyHistoryForm.reset();

    this.pregnancyHistoryService.pregnancyHistoryModalSub.next(false);

    this.pregnancyHistorySubmitted = false;

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.pregnancyHistorySubmitted = true;

    if (this.pregnancyHistoryForm.valid) {
      const pregnancyHistoryObj: IAddPregnancyHistory = {};

      pregnancyHistoryObj.id = this.editPregnancyHistoryId;
      pregnancyHistoryObj.status = this.pregnancyHistoryForm.value?.statusValue;
      pregnancyHistoryObj.description =
        this.pregnancyHistoryForm.value?.descriptionValue;
      pregnancyHistoryObj.outcomeDate =
        this.pregnancyHistoryForm.value?.outcomeDateValue;

      if (this.isEdit) {
        this.pregnancyHistoryService
          .editPregnancyHistory(pregnancyHistoryObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Pregnancy History edited successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pregnancyHistoryService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to edit Pregnancy History!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      } else {
        this.pregnancyHistoryService
          .addPregnancyHistory(pregnancyHistoryObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Pregnancy History added successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pregnancyHistoryService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to add Pregnancy History!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      }
    } else {
      this.isLoading = false;
    }
  }
}
