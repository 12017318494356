<nz-layout>
  <nz-content><ng-container>
      <app-reusable-table title="Device and Implants"
        [columns]="['Medical Device','Onset Date','Removal Date','Action']"
        [rowFields]="['medicalDevice','onSetDate','removalDate','medicalDeviceAction']" [data]="medicalDevices?.getAll"
        [dataGetList]="medicalDevices" [expandComponent]="expandComp" [addComponent]="addComp" [isLoading]="isLoading"
        [isNoKnownLoading]="isNoKnownLoading" [deleteFun]="deleteFun" [handleToggleFun]="handleToggleFun"
        [openModalFun]="openModalFun" [isPreview]="false" />
    </ng-container>
  </nz-content>
</nz-layout>