/* src/app/components/ehr/admission/admission.component.css */
.patients-search {
  margin-top: 2em;
  margin-bottom: 2em;
  height: 10%;
}
.patients-table {
  height: 90%;
  min-width: 100%;
}
.mat-mdc-form-field-focus-overlay {
  background-color: red !important;
}
.patients-search-input {
  height: 35px !important;
  margin-right: 3em;
}
@media (max-width: 1140px) {
  .patients-search-input {
    margin-right: 1em !important;
  }
}
.patients-search-buttons {
  height: 35px !important;
  margin-left: 2em;
}
.mat-mdc-input-element {
  height: 35px !important;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
::placeholder {
  color: #c2c2c2;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
}
.clear-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: white;
  color: #317d88;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-align {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}
.register-button,
.export-button {
  height: 100%;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
}
.register-button {
  background-color: #0d9488;
  border: 1px solid #0d9488;
}
.export-button {
  background: #f3f3f3 !important;
  border: 1px solid black !important;
  color: black;
}
.register-button:hover,
.export-button:hover,
.clear-button:hover {
  cursor: pointer;
}
.add-button {
  border: 1px solid #317d88;
  border-radius: 15px;
  background-color: white;
  color: #317d88;
  padding: 5px;
  font-size: 12px;
  min-width: calc(45% + 5px);
}
.add-button:hover {
  color: white;
  background-color: #317d88;
  cursor: pointer;
}
.discharge-button {
  border: 1px solid rgb(202, 79, 79);
  border-radius: 15px;
  background-color: rgb(202, 79, 79);
  color: white;
  padding: 5px;
  font-size: 12px;
  min-width: calc(45% + 5px);
}
.discharge-button:hover {
  background-color: rgb(149, 62, 62);
  cursor: pointer;
}
.waitinglist-button {
  background-color: rgb(56, 157, 224);
  color: white;
  border: 1px solid rgb(56, 157, 224);
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
  min-width: 45%;
  min-width: 115px !important;
  width: 115px;
  max-width: 115px;
}
.waitinglist-button:hover {
  background-color: rgb(42, 147, 217);
  color: white;
  cursor: pointer;
}
.preapprove-button {
  background-color: #0d9488;
  color: white;
  border: 1px solid #317d88;
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
  min-width: 115px !important;
  width: 115px;
  max-width: 115px;
}
.preapprove-button:hover {
  background-color: #317d88;
  color: white;
  cursor: pointer;
}
.full-width {
  margin-left: 0px;
  min-width: calc(90% + 5px) !important;
}
.patients-content-paginator-hide {
  display: none !important;
}
.parent-body {
  height: 100%;
}
.patients-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
}
::ng-deep .mat-mdc-paginator-outer-container {
  margin-top: 0em;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
input {
  color: black !important;
}
textarea {
  border: 1px solid #d9d9d9;
  margin-left: 1em;
  padding-left: 11px;
}
::ng-deep .mat-column-admission-actions {
  max-width: 100px !important;
  width: 100px !important;
  min-width: 100px !important;
  padding-right: 0px !important;
}
::ng-deep .mat-column-location {
  max-width: 300px !important;
  width: 300px !important;
  min-width: 100px !important;
}
::ng-deep .mat-column-patientFullName {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 100px !important;
}
::ng-deep .mat-column-sepsis {
  max-width: 150px !important;
  width: 150px !important;
  min-width: 150px !important;
}
nz-progress {
  width: 100% !important;
}
.inputs {
  width: 100%;
}
.footer {
  margin-top: 2em;
}
.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}
.cancel-btn:hover {
  background: lightgray !important;
}
.save-btn {
  margin-right: 1em;
}
.add-btn {
  margin-bottom: 1em;
}
.form-row {
  margin-top: 1em;
}
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
  margin-left: 1em;
  width: 100%;
}
nz-select {
  margin-left: 1em;
}
label {
  font-weight: 500;
}
::ng-deep .ant-modal {
  min-width: 520px !important;
  width: 50% !important;
}
.reactions-container {
  margin-top: 2em;
  padding: 1.5em;
  border: 2px solid rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}
.left-margin {
  margin-left: 1em;
}
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-arrow {
  display: none !important;
}
::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}
::ng-deep .ant-btn:focus {
  color: black;
}
::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}
::ng-deep .material-icons {
  font-size: 22px;
}
::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}
.title {
  padding-left: 1em;
  padding-right: 1em;
}
.table-header-row {
  height: 100%;
}
.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}
.no-known-content {
  display: flex !important;
}
.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}
::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}
::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}
::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}
.add-icon {
  margin-right: 0.2em;
}
@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}
.remove-btn {
  color: rgb(24, 146, 136) !important;
}
.remove-btn:hover {
  cursor: pointer;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
form {
  border: none !important;
}
.bed-submitted .ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.discharge-submitted .ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
::ng-deep .ant-modal-content {
  border-radius: 10px;
}
::ng-deep .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
}
::ng-deep nz-embed-empty {
  font-size: 16px !important;
  font-weight: 500 !important;
}
::ng-deep .ant-picker {
  margin-left: 1em !important;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}
.patient-dropdown {
  width: 100%;
}
:is() .ant-progress-inner {
  background-color: rgba(49, 125, 136, 0.2) !important;
}
:is() .ant-progress-inner {
  background-color: rgba(255, 184, 67, 0.29) !important;
}
:is() .ant-progress-inner {
  background-color: rgba(229, 86, 89, 0.25) !important;
}
.approved {
  font-weight: 500;
  color: #52be9d;
}
.preapproved {
  background-color: #fff9d4;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.waitinglist {
  font-weight: 500;
  color: #4a90e2;
}
.pending {
  font-weight: 500;
  color: #fdc55d;
}
.rejected {
  font-weight: 500;
  color: #ff4550;
}
.approved:hover {
  font-size: 16px;
}
.rejected:hover {
  font-size: 16px;
}
.approved i,
.rejected i {
  vertical-align: middle;
}
.moderate-rv {
  color: orange;
}
.severe-rv {
  color: red;
}
::ng-deep .mat-mdc-table {
  overflow-x: auto;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
.search-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-button:hover {
  cursor: pointer;
}
::ng-deep .ant-checkbox-checked ::ng-deep .ant-checkbox-inner {
  background-color: #0d9488 !important;
  border-color: #0d9488 !important;
}
::ng-deep .ant-checkbox-input:focus + ::ng-deep .ant-checkbox-inner {
  border-color: #0d9488 !important;
}
::ng-deep .ant-checkbox-input:hover + ::ng-deep .ant-checkbox-inner {
  border-color: #0d9488 !important;
}
.action-buttons-cell {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  margin-right: 0.5em;
}
.discharge-button,
.preapprove-button {
  flex: 1;
  min-width: unset;
  border-radius: 15px;
}
.add-button {
  color: #317d88;
  border: 1px solid #317d88;
  border-radius: 15px;
  background-color: transparent;
  padding: 5px;
  min-width: 75%;
  font-size: 12px;
}
.add-button:hover {
  color: white;
  background-color: #317d88;
  cursor: pointer;
}
.discharge-button {
  border: 1px solid rgb(202, 79, 79);
  background-color: rgb(202, 79, 79);
  color: white;
}
.discharge-button:hover {
  background-color: rgb(149, 62, 62);
  cursor: pointer;
}
.preapprove-button {
  background-color: #0d9488;
  color: white;
  border: 1px solid #317d88;
}
.preapprove-button:hover {
  background-color: #317d88;
  color: white;
  cursor: pointer;
}
.no-patients {
  margin-top: 15em;
  font-size: large;
}
.ml-1 {
  margin-left: 1em;
}
.anticon {
  margin-left: 2px;
}
.anticon:hover {
  cursor: pointer;
}
.custom-icon svg {
  width: 24px;
  height: 24px;
}
@media only screen and (max-width: 1279.99px) {
  .btn-text {
    display: none !important;
  }
}
[nz-icon] {
  font-size: 20px;
}
.admission-spinner {
  margin-top: 3em;
}
.firstName,
.lastName {
  font-weight: 500;
}
.dateOfBirth,
.dateOfApplication,
.age,
.daysFromFirstRegistration,
.fileRatio,
.documentNumber {
  font-weight: 400;
  font-size: smaller;
}
mat-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
/*# sourceMappingURL=admission.component-IBHRDR5N.css.map */
