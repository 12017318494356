<nz-layout>
    <nz-header *ngIf="title">{{ title | translate }}</nz-header>
    <nz-content>
        <nz-table #nzTable [nzData]="data || []" nzTableLayout="fixed" [nzShowPagination]="false" nzSize="small"
            nzNoResult="{{'No information for' | translate}} {{title | translate}}">
            <thead>
                <tr>
                    <ng-container *ngFor="let col of columns">
                        <th>{{col | translate}}
                            <app-tooltip [fieldName]="col" [area]="title" />
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of data">
                    <tr>

                        <ng-container *ngFor="let field of rowFields">
                            <td>{{ item[field] || "-"}}</td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>