<!--<div>-->

<!--  <div class="app-container" *ngIf="authenticated">-->
<!--  <app-temp></app-temp>-->
<!--  </div>-->
<!--  <app-login *ngIf="!authenticated"></app-login>-->
<!--</div>-->
<div class="app-body">
    <router-outlet></router-outlet>
</div>

<!-- <app-login></app-login> -->