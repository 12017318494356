import { createSelector, createFeatureSelector } from '@ngrx/store';
import { MenuState } from '../reducers/menu.reducer';

export const selectMenuState = createFeatureSelector<MenuState>('menu');

export const selectMenu = createSelector(
  selectMenuState,
  (state) => state.menu
);

export const selectMenuLoaded = createSelector(
  selectMenuState,
  (state: MenuState) => state.loaded // Ensure this flag is set to true when the API call completes
);
