import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IModule } from 'src/app/interfaces/IModule';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrl: './workspace.component.css',
})
export class WorkspaceComponent {
  public menuData?: IModule;
  public isCollapsed: boolean = false;

  public selectedOption?: number;

  componentPaths = paths.workspace;

  constructor(
    private readonly store: Store,
    private readonly cdref: ChangeDetectorRef
  ) {}

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  updateSelectedOption(option: number) {
    this.selectedOption = option;
  }

  clickItem(m: IModule) {
    this.updateSelectedOption(m.id);
    this.scrollToSection(m.path);
  }

  activeSubmenu(): string {
    return (
      this.menuData?.childModules?.find((d) =>
        d.childModules.find((x) => x.id === this.selectedOption)
      )?.path ||
      this.menuData?.childModules?.find((d) => d.id === this.selectedOption)
        ?.path ||
      ''
    );
  }

  getSubmenu(menu: string): IModule[] | undefined {
    return this.menuData?.childModules?.find((d) => d.path === menu)
      ?.childModules;
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      // menu, patient header, title, margin
      const offset = element.offsetTop - 120 - 66 - 64 - 16;
      window.scroll({
        top: offset,
        behavior: 'smooth',
      });
    }
  }

  ngOnInit() {
    if (window.innerWidth <= 1919) {
      this.isCollapsed = true;
      this.cdref.detectChanges();
    }

    this.store.select(selectMenu).subscribe((data) => {
      this.menuData = data.find((d) => d.path == 'workspace');

      this.selectedOption = this.menuData?.childModules?.at(0)?.id;
    });
  }
}
