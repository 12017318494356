import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CapnographyService } from 'src/app/services/capnography.service';
import Swal from 'sweetalert2';
import { CapnographyAddEditComponent } from './capnography-add-edit/capnography-add-edit.component';
import { CapnographyExpandComponent } from './capnography-expand/capnography-expand.component';
import { IAddCapnography } from 'src/app/interfaces/Capnography/IAddCapnography';
import { ICapnographyDetailed } from 'src/app/interfaces/Capnography/ICapnographyDetailed';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-capnography',
  templateUrl: './capnography.component.html',
  styleUrls: ['./capnography.component.css'],
})
export class CapnographyComponent {
  public addComp = CapnographyAddEditComponent;
  public expandComp = CapnographyExpandComponent;

  private destroy$ = new Subject<void>();

  capnography?: ICapnographyDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly capnographyService: CapnographyService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: IAddCapnography) {
    this.capnographyService.capnographyModalSub.next(true);
    this.capnographyService.capnographyEditSub.next(item);
  }

  deleteFun(capnographyId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Capnography?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.capnographyService.deleteCapnography(capnographyId).subscribe({
          next: (d) => {
            Swal.fire({
              text: 'Capnography deleted successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.capnographyService.deleteCapnographyFromTable(
              capnographyId.toString()
            );
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to delete capnography!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire('Capnography is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.capnographyService.capnographyLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.capnographyService.capnographyDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ICapnographyDetailed[]) => {
        this.capnography = data;
      });

    this.capnographyService.getData();
  }
}
