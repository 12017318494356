<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Vital Signs') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="vitalSignForm" (ngSubmit)="submitForm()"
            [ngClass]="{'vital-sign-submitted': vitalSignSubmitted, 'vital-sign-not-submitted': !vitalSignSubmitted}">

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Onset Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input class="inputs vital-sign" type="datetime-local" [placeholder]="'Onset Date' | translate"
                        formControlName="onSetValue" [max]="getCurrentDateTime()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Temperature (°C)" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs vital-sign" type="number" [placeholder]="'Temperature' | translate"
                        formControlName="temperatureValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Respiratory Rate (bpm)" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs vital-sign" type="number"
                        [placeholder]="'Respiratory Rate' | translate" formControlName="respiratoryRateValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Heart Rate (bpm)" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs vital-sign" type="number" [placeholder]="'Heart Rate' | translate"
                        formControlName="heartRateValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Systolic Blood Pressure (mm[Hg])" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs vital-sign" type="number"
                        [placeholder]="'Systolic Blood Pressure' | translate" formControlName="systolicValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Diastolic Blood Pressure (mm[Hg])" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs vital-sign" type="number"
                        [placeholder]="'Diastolic Blood Pressure' | translate" formControlName="diastolicValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"SpO2 (%)" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs vital-sign" type="number" [placeholder]="'SpO2' | translate"
                        formControlName="spo2Value" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" style="margin-top: 3em;">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="20">
                    <div style="font-size: 20px;">{{"Urine Output" | translate}}</div>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="80">
                    <hr style="width: 100%; margin-left:0.5em;" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Urine Output (ml/kg/h)" | translate}}<span class="optional">{{"(optional)" |
                            translate}}</span></label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs vital-sign" type="number" [placeholder]="'Urine Output' | translate"
                        formControlName="urineOutputValue" />
                </div>
            </div>



            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>