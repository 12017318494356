/* src/app/common/test/test.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-arrow {
  display: none !important;
}
::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}
::ng-deep .ant-btn:focus {
  color: black;
}
::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}
::ng-deep .material-icons {
  font-size: 22px;
}
::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}
.title {
  padding-left: 1em;
  padding-right: 1em;
}
.add-button {
  margin-top: 1em;
  padding-right: 1em;
}
.table-header-row {
  height: 100%;
}
.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}
.no-known-content {
  display: flex !important;
}
.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}
::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}
::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}
::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}
.add-icon {
  margin-right: 0.2em;
}
@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}
/*# sourceMappingURL=test.component-P7ESNJMF.css.map */
