import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, combineLatest, debounceTime, takeUntil } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-medication-summary-section',
  templateUrl: './medication-summary-section.component.html',
  styleUrls: ['./medication-summary-section.component.css'],
})
export class MedicationSummarySectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  private destroy$ = new Subject<void>();

  addScreen?: boolean = false;

  @Input() selectedOption?: number;
  @Output() selectedOptionChange = new EventEmitter<number>();

  ngOnInit() {
    this.currentMedicationService.medicationOpenSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.addScreen = data;
      });
  }

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.currentMedicationService.currentMedicationLoadingSub$,
        this.pastMedicationService.pastMedicationLoadingSub$,
      ]).subscribe(([value1, value2]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.id == this.selectedOption
        )?.path;
        if (!value1 && !value2 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const md = document.getElementById(paths.currentMedicines);
                const pm = document.getElementById(paths.pastMedicines);

                if (pm) {
                  const rect = pm.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.pastMedicines
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (md) {
                  const rect = md.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.currentMedicines
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pastMedicationService: PastMedicationService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }

  ngOnDestroy() {
    this.currentMedicationService.medicationOpenSub.next(false);
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleButton() {
    this.currentMedicationService.medicationEditSub.next(undefined);
    this.currentMedicationService.medicationOpenSub.next(true);
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }
}
