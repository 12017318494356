<nz-layout>
    <nz-header *ngIf="submenu?.length!<=1">
    </nz-header>
    <nz-header *ngIf="submenu?.length!>1" class="section-header">
        {{'Imaging'|translate}}
    </nz-header>
    <nz-content>
        <ng-container *ngFor="let cm of submenu">
            <app-imaging-files *ngIf="cm.path==componentPaths.imagingFiles" [id]="cm.path" />
            <app-multiple-sclerosis-segmentation *ngIf="cm.path==componentPaths.multipleSclerosisSegmentation"
                [id]="cm.path" />
        </ng-container>
    </nz-content>
</nz-layout>