/* src/app/components/ehr/dashboard/dashboard-inpatient-by-gender/dashboard-inpatient-by-gender.component.css */
.not-amen {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30vh;
  justify-content: center;
}
.amen {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30vh;
  justify-content: center;
}
.rounded-chart-labels {
  border-radius: 10px !important;
  background-color: rgba(54, 162, 235, 0.2);
  padding: 5px 10px;
}
.chart-container {
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px;
  background-color: white;
}
#inPatientGender {
  width: 100%;
  height: auto;
}
/*# sourceMappingURL=dashboard-inpatient-by-gender.component-6ZTSIC77.css.map */
