/* src/app/components/ehr/admission/admission-reject-modal/admission-reject-modal.component.css */
.reject-form {
  padding: 20px;
}
.form-row {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.label-column {
  flex: 0 0 20%;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.input-column {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
}
.full-width-select {
  width: 100%;
  margin-left: 1em;
}
.text-area {
  width: calc(100% - 1em);
  margin-left: 1em;
  border: 1px solid #d9d9d9;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
}
.optional-text {
  font-weight: 300;
  display: block;
  font-size: 12px;
  margin-top: 0.25em;
  border-radius: 1px;
}
.optional-text::selection {
  border-color: #0d9488;
  border-radius: 1px;
}
.button-row {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}
::ng-deep .ant-btn:focus {
  color: black;
}
::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}
.save-btn {
  margin-right: 1em;
}
.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}
.cancel-btn:hover {
  background: lightgray !important;
}
/*# sourceMappingURL=admission-reject-modal.component-KWSZG4GN.css.map */
