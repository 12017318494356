/* src/app/components/ehr/patients/components/cognitive-assessment-section/cognitive-assessment-section.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-layout {
  background: #f4f4f6;
  height: 100%;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
.comorbidity {
}
.content {
  width: 100%;
}
/*# sourceMappingURL=cognitive-assessment-section.component-HW62ZI2V.css.map */
