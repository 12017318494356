<nz-layout>
  <nz-header>
    {{'Hysteroscopy'|translate}}
  </nz-header>
  <nz-content>
    <!-- <app-hysteroscopy-examination></app-hysteroscopy-examination> -->
    <app-hysteroscopy-start-examination></app-hysteroscopy-start-examination>
    <app-hysteroscopy-file></app-hysteroscopy-file>
  </nz-content>
</nz-layout>
