import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IModule } from 'src/app/interfaces/IModule';
import { IPatientDetailed } from 'src/app/interfaces/Detailed/IPatientDetailed';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { PatientService } from 'src/app/services/patient.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { paths } from 'src/component-paths/component-paths';
import { modules } from 'src/module-ids/module-ids';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent {
  public menuData?: IModule;
  public isCollapsed: boolean = false;

  public selectedOption?: number;

  componentPaths = paths;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly patientService: PatientService,
    private readonly store: Store,
    private readonly eocService: EpisodeOfCareService
  ) {}

  public patientID: string = '';

  public patientInfo?: IPatientDetailed;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  updateSelectedOption(option: number) {
    this.selectedOption = option;
  }

  clickItem(m: IModule) {
    if (m.path != this.componentPaths.pilotStudy) {
      this.updateSelectedOption(m.id);
      this.scrollToSection(m.path);
    }
  }

  activeSubmenu(): string {
    return (
      this.menuData?.childModules?.find((d) =>
        d.childModules.find((x) => x.id === this.selectedOption)
      )?.path ||
      this.menuData?.childModules?.find((d) => d.id === this.selectedOption)
        ?.path ||
      ''
    );
  }

  getSubmenu(menu: string): IModule[] | undefined {
    return this.menuData?.childModules?.find((d) => d.path === menu)
      ?.childModules;
  }

  getGynecologySubMenu(menu: string): IModule[] | undefined {
    const foundModule = this.menuData?.childModules?.find(
      (d) => d.path === menu
    )?.childModules;

    if (foundModule) {
      const childWithSubmodules = foundModule.find(
        (child) => child.childModules && child.childModules.length > 0
      );
      return childWithSubmodules
        ? childWithSubmodules.childModules
        : foundModule;
    }
    return undefined;
  }

  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      // menu, patient header, title, margin
      const offset = element.offsetTop - 120 - 66 - 64 - 16;
      window.scroll({
        top: offset,
        behavior: 'smooth',
      });
    }
  }

  ngOnInit() {
    if (window.innerWidth <= 1919) {
      this.isCollapsed = true;
    }

    // Reset Episode of Care and Visit ID upon rendering
    // the profile component
    this.eocService.currentEocId.set('');
    this.eocService.currentVisitId.set('');

    this.patientService.patientInfoSub.next({});
    this.patientService.patientLoadingSub.next(true);

    this.route.queryParams.subscribe((params) => {
      this.patientID = params['patientID'];
    });

    this.patientService.patientIDsignal.set(this.patientID);

    this.patientService.getPatientByID(this.patientID).subscribe({
      next: (response) => {
        this.patientService.patientInfoSub.next(response?.data!);
        this.patientService.patientLoadingSub.next(false);
        this.patientInfo = response?.data; // Assuming data is the property you want

        // Get profile module
        // Set selected option
        this.store.select(selectMenu).subscribe((data) => {
          this.menuData = data
            .find((d) => d.path == 'allPatients')
            ?.childModules?.find((d) => d.path === 'profile');

          // If not imported
          // Remove IPS preview TAB (ID: 26)
          if (!this.patientInfo?.isImported && this?.menuData?.childModules) {
            this.menuData = {
              ...this.menuData,
              childModules:
                this.menuData?.childModules?.filter(
                  (c) => c?.id != modules.ipsPreview
                ) || [],
            };
          }

          // If not female
          // Remove Gynecology TAB (ID: 51)
          if (
            this.patientInfo?.gender?.toLowerCase() != 'female' &&
            this?.menuData?.childModules
          ) {
            this.menuData = {
              ...this.menuData,
              childModules:
                this.menuData?.childModules?.filter(
                  (c) => c.id != modules.gynecology
                ) || [],
            };
          }

          // If not inpatient
          // Remove Daily Monitoring TAB (ID: 28)
          // Remove Etiology TAB (ID: 45)
          // Remove Complication TAB (ID: 46)
          // Remove Tracheostomy 2 TAB (ID: 49) ?
          this.menuData = {
            id: this.menuData?.id!,
            name: this.menuData?.name!,
            description: this.menuData?.description!,
            icon: this.menuData?.icon!,
            path: this.menuData?.path!,
            orderBy: this.menuData?.orderBy!,
            isActive: this.menuData?.isActive!,
            ...this.menuData,
            childModules:
              this.menuData?.childModules?.filter(
                (c) =>
                  c.id != modules.dailyMonitoring &&
                  c.id != modules.etiology &&
                  c.id != modules.complication
              ) || [],
          };
          // if the first option is pilot study (44)
          // have tracheostomy2 (49) as preselcted
          if (this.menuData?.childModules?.at(0)?.id == modules.pilotStudy) {
            this.selectedOption = modules.tracheostomy2;
          } else if (
            this.menuData?.childModules?.at(0)?.childModules.length! == 0
          ) {
            this.selectedOption = this.menuData?.childModules?.at(0)?.id;
          } else {
            this.selectedOption =
              this.menuData?.childModules?.at(0)?.childModules?.at(0)?.id ||
              this.menuData?.childModules?.at(0)?.id ||
              this.menuData?.id ||
              0;
          }
        });
      },
      error: (err) => {
        console.error('Error fetching patient information:', err);
      },
    });
  }
}
