import { Component } from '@angular/core';
import { TubeCharacteristicsAddEditComponent } from './tube-characteristics-add-edit/tube-characteristics-add-edit.component';
import { Subject, takeUntil } from 'rxjs';
import { ITubeCharacteristicsDetailed } from 'src/app/interfaces/TubeCharacteristics/ITubeCharacteristicsDetailed';
import { TubeCharacteristicsService } from 'src/app/services/tubeCharacteristics.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tube-characteristics',
  templateUrl: './tube-characteristics.component.html',
  styleUrls: ['./tube-characteristics.component.css'],
})
export class TubeCharacteristicsComponent {
  public addComp = TubeCharacteristicsAddEditComponent;

  private destroy$ = new Subject<void>();

  tubeCharacteristics?: ITubeCharacteristicsDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly tubeCharacteristicsService: TubeCharacteristicsService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: ITubeCharacteristicsDetailed) {
    this.tubeCharacteristicsService.tubeCharacteristicsModalSub.next(true);
    this.tubeCharacteristicsService.tubeCharacteristicsEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Tube Characteristic')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.tubeCharacteristicsService
          .deleteTubeCharacteristics(id.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Tube Characteristics record deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.tubeCharacteristicsService.deleteTubeCharacteristicsFromTable(
                id.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Tube Characteristics record!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Tube Characteristics record is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.tubeCharacteristicsService.tubeCharacteristicsLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.tubeCharacteristicsService.tubeCharacteristicsDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ITubeCharacteristicsDetailed[]) => {
        this.tubeCharacteristics = data;
      });

    this.tubeCharacteristicsService.getData();
  }
}
