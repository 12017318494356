import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { IPregnancyStatusDetailed } from '../../../../../../interfaces/PregnancyStatus/IPregnancyStatusDetailed';
import { PregnancyStatusAddEditComponent } from '../pregnancy-status/pregnancy-status-add-edit/pregnancy-status-add-edit.component';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pregnancy-status',
  templateUrl: './pregnancy-status.component.html',
  styleUrls: ['./pregnancy-status.component.css'],
})
export class PregnancyStatusComponent {
  public addComp = PregnancyStatusAddEditComponent;
  // public expandComp = PregnancyStatusExpandComponent;

  private destroy$ = new Subject<void>();

  pregnancyStatus?: IPregnancyStatusDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.pregnancyStatusService.pregnancyStatusLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.pregnancyStatusService.pregnancyStatusDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IPregnancyStatusDetailed[]) => {
        this.pregnancyStatus = data;
      });

    this.pregnancyStatusService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IPregnancyStatusDetailed) {
    this.pregnancyStatusService.pregnancyStatusModalSub.next(true);
    this.pregnancyStatusService.pregnancyStatusEditSub.next(item);
  }

  deleteFun(pregnancyStatusId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Pregnancy Status')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.pregnancyStatusService
          .deletePregnancyStatus(pregnancyStatusId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Pregnancy Status deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pregnancyStatusService.deletePregnancyStatusFromTable(
                pregnancyStatusId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Pregnancy Status!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Pregnancy Status is not deleted', '', 'info');
      }
    });
  }

  // expandSet = new Set<number>();
  // onExpandChange(id: number, checked: boolean): void {
  //   if (checked) {
  //     this.expandSet.add(id);
  //   } else {
  //     this.expandSet.delete(id);
  //   }
  // }
}
