import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { IAddPregnancyOutcome } from '../../../../../../../interfaces/PregnancyOutcome/IAddPregnancyOutcome';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  standalone: true,
  selector: 'app-pregnancy-status-add-edit',
  templateUrl: './pregnancy-outcome-add-edit.component.html',
  styleUrls: ['./pregnancy-outcome-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class PregnancyOutcomeAddEditComponent {
  private destroy$ = new Subject<void>();

  public outcomeDropdown?: ICoding[];
  isVisible = false;
  isLoading = false;
  isDropdownLoading = false;

  isEdit = false;
  editPregnancyOutcomeId?: number;

  pregnancyOutcomeSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  pregnancyOutcomeForm: FormGroup = new FormGroup({});

  constructor(
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isDropdownLoading = true;
    this.isEdit = false;

    this.pregnancyOutcomeForm = this.formBuilder.group({
      outcomeValue: [null, Validators.required],
      numberOfChildrenValue: [null, Validators.required],
    });

    this.pregnancyOutcomeService.getDropdownData().subscribe({
      next: (res) => {
        this.outcomeDropdown = res?.data?.outcome;
        this.isDropdownLoading = false;
      },
      error: (err) => {
        console.error('Error fetching dropdown data:', err);
      },
    });

    this.pregnancyOutcomeService.pregnancyOutcomeEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editPregnancyOutcomeId = data?.id;

          this.pregnancyOutcomeForm.patchValue({
            outcomeValue: data?.outcomeId,
            numberOfChildrenValue: data?.numberOfChildren,
          });
        } else {
          this.isEdit = false;
          this.editPregnancyOutcomeId = undefined;

          this?.pregnancyOutcomeForm.reset();
        }
      });

    this.pregnancyOutcomeService.pregnancyOutcomeModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.pregnancyOutcomeForm.reset();

    this.pregnancyOutcomeService.pregnancyOutcomeModalSub.next(false);

    this.pregnancyOutcomeSubmitted = false;

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.pregnancyOutcomeSubmitted = true;

    if (this.pregnancyOutcomeForm.valid) {
      const pregnancyOutcomeObj: IAddPregnancyOutcome = {};

      pregnancyOutcomeObj.id = this.editPregnancyOutcomeId;
      pregnancyOutcomeObj.outcomeId =
        this.pregnancyOutcomeForm.value?.outcomeValue;
      pregnancyOutcomeObj.numberOfChildren =
        this.pregnancyOutcomeForm.value?.numberOfChildrenValue;

      if (this.isEdit) {
        this.pregnancyOutcomeService
          .editPregnancyOutcome(pregnancyOutcomeObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Pregnancy Outcome edited successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pregnancyOutcomeService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to edit Pregnancy Outcome!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      } else {
        this.pregnancyOutcomeService
          .addPregnancyOutcome(pregnancyOutcomeObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Pregnancy Outcome added successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pregnancyOutcomeService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to add Pregnancy Outcome!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      }
    } else {
      this.isLoading = false;
    }
  }
}
