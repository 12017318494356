/* src/app/components/ehr/patients/components/daily-monitoring-section/vital-signs/vital-signs-chart/vital-signs-chart.component.css */
#canvas {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: unset !important;
}
.chart {
  padding: 1em;
  border: 1px solid #d8d8d8;
  background: white;
}
/*# sourceMappingURL=vital-signs-chart.component-UC3XBJE7.css.map */
