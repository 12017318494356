import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Subject, combineLatest, debounceTime } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { MedicalHistoryService } from 'src/app/services/medicalHistory.service';
import { ResolvedProblemsService } from 'src/app/services/resolvedProblems.service';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-medical-history-section',
  templateUrl: './medical-history-section.component.html',
  styleUrls: ['./medical-history-section.component.css'],
})
export class MedicalHistorySectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: number;
  @Output() selectedOptionChange = new EventEmitter<number>();

  ngOnInit() {}

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.medicalHistoryService.medicalHistoryLoadingSub$,
        this.resolvedProblemsService.resolvedProblemLoadingSub$,
        this.vaccinationService.vaccinationLoadingSub$,
      ]).subscribe(([value1, value2, value3]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.id == this.selectedOption
        )?.path;
        if (!value1 && !value2 && !value3 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const va = document.getElementById(paths.vaccinations);
                const rp = document.getElementById(paths.resolvedProblems);
                const mh = document.getElementById(paths.medicalHistory);

                if (mh) {
                  const rect = mh.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.medicalHistory
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (rp) {
                  const rect = rp.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.resolvedProblems
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (va) {
                  const rect = va.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.vaccinations
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly vaccinationService: VaccinationService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
