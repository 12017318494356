import { Component } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
} from '@angular/animations';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.css',
  animations: [
    trigger('iconAnimation', [
      state(
        'hidden',
        style({
          opacity: 0,
          transform: 'translateY(-100%) scale(0.5)',
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          transform: 'translateY(0) scale(1)',
        })
      ),
      transition('hidden => visible', [
        animate(
          '700ms ease-in-out',
          keyframes([
            style({
              opacity: 0,
              transform: 'translateY(-100%) scale(0.5)',
              offset: 0,
            }),
            style({
              opacity: 1,
              transform: 'translateY(0) scale(1)',
              offset: 1,
            }),
          ])
        ),
      ]),
    ]),
  ],
})
export class NotFoundComponent {
  iconState = 'hidden';

  ngOnInit() {
    setTimeout(() => {
      this.iconState = 'visible';
    }, 100); // Delay to trigger animation after component renders
  }
}
