import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrl: './forgot-username.component.css',
})
export class ForgotUsernameComponent {
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private readonly router: Router,
    private readonly accountService: AccountService
  ) {}

  async ngOnInit() {}

  back() {
    this.router.navigate(['/login']);
  }

  forgotUsername() {
    if (this.emailControl.value)
      [
        this.accountService.forgotUsername(this.emailControl.value).subscribe({
          next: (res) => {
            Swal.fire({
              text: 'User will be notified via email!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            Swal.fire({
              text: err.error.message,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        }),
      ];
  }
}
