<div class="patient-details-container">
  <h2 class="titleBar">{{ "Patient Information" | translate }}</h2>

  <div class="personal-info">
    <div class="form-row">
      <div class="info-item" *ngIf="patient?.firstName || patient?.lastName">
        <label>{{ "Fullname:" | translate }}</label>
        <span>{{ patient?.firstName }} {{ patient?.lastName }}</span>
      </div>
      <div class="info-item" *ngIf="patient?.dateOfBirth">
        <label>{{ "Date of Birth:" | translate }}</label>
        <span>{{ patient?.dateOfBirth }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="info-item" *ngIf="patient?.email">
        <label>{{ "Email:" | translate }}</label>
        <span>{{ patient?.email }}</span>
      </div>
      <div class="info-item" *ngIf="patient?.phone">
        <label>{{ "Phone Number:" | translate }}</label>
        <span>{{ patient?.phone }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="info-item" *ngIf="patient?.gender">
        <label>{{ "Gender:" | translate }}</label>
        <span>{{ patient?.gender }}</span>
      </div>
      <div class="info-item" *ngIf="patient?.bloodType">
        <label>{{ "Blood Type:" | translate }}</label>
        <span>{{ patient?.bloodType }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="info-item" *ngIf="patient?.height">
        <label>{{ "Height:" | translate }}</label>
        <span>{{ patient?.height }} cm</span>
      </div>
      <div class="info-item" *ngIf="patient?.weight">
        <label>{{ "Weight:" | translate }}</label>
        <span>{{ patient?.weight }} kg</span>
      </div>
    </div>

    <!-- List of Assigned Doctors -->
    <div class="form-row" *ngIf="patient?.assignedDoctors?.length">
      <div class="info-item">
        <label>{{ "Assigned Doctors:" | translate }}</label>
        <div>
          <!-- Display the primary doctor first -->
          <div *ngIf="getPrimaryDoctor() as primaryDoctor">
            {{ primaryDoctor.doctorFullName }} (Primary)
          </div>
          <!-- Display the rest of the assigned doctors -->
          <div *ngFor="let doctor of getNonPrimaryDoctors()">
            {{ doctor.doctorFullName }}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="patient?.documentDto">
      <div *ngFor="let document of patient?.documentDto">
        <div class="form-row" style="padding-top: 20px">
          <div class="info-item">
            <label>{{ "Document Type:" | translate }}</label>
            <span>{{ document?.documentType }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="info-item">
            <label>{{ "Document Number:" | translate }}</label>
            <span>{{ document?.documentNumber }}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="info-item">
            <label>{{ "Document Country:" | translate }}</label>
            <span>{{ document?.documentCountryIssued }}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!patient?.documentDto">
      <p>{{ "No documentation available for this patient." | translate }}</p>
    </div>
  </div>

  <div class="address-info">
    <h3 class="infoBar">{{ "Address Information" | translate }}</h3>
    <div class="form-row">
      <div class="info-item" *ngIf="patient?.addressStreet">
        <label>Street:</label>
        <span>{{ patient?.addressStreet }}</span>
      </div>

      <div class="info-item" *ngIf="patient?.addressDistrict">
        <label>District:</label>
        <span>{{ patient?.addressDistrict }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="info-item" *ngIf="patient?.addressStreetNumber">
        <label>Street Number:</label>
        <span>{{ patient?.addressStreetNumber }}</span>
      </div>

      <div class="info-item" *ngIf="patient?.addressApartmentNumber">
        <label>Apartment Number:</label>
        <span>{{ patient?.addressApartmentNumber }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="info-item" *ngIf="patient?.addressTown">
        <label>Town:</label>
        <span>{{ patient?.addressTown }}</span>
      </div>
      <div class="info-item" *ngIf="patient?.addressPostCode">
        <label>Post Code:</label>
        <span>{{ patient?.addressPostCode }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="info-item" *ngIf="patient?.addressCountry">
        <label>Country:</label>
        <span>{{ patient?.addressCountry }}</span>
      </div>
    </div>
  </div>
</div>
