/* src/app/components/ehr/admission/admission-popup/admission-popup.component.css */
.bottom-divider {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #d8d8d8;
}
.titles {
  font-weight: 500;
}
/*# sourceMappingURL=admission-popup.component-CJ75CYBF.css.map */
