<div>
    <div fxLayout="row" fxLayout.lt-lg="column">
        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" class="left-half">
            <img src="../../../assets/images/loginScreen/loginBuilding.png" class="left-image">
        </div>


        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" fxLayoutAlign="center center">

            <div fxLayout="column" fxFlex="60">
                <div fxLayout="row" class="w-100 forgot-line" fxLayoutAlign="center center">
                    <img src="../../../assets/images/loginScreen/3aHealth.png" class="logo">
                </div>


                <div fxLayout="row" class="w-100 login-text" fxLayoutAlign="start start">
                    {{"Enter your email"|translate}}
                </div>

                <input nz-input [formControl]="emailControl" type="email" class="email" placeholder="Email" />

                <div fxLayout="row">
                    <button nz-button class="back-btn" (click)="back()">
                        <i nz-icon nzType="icons:arrow-left" nzTheme="outline"></i>
                    </button>
                    <button nz-button [disabled]="!emailControl.valid"
                        [ngClass]="{ 'invalid-login-btn': !emailControl.valid, 'login-btn': emailControl.valid }"
                        (click)="forgotUsername()">
                        {{"Forgot Username" | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>