<nz-modal
  #modalRef
  nzSize
  [(nzVisible)]="isVisible"
  [nzTitle]="'Add Event' | translate"
  (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel"
  nzOkText="Save"
  nzOkType="primary"
  [nzFooter]="null"
  class="custom-modal-class"
>
  <ng-container *nzModalContent>
    <form [formGroup]="eventForm" (ngSubmit)="submitForm()">
      <!-- Title -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "Title" | translate }}</label>
          <span class="asterisk">*</span>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input
            nz-input
            class="inputs event"
            formControlName="title"
            [placeholder]="'Enter title' | translate"
          />
        </div>
      </div>

      <!-- Description -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "Description" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <textarea
            nz-input
            rows="3"
            class="inputs event"
            formControlName="description"
            [placeholder]="'Enter description' | translate"
          ></textarea>
        </div>
      </div>

      <!-- Department -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "Department" | translate }}</label
          ><span class="asterisk">*</span>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select
            class="inputs event"
            formControlName="department"
            nzPlaceHolder="Select department"
            nzShowSearch
            nzAllowClear
          >
            <nz-option
              *ngFor="let department of departmentList"
              [nzValue]="department.id"
              [nzLabel]="department.title"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <!-- Personnel -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "Select Personnel" | translate }}</label
          ><span class="asterisk">*</span>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select
            nzMode="multiple"
            class="inputs event"
            formControlName="personnel"
            nzPlaceHolder="Select personnel"
            nzShowSearch
            nzAllowClear
          >
            <nz-option
              *ngFor="let personnel of personnelList"
              [nzValue]="personnel.id"
              [nzLabel]="personnel.title"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <!-- Patients -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "Patients" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select
            nzMode="multiple"
            class="inputs event"
            formControlName="patients"
            nzPlaceHolder="Select patients"
            nzShowSearch
            nzAllowClear
          >
            <nz-option
              *ngFor="let patient of patientsList"
              [nzValue]="patient.id"
              [nzLabel]="patient.title"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <!-- Start Date and Time -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "Start Date and Time" | translate }}</label
          ><span class="asterisk">*</span>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input
            id="start"
            class="inputs event"
            type="datetime-local"
            formControlName="start"
          />
        </div>
      </div>

      <!-- End Date and Time -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="30">
          <label>{{ "End Date and Time" | translate }}</label>
        </div>
        <div fxFlex="70">
          <input
            id="end"
            class="inputs event"
            type="datetime-local"
            formControlName="end"
          />
          <!-- Error message displayed below the input field -->
          <div
            *ngIf="eventForm.errors?.['startAfterEnd']"
            class="error-message"
          >
            <span class="asterisk">*</span> "End date" must be after "Start
            date".
          </div>
        </div>
      </div>

      <!-- All Day Event -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="30">
          <label>{{ "All Day Event" | translate }}</label>
        </div>
        <div fxFlex="70">
          <nz-switch
            formControlName="allDay"
            ngDefaultControl
            class="inputs"
          ></nz-switch>
          <!-- Error message displayed below the switch component -->
          <div
            *ngIf="eventForm.errors?.['endDateOrAllDayRequired']"
            class="error-message"
          >
            <span class="asterisk">*</span> Please select an "End date" or "All
            Day Event".
          </div>
        </div>
      </div>

      <!-- Event Color -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "Event Color" | translate }}</label
          ><span class="asterisk">*</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="70">
          <div class="color-options" fxLayout="row" fxLayoutGap="10px">
            <label
              *ngFor="let color of colorOptions"
              fxLayoutAlign="start center"
            >
              <input
                type="radio"
                formControlName="eventColor"
                [value]="color.value"
                (change)="onColorChange(color.name)"
              />
              <span
                class="color-circle"
                [ngStyle]="{
                  'background-color': color.value,
                  opacity: eventForm.get('eventColor')?.disabled ? 0.2 : 1
                }"
                [class.disabled]="eventForm.get('eventColor')?.disabled"
              ></span>
            </label>
          </div>

          <!-- Error message below the colors -->
          <div
            *ngIf="eventForm.get('eventColor')?.disabled"
            class="info-message"
            style="margin-top: 10px"
          >
            ** Event colors are applicable only for All Day events.
          </div>
        </div>
      </div>

      <!-- Save and Cancel buttons -->
      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button
          nz-button
          nzPrimary
          class="save-btn"
          type="submit"
          [nzLoading]="isLoading"
          [disabled]="isLoading || !eventForm.valid"
        >
          {{ "Save" | translate }}
        </button>
        <button
          nz-button
          nzDanger
          class="cancel-btn"
          type="button"
          (click)="handleCancel()"
          [disabled]="isLoading"
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
    </form>
  </ng-container>
</nz-modal>
