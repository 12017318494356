import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Subject, combineLatest, debounceTime } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { CtParametersService } from 'src/app/services/ctparameters.service';
import { TubeCharacteristicsService } from 'src/app/services/tubeCharacteristics.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-tracheostomy-section',
  templateUrl: './tracheostomy-section.component.html',
  styleUrls: ['./tracheostomy-section.component.css'],
})
export class TracheostomySectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: number;
  @Output() selectedOptionChange = new EventEmitter<number>();

  ngOnInit() {}

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.ctService.ctParametersLoadingSub$,
        this.tubeService.tubeCharacteristicsLoadingSub$,
      ]).subscribe(([value1, value2]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.id == this.selectedOption
        )?.path;
        if (!value1 && !value2 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const ct = document.getElementById(paths.ctParameters);
                const tb = document.getElementById(paths.tubeCharacteristics);

                if (tb) {
                  const rect = tb.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.tubeCharacteristics
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (ct) {
                  const rect = ct.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.ctParameters
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly ctService: CtParametersService,
    private readonly tubeService: TubeCharacteristicsService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
