<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' 3D-CT Parameters') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="ctParametersForm" (ngSubmit)="submitForm()"
            [ngClass]="{'ctParameters-submitted': ctParametersSubmitted, 'ctParameters-not-submitted': !ctParametersSubmitted}">

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Depth of Trachea" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs ctParameters" type="number" [placeholder]="'Depth of Trachea' | translate"
                        formControlName="depthOfTracheaValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Distance from Skin to Trache" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs ctParameters" type="number" [placeholder]="'Distance from Skin to Trache' | translate"
                        formControlName="distanceFromSkinToTracheaValue" />
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Distance from Cricothyroid to Carina" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs ctParameters" type="number"
                        [placeholder]="'Distance from Cricothyroid to Carina' | translate"
                        formControlName="distanceFromCricothyroidToCarinaValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Distance between Tracheal and Cartilages" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs ctParameters" type="number"
                        [placeholder]="'Distance between Tracheal and Cartilages' | translate"
                        formControlName="distanceBetweenTrachealCartilagesValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Anatomical Variations" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs ctParameters" type="text" [placeholder]="'Anatomical Variations' | translate"
                        formControlName="anatomicalVariationsValue" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
    <ng-template>
        <button type="submit">{{"Save" | translate}}</button>
    </ng-template>
</nz-modal>