<div fxLayout="row" class="medication-expanded">
    <div fxLayout="col" fxFlex="100" class="medication-details">
        <nz-layout>
            <nz-header>{{"Details" | translate}}</nz-header>
            <nz-content>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="30" class="detail-label">{{'Assigned Doctor' | translate}}</div>
                    <div fxLayout="col" fxFlex="70" class="detail-info">{{item.assignedDoctorFullName || "-"}}</div>
                </div>

                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="30" class="detail-label">{{'Profession' | translate}}</div>
                    <div fxLayout="col" fxFlex="70" class="detail-info">{{item.profession || "-"}}</div>
                </div>

                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="30" class="detail-label">{{'Instructions to Patient' | translate}}</div>
                    <div fxLayout="col" fxFlex="70" class="detail-info">{{item.instructionPatient || "-"}}</div>
                </div>


                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="30" class="detail-label">{{'Advice to Dispenser' | translate}}</div>
                    <div fxLayout="col" fxFlex="70" class="detail-info">{{item.adviceToDispenser || "-"}}</div>
                </div>

            </nz-content>
        </nz-layout>
    </div>
</div>