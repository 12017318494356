/* src/app/components/ehr/dashboard-amen/dashboard-admissions-moh-dmsw/dashboard-admissions-moh-dmsw.component.css */
#divChart {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30vh;
  justify-content: center;
}
.chart-container {
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px;
  background-color: white;
}
#mohDmsw {
  width: 100%;
  height: 30vh;
}
/*# sourceMappingURL=dashboard-admissions-moh-dmsw.component-44QHOV7X.css.map */
