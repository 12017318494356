<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Imaging Files') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="imagingForm" (ngSubmit)="submitForm()"
            [ngClass]="{'imaging-submitted': imagingSubmitted, 'imaging-not-submitted': !imagingSubmitted}">

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Category" | translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs imaging" nzShowSearch nzAllowClear [nzPlaceHolder]="'Category' | translate"
                        formControlName="categoryValue" [nzLoading]="isLoading"
                        (ngModelChange)="categoryChange($event)">
                        <ng-container *ngFor="let et of imagingDropdown?.category">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Modality" | translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs imaging" nzShowSearch nzAllowClear [nzPlaceHolder]="'Modality' | translate"
                        formControlName="modalityValue" [nzLoading]="isLoading"
                        [nzDisabled]="!imagingForm.get('categoryValue')?.value">
                        <ng-container *ngFor="let et of filteredModalities()">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Exam Title" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs imaging" type="text" [placeholder]="'Exam Title...' | translate"
                        formControlName="examTitleValue" (keypress)="disallowDots($event)" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs imaging" type="date" [placeholder]="'Date...' | translate"
                        formControlName="dateValue" [max]="this?.imagingForm?.value?.dateValue|| getCurrentDate()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Report" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input class="imaging upload" [ngClass]="{'ng-invalid' : !this.imagingForm.value?.reportValue}"
                        style="margin-left:1em" type="file" (change)="onReportSelected($event)" accept=".pdf" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Files" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <!-- <nz-upload class="inputs imaging" style="margin-left:1em" [(nzFileList)]="fileList"
                        [nzBeforeUpload]="beforeUpload" [nzMultiple]="false" [nzLimit]="1" nzAccept=".pdf">
                        <button nz-button type="button">
                            <i nz-icon nzType="icons:upload"></i>
                            Select File
                        </button>
                    </nz-upload> -->

                    <!-- .dcm -->
                    <input class="imaging upload" [ngClass]="{'ng-invalid' : !this.imagingForm.value?.fileValue}"
                        style="margin-left:1em" type="file" (change)="onFileSelected($event)"
                        accept=".jpg, .jpeg, .png, .webp, .tif, .tiff" multiple />
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Description" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs imaging" type="text" [placeholder]="'Description...' | translate"
                        formControlName="descriptionValue" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>