/* src/app/components/ehr/register-patient/register-patient.component.css */
::ng-deep .mat-step-icon-selected,
::ng-deep .mat-step-icon-state-edit {
  background-color: #0d9488 !important;
}
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
.add-patient-row {
  margin-bottom: 1.5em;
}
.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
  height: 35px !important;
}
.add-patient-input {
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
}
::placeholder {
  color: #c2c2c2;
}
.add-patient-button {
  height: 35px;
  border: 1px solid #0d9488;
  border-radius: 4px;
  background-color: #0d9488 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-button {
  margin-right: 1em;
  height: 35px;
  border: 1px solid #0d9488;
  border-radius: 4px;
  background-color: white;
  color: #0d9488;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
.back-button {
  cursor: pointer;
}
::ng-deep .mat-vertical-stepper-header {
  pointer-events: none !important;
}
::ng-deep nz-select {
  min-height: 35px !important;
}
::ng-deep nz-select-item {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
::ng-deep nz-select-search {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
::ng-deep nz-select-top-control {
  height: 100% !important;
}
.stepper {
  margin-top: 1em;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}
/*# sourceMappingURL=register-patient.component-X7UBCTD7.css.map */
