import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { IAdmissionFile } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionFile';
import { ICoding } from 'src/app/interfaces/ICoding';
import { AdmissionService } from 'src/app/services/admission.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admission-documents',
  templateUrl: './admission-documents.component.html',
  styleUrl: './admission-documents.component.css',
})
export class AdmissionDocumentsComponent {
  patientId: string = '';
  @Input() fileList: ICoding[] = [];
  @Input() isEditMode: boolean = false;

  documents: IAdmissionFile[] = [];

  constructor(
    private readonly patientService: PatientService,
    private readonly route: ActivatedRoute
  ) {}

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.patientService.documentsSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.documents = data;
      });

    this.route.queryParams.subscribe((params) => {
      this.patientId = params['patientId'];

      this.patientService.getFiles(this.patientId);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['admissionPersonalInfoData']) {
      // this.initializeForm();
    }
    if (changes['isEditMode']) {
      // this.setFormControlsState();
    }
  }

  getDocument(documentID: number) {
    return this.documents.find((d) => d.fileTypeId === documentID);
  }
}
