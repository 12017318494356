<div class="workspace">
  <div class="workspace-container">
    <nz-layout class="inner-layout">
      <nz-sider nzWidth="15%" [nzCollapsed]="isCollapsed">
        <ul nz-menu nzMode="inline" class="sider-menu">
          <ng-container *ngFor="let m of this.menuData?.childModules">
            <li
              nz-menu-item
              *ngIf="m.childModules.length < 1"
              [ngClass]="{
                            'single-menu': true,
                            'single-menu-collapse': isCollapsed,
                            '': !isCollapsed,
                          }"
              [nzSelected]="m.id == selectedOption"
              (click)="clickItem(m)"
            >
              <span
                nz-icon
                [nzType]="'icons:' + m.icon"
                [ngClass]="{
                  'single-menu-icon': isCollapsed,
                  'single-menu-icon-nc': !isCollapsed
                }"
              ></span>

              <span
                [ngClass]="{ 'label-single': isCollapsed, '': !isCollapsed }"
                >{{ m.name | translate }}</span
              >
            </li>
          </ng-container>
        </ul>
      </nz-sider>
      <nz-content
        class="inner-content"
        [ngClass]="isCollapsed ? 'inner-content-collapse' : 'inner-content'"
      >
        <button mat-fab class="collapse-menu" (click)="toggleCollapsed()">
          <mat-icon *ngIf="!isCollapsed" class="collapse-menu-icon"
            >keyboard_arrow_left</mat-icon
          >
          <mat-icon *ngIf="isCollapsed" class="collapse-menu-icon"
            >keyboard_arrow_right</mat-icon
          >
        </button>

        <app-my-patients
          *ngIf="activeSubmenu() == componentPaths.myPatients"
        ></app-my-patients>
        <app-incoming-referrals
          *ngIf="activeSubmenu() == componentPaths.incomingReferrals"
        ></app-incoming-referrals>
      </nz-content>
    </nz-layout>
  </div>
</div>
