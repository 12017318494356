/* src/app/components/ehr/admission/admission-profile/admission-insurance/admission-insurance.component.css */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
::ng-deep .mat-vertical-content {
  padding: 15px 24px 24px 24px !important;
}
.add-patient-row {
  margin-bottom: 1.5em;
}
.add-patient-col {
  margin-left: 1em;
  margin-right: 2em;
  height: 35px !important;
}
.add-patient-insurance-col {
  margin-left: 1em;
  margin-right: 2em;
  min-height: 35px !important;
}
.add-patient-profile {
  margin-left: 1em;
  margin-right: 1em;
  height: 100px !important;
}
.add-patient-input {
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
.add-patient-multi-inputZorro {
  width: 100%;
  color: #c2c2c2 !important;
  height: 100%;
  min-height: 35px !important;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
.ant-select-single {
  font-family: "Inter", sans-serif !important;
  color: black !important;
}
select.ng-valid.ng-touched {
  color: black;
}
input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: #c2c2c2;
}
.add-patient-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-patient-picture-btn {
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
.submitted .ng-invalid {
  border: red 1px solid;
  border-radius: 2px;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
::ng-deep nz-select-top-control {
  height: auto !important;
}
::ng-deep .ant-select-selector {
  height: 100% !important;
}
/*# sourceMappingURL=admission-insurance.component-GTAYTLPN.css.map */
