import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { MatIconModule } from '@angular/material/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IHysteroscopyFileDetailed } from 'src/app/interfaces/HysteroscopyFile/IHysteroscopyFileDetailed';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { Subject, takeUntil } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { HysteroscopyFileAddComponent } from './hysteroscopy-file-add/hysteroscopy-file-add.component';
import moment from 'moment';
import { NzImageModule } from 'ng-zorro-antd/image';

@Component({
  standalone: true,
  selector: 'app-hysteroscopy-file',
  templateUrl: './hysteroscopy-file.component.html',
  styleUrls: ['./hysteroscopy-file.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    TooltipComponent,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NzPaginationModule,
    NgTemplateOutlet,
    BrowserModule,
    NzSpinModule,
    NzImageModule,
  ],
})
export class HysteroscopyFileComponent {
  @Input() isPreview: boolean = false;
  @Input() isLoading?: boolean = false;
  isPreviewLoading?: boolean = false;

  @Input() pagination?: boolean = true;

  public addComp = HysteroscopyFileAddComponent;

  private destroy$ = new Subject<void>();

  fileObj?: string;
  fileItem?: IHysteroscopyFileDetailed;
  fileType: string = 'unknown';
  @Input() data?: IHysteroscopyFileDetailed[];

  currentPageIndex: number = 1;
  pageSize: number = 10;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal
  isVisible = false;

  constructor(
    private readonly hysteroscopyService: HysteroscopyFileService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.hysteroscopyService.hysteroscopyLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    if (!this.data) {
      this.hysteroscopyService.hysteroscopyDataSub
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: IHysteroscopyFileDetailed[]) => {
          this.data = data;

          if (data.at(0)) {
            this.requestFile(data.at(0));
          }
        });

      this.hysteroscopyService.getData();
    }
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  hasUnsubmitted() {
    return this.getData()?.find(
      (d) => d?.['isSubmitted'] !== undefined && !d?.['isSubmitted']
    );
  }

  getData() {
    if (this.pagination) {
      const startIndex = (this.currentPageIndex - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this?.data?.slice(startIndex, endIndex) || [];
    }
    return this?.data;
  }

  handlePageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPageIndex = 1; // Reset to the first page when changing page size
  }

  openModal(item?: IHysteroscopyFileDetailed) {
    this.hysteroscopyService.hysteroscopyModalSub.next(true);
    this.hysteroscopyService.hysteroscopyEditSub.next(item);
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Hysteroscopy?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.hysteroscopyService.deleteHysteroscopy(id.toString()).subscribe({
          next: (d) => {
            Swal.fire({
              text: 'Hysteroscopy deleted successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            if (id == this?.fileItem?.id) {
              this.fileObj = undefined;
              this.fileItem = undefined;
            }

            this.hysteroscopyService.deleteHysteroscopyFromTable(id.toString());
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to delete Hysteroscopy!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire('Hysteroscopy is not deleted', '', 'info');
      }
    });
  }

  requestFile(item?: IHysteroscopyFileDetailed) {
    this.isPreviewLoading = true;
    this.hysteroscopyService.getImage(item?.id?.toString()!).subscribe({
      next: (res) => {
        // Create a Blob URL for the response data
        const fileBlob = new Blob([res], { type: res.type });
        this.fileObj = URL.createObjectURL(fileBlob);

        // Detect file extension and determine file type
        const fileExtension = item?.path?.split('.').pop()?.toLowerCase();
        this.fileType = this.getFileType(fileExtension);

        this.fileItem = item;
        this.isPreviewLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve the file!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        this.fileObj = undefined;
        this.fileItem = undefined;
        this.isPreviewLoading = false;
        // For debugging
      },
    });
  }

  getFileType(extension: string | undefined): string {
    switch (extension) {
      case 'mp4':
      case 'avi':
      case 'mov':
        return 'video';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image';
      default:
        return 'unknown';
    }
  }
  // getFileType(extension: string | undefined): string {
  //   if (!extension) return 'unknown';
  //   const imageExtensions = ['png', 'jpeg', 'jpg'];
  //   const videoExtensions = ['mp4', 'avi', 'mov'];
  //
  //   if (imageExtensions.includes(extension)) {
  //     return 'image';
  //   } else if (videoExtensions.includes(extension)) {
  //     return 'video';
  //   }
  //   return 'unknown';
  // }

  downloadFile(item: IHysteroscopyFileDetailed) {
    this.hysteroscopyService.downloadFile(item);
  }

  getValue(field: string, value?: string) {
    if (!value) {
      return '-';
    }

    if (field?.toLowerCase()?.includes('date time')) {
      return moment(value).format('YYYY-MM-DD h:mm:ss a');
    } else if (field?.toLowerCase()?.includes('date')) {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return value;
    }
  }

  handleCancel() {
    this.isVisible = false;

    this.modalRef.destroy();
  }
}
