<nz-layout>
    <nz-header class="section-header">
        {{'Daily Monitoring' | translate}}
    </nz-header>
    <nz-content>
        <ng-container *ngFor="let cm of submenu">
            <app-vital-signs *ngIf="cm.path == componentPaths.vitalSigns" [id]="cm.path" />
            <app-arterial-blood-gas *ngIf="cm.path == componentPaths.arterialBloodGas" [id]="cm.path" />
            <app-glasgow *ngIf="cm.path == componentPaths.glasgow" [id]="cm.path" />
        </ng-container>
    </nz-content>
</nz-layout>