import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Chart } from 'chart.js/auto';
import { IGenericServiceResponse } from '../../../../interfaces/IServiceResponse';
import { environment } from '../../../../../environments/environment';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { HttpClient } from '@angular/common/http';
import { IDateTimeRange } from 'src/app/interfaces/Dashboard/IDateTimeRange';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-bed-availability-by-day',
  templateUrl: './dashboard-bed-availability-by-day.component.html',
  styleUrls: ['./dashboard-bed-availability-by-day.component.css'],
})
export class DashboardBedAvailabilityByDayComponent {
  @Input() dateTimeRange: IDateTimeRange | null = null; // Input to receive the selected date range

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  public chartData: IDashboardChart | null = null;
  public allZeroData: boolean = true;

  ngAfterViewInit(): void {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateTimeRange'] && !changes['dateTimeRange'].firstChange) {
      this.fetchData(); // Fetch data whenever dateTimeRange changes
    }
  }

  BarChart(): void {
    // Initialize an empty array for allDataSets
    let allDataSets: number[][] = [];

    // Check if dataSets is defined and is an array
    if (Array.isArray(this.chartData?.dataSet)) {
      // Iterate over each dataset and process its data
      this.chartData?.dataSet.forEach((dataset) => {
        // Check if dataset and dataset.data are defined
        if (dataset.data) {
          // Map the data to numbers and concatenate to allDataSets
          const numericData = dataset.data.map(Number); // Convert each item to a number
          allDataSets.push(numericData);
        }
      });
    }

    const maxYAxisValue = Math.max(...allDataSets.flat()) + 2; // Adjust buffer as needed

    const data = {
      labels: this.chartData!.labels,
      datasets:
        this.chartData?.dataSet?.map((dataset, index) => ({
          label: this.translate.instant(dataset?.label || ''),
          data: allDataSets?.at(index) || [],
          backgroundColor: dataset.backgroundColor || 'rgba(0, 0, 0, 0.2)',
          borderColor: dataset.backgroundColor || 'rgba(0, 0, 0, 0.2)',
          borderWidth: 1,
        })) || [],
    };

    new Chart('bedAvailabilityByDay', {
      type: 'line',
      data: data,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            max: maxYAxisValue,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.translate.instant('Daily Bed Capacity'),
          },
        },
      },
    });
  }

  fetchData(): void {
    let chartStatus = Chart.getChart('bedAvailabilityByDay');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.http
      .post<IGenericServiceResponse<IDashboardChart>>(
        `${environment.BACKEND_URL}Dashboard/CapacityBed`,
        {
          startDate: this.dateTimeRange?.startDate,
          endDate: this.dateTimeRange?.endDate,
          offset: new Date().getTimezoneOffset() / 60,
        }
      )
      .subscribe((data) => {
        this.chartData = data.data;
        this.cdr.detectChanges();
        this.allZeroData = this.chartData?.dataSet!.every((dataset) =>
          dataset.data!.every((value) => parseFloat(value) === 0)
        );
        console.log(this.allZeroData);

        if (this.chartData && !this.allZeroData) {
          setTimeout(() => {
            this.BarChart();
          });
        }
      });
  }
}
