import { Component } from '@angular/core';

@Component({
  selector: 'app-newforgot',
  templateUrl: './newforgot.component.html',
  styleUrl: './newforgot.component.css',
})
export class NewforgotComponent {
  passwordFieldType: string = 'password';

  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }
}
