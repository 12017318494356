import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
  base64UrlDecode(str: string): string {
    // Replace non-url compatible chars with base64 standard chars
    str = str.replace(/-/g, '+').replace(/_/g, '/');

    // Pad out with standard base64 required padding characters
    while (str.length % 4) {
      str += '=';
    }

    return atob(str);
  }

  utf8Decode(str: string): string {
    try {
      return decodeURIComponent(escape(str));
    } catch (e) {
      console.error('Failed to decode UTF-8:', e);
      return '';
    }
  }
}
