/* src/app/components/ehr/patients/components/medical-history-section/resolved-problems/resolved-problems.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
/*# sourceMappingURL=resolved-problems.component-Y4DAW22J.css.map */
