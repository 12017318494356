/* src/app/components/ehr/patients/components/medication-summary-section/medication-add/medication-add.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
nz-content {
  background: white;
}
.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-arrow {
  display: none !important;
}
::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}
::ng-deep .ant-btn:focus {
  color: black;
}
::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}
::ng-deep .material-icons {
  font-size: 22px;
}
::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}
::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}
::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}
::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}
.table-content {
  border: 1px solid #d8d8d8 !important;
}
.table-margin {
  margin-top: 1em;
  margin-bottom: 1em;
}
::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}
.table-header-row {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-header-cell {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.margin-lr {
  margin-left: 1em;
  margin-right: 1em;
}
.margin-t {
  margin-top: 1em;
}
::ng-deep .ant-radio-checked,
::ng-deep .ant-radio-inner,
::ng-deep .ant-radio-checked::after {
  border-color: #0d9488 !important;
}
::ng-deep .ant-radio-inner::after {
  background-color: #0d9488 !important;
}
.width-100 {
  width: 100% !important;
}
.margin-1 {
  margin: 1em;
}
.padding-1 {
  padding: 1em;
}
.margin-15 {
  margin: 1.5em;
}
textarea.disabled-txtarea {
  resize: none;
  background: #f0f2f5;
  border: 1px solid #d9d9d9;
}
textarea {
  color: black;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 0px solid #d9d9d9;
}
.label {
  color: black;
  font-weight: 500;
  font-size: 14px;
}
.radio-label {
  color: black;
  font-weight: 500;
  font-size: 16px;
}
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
.search-group {
  border: 1px solid #d9d9d9;
}
.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}
.cancel-btn:hover {
  background: lightgray !important;
}
.save-btn {
  margin-right: 1em;
}
::ng-deep .ant-layout-header {
  height: 50px;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
form {
  border: none !important;
}
.medication-submitted .medication.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=medication-add.component-IEFKCPRE.css.map */
