import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { EpisodeOfCareService } from '../services/episodeOfCare.service';
import { PatientService } from '../services/patient.service';
import { KeycloakService } from 'keycloak-angular';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class EocGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly keycloakService: KeycloakService,
    private readonly tokenService: TokenService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const patientValue = next.paramMap.get('patientID');
    const eocValue = next.paramMap.get('eocID');
    const visitValue = next.paramMap.get('visitID');

    if (patientValue && eocValue && visitValue) {
      const token = await this.keycloakService.getToken();

      if (token) {
        const base64Decoded = this.tokenService.base64UrlDecode(
          token.split('.')[1]
        );
        const payload = JSON.parse(this.tokenService.utf8Decode(base64Decoded));

        const roles = payload?.resource_access?.['ehr-ui']?.roles;

        if (roles?.includes('read-only')) {
          this.router.navigate(['/ehr/not-found']);
          return false;
        }
      } else {
        this.router.navigate(['/ehr/not-found']);
        return false;
      }

      // Set the patient and EOC IDs after confirming user roles
      this.patientService.patientIDsignal.set(patientValue);
      this.eocService.currentEocId.set(eocValue);
      this.eocService.currentVisitId.set(visitValue);
      return true;
    } else {
      this.router.navigate(['/ehr/not-found']);
      return false;
    }
  }
}
