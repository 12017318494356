/* src/app/components/ehr/patients/components/health-care-plan-section/care-plan/care-plan.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
/*# sourceMappingURL=care-plan.component-7LDVJCU5.css.map */
