<div fxLayout="row" class="allergy-expanded">
    <div fxLayout="col" fxFlex="100" class="allergy-details">
        <nz-layout>
            <nz-header>{{"Details" | translate}}</nz-header>
            <nz-content>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Medicinal Product Name' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.medicinalProductName || "-"}}</div>
                </div>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Marketing Authorization Holder' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.marketingAuthorizationHolder || "-"}}</div>
                </div>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Batch Lot Number' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.batchLotNumber || "-"}}</div>
                </div>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Description' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.description || "-"}}</div>
                </div>

                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Country' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.country || "-"}}</div>
                </div>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Doctor Full Name' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.doctorFullName || "-"}}</div>
                </div>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Administering Center Full Name' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.administeringCenterFullName || "-"}}</div>
                </div>

            </nz-content>
        </nz-layout>
    </div>
</div>
