<form [formGroup]="insuranceInfoForm" [ngClass]="{ submitted: formSubmitted }">
    <div>
        <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
            <div fxLayout="col" fxFlex="100" class="add-patient-col" fxLayoutAlign="start center">
                {{ "Insurances" | translate }}
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
            <div fxLayout="col" fxFlex="50" class="add-patient-insurance-col">
                <nz-select nzMode="multiple" nzShowSearch nzAllowClear [nzPlaceHolder]="'Insurances' | translate"
                    formControlName="insurance" class="add-patient-multi-inputZorro" [nzOptions]="insurances">
                </nz-select>
            </div>
            <div fxLayout="col" fxFlex="50" class="add-patient-col"></div>
        </div>

    </div>
</form>