/* src/app/components/ehr/dashboard-amen/dashboard-registration-history-month/dashboard-registration-history-month.component.css */
#divChart {
  display: flex;
  width: 100% !important;
  height: 40vh !important;
}
.chart-container {
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px;
  background-color: white;
}
#registrationHistoryMonth {
  width: 100%;
  height: 40vh;
}
/*# sourceMappingURL=dashboard-registration-history-month.component-RN4IGGOR.css.map */
