/* src/app/components/ehr/patients/components/tracheostomy-section/tube-characteristics/tube-characteristics.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
/*# sourceMappingURL=tube-characteristics.component-7OD3I2KH.css.map */
