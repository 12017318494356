export const modules = {
  episodeOfCare: 36,
  ipsPreview: 26,
  gynecology: 51,
  dailyMonitoring: 28,
  etiology: 45,
  complication: 46,
  tracheostomy2: 49,
  pilotStudy: 44,
  gocPilotStudy: 55,
  gocTracheostomy: 56,
};
