import { createAction, props } from '@ngrx/store';
import { IModule } from '../interfaces/IModule';

export const loadMenu = createAction('[Menu] Load Menu');
export const loadMenuSuccess = createAction(
  '[Menu] Load Menu Success',
  props<{ menu: IModule[] }>()
);
export const loadMenuFailure = createAction(
  '[Menu] Load Menu Failure',
  props<{ error: any }>()
);
