<div class="header-body" *ngIf="!ongoingHysteroscopyExamination">
  <mat-toolbar color="primary" class="" header>
    <img [src]="logo" class="header-logo" (click)="logoPress()" />
    <mat-toolbar class="navbar" fxLayout="row">
      <mat-button-toggle-group [(ngModel)]="activeButton" class="header-toolbar">
        <ng-container *ngFor="let menu of menuList">
          <mat-button-toggle [value]="menu.path" routerLink="{{ menu.path }}" [routerLinkActive]="activeButton"
            [checked]="menu.path === activeButton">{{ menu.name | translate }}</mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </mat-toolbar>
    <!-- 
    <input matInput placeholder="{{'Search' | translate}}..." class="header-search"> -->

    <button mat-fab class="header-notifications">
      <mat-icon class="header-notifications-icon">notifications_none</mat-icon>
    </button>

    <select (ngModelChange)="changeLanguage($event)" [(ngModel)]="defaultLanguage" class="header-language">
      <option value="en">EN</option>
      <option value="el">GR</option>
    </select>

    <button mat-fab class="header-profile" [matMenuTriggerFor]="menu">
      <div class="header-profile-initials">{{ initials }}</div>
      <mat-menu #menu="matMenu">
        <app-logout />
      </mat-menu>
    </button>
  </mat-toolbar>
</div>