<form [formGroup]="rejectForm" (ngSubmit)="submitForm()" [ngClass]="{ 'reject-submitted': formSubmitted }" class="reject-form">
  <div class="form-row">
    <div class="label-column">
      <label>{{ "Reject Reason" | translate }}</label>
    </div>
    <div class="input-column">
      <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="'Reject Reason' | translate" formControlName="rejectReason" class="full-width-select">
        <ng-container *ngFor="let reason of rejectReasonsList">
          <nz-option [nzLabel]="reason.name" [nzValue]="reason.id"></nz-option>
        </ng-container>
      </nz-select>
    </div>
  </div>

  <div class="form-row">
    <div class="label-column">
      <label>
        {{ "Notes" | translate }}
        <span class="optional-text">{{ "(optional)" | translate }}</span>
      </label>
    </div>
    <div class="input-column">
      <textarea rows="4" nz-input class="text-area" placeholder="{{ 'Notes' | translate }}" formControlName="notes"></textarea>
    </div>
  </div>

  <div class="button-row">
    <button nz-button nzPrimary type="submit" [nzLoading]="isLoading" [disabled]="isLoading" class="save-btn">
      {{ "Save" | translate }}
    </button>
    <button nz-button nzDanger type="button" (click)="cancel()" [disabled]="isLoading" class="cancel-btn">
      {{ "Cancel" | translate }}
    </button>
  </div>
</form>
