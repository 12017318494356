<div fxLayout="row" fxLayoutAlign="center center" class="not-found-container">
    <div fxLayout="column" fxFlex="50">
        <div fxLayout="row" fxLayout="row" fxLayoutAlign="center center">
            <i nz-icon nzType="icons:not-found" nzTheme="outline" class="not-found-icon"
                [@iconAnimation]="iconState"></i>
        </div>

        <div fxLayout="row" fxLayout="row" fxLayoutAlign="center center" class="not-found-text">
            {{"The requested resource could not be found." | translate}}
        </div>
    </div>
</div>