<nz-layout>
    <nz-header class="section-header">
        {{'Patient Characteristics'|translate}}
    </nz-header>
    <nz-content>
        <!-- <ng-container *ngFor="let cm of submenu">
        </ng-container> -->

        <app-social-history />
        <app-comorbidity />
    </nz-content>
</nz-layout>