/* src/app/components/ehr/patients/components/daily-monitoring-section/arterial-blood-gas/arterial-blood-gas.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
.margint {
  margin-top: 2em;
}
/*# sourceMappingURL=arterial-blood-gas.component-M5PBFFKN.css.map */
