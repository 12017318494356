<nz-layout>
    <nz-content>
        <ng-container>
            <app-reusable-table title="Allergies" [columns]="['Category',
            'Type',
            'Substance/Product',
            'Criticality'
        ,'Status']" [rowFields]="['allergyCategory',
            'eventType',
            'allergy',
            'criticality'
            ,'allergyStatus']" [data]="allergies.getAll" [dataGetList]="allergies" [expandComponent]="expandComp"
                [addComponent]="addComp" [isLoading]="isLoading" [isNoKnownLoading]="isNoKnownLoading"
                [deleteFun]="deleteFun" [openModalFun]="openModalFun" [isPreview]="false"
                [handleToggleFun]="handleToggleFun" *ngIf="allergies" />
        </ng-container>
    </nz-content>
</nz-layout>