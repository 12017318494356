<div fxLayout="row" class="allergy-expanded">
    <div fxLayout="col" fxFlex="35" class="allergy-details">
        <nz-layout>
            <nz-header>{{"Details" | translate}}</nz-header>
            <nz-content>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Description' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.description || "-"}}</div>
                </div>
                <div fxLayout="row" class="detail-row" *ngIf="item.resolutionDate">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Resolution Date' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.resolutionDate || "-"}}</div>
                </div>
            </nz-content>
        </nz-layout>
    </div>
    <div fxLayout="col" fxFlex="1"></div>
    <div fxLayout="col" fxFlex="64" class="allergy-reactions">
        <app-reusable-table-expand title="Reactions" [columns]="['Description',
'Manifestation',
'Severity',
'Certainty',
'Onset Date',
'End Date']" [rowFields]="['description',
'manifestation',
'severity',
'certainty',
'onSetDate',
'endDate']" [data]="item.reactionDto" />
    </div>
</div>