/* src/app/components/ehr/patients/components/pregnancy-section/pregnancy-history/pregnancy-history.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
/*# sourceMappingURL=pregnancy-history.component-SN2QCM6T.css.map */
