<div class="patients-container">
    <div class="patients-search">
        <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
            <input matInput [placeholder]="'Document ID' | translate" [(ngModel)]="documentID">
        </div>
        <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
            <input matInput [placeholder]="'First Name' | translate" [(ngModel)]="firstName">
        </div>
        <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
            <input matInput [placeholder]="'Last Name' | translate" [(ngModel)]="lastName">
        </div>
        <div fxLayout="column" fxLayoutAlign="" fxFlex="17" class="patients-search-input">
            <input matInput [placeholder]="'Date of Birth' | translate" type="date" [(ngModel)]="dateOfBirth"
                [max]="getCurrentDate()">
        </div>
        <div fxLayout="column" fxFlex="32" class="patients-search-buttons">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center">
                <div fxLayout="column" fxLayoutAlign="" fxFlex="47" class="h-100">
                    <button class="search-button" (click)="searchPatientsForm()">
                        <mat-icon>search</mat-icon>
                        <div class="btn-text">{{"Search" | translate}}</div>
                    </button>
                </div>
                <div *ngIf="!isAMEN" fxFlex="6"></div>
                <div *ngIf="!isAMEN" fxLayout="column" fxLayoutAlign="" [fxFlex]="isAMEN ? '0': '47'" class="h-100">
                    <button class="register-button" (click)="registerPatient()"><mat-icon>add</mat-icon>
                        <div class="btn-text">{{"Register Patient" | translate}}</div>
                    </button>
                </div>
                <!-- <div fxLayout="column" fxLayoutAlign="" fxFlex="47">
                <button class="clear-button" (click)="clearPatientsForm()"><mat-icon>clear</mat-icon>
                    <div>Clear</div>
                </button>
            </div> -->
            </div>
        </div>
    </div>

    <div class="patients-table">
        <mat-table [dataSource]="data" class="mat-elevation-z8 ">

            <ng-container matColumnDef="documentNumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Document Number' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.documentNumber || "-"}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'First Name' | translate }}
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.firstName || "-"}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Last Name' | translate }}
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.lastName || "-"}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateOfBirth">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Date of Birth' | translate }}
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> {{element.dateOfBirth || "-"}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'Actions' | translate }}
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                    <button class="action-button">{{ 'View' | translate }}</button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></mat-row>
        </mat-table>
        <!-- <div fxLayout="row" [ngClass]="data.data.length!==0 ? 'patients-content-paginator-hide' : ''">
        <div fxLayout="col" fxFlex="100" style="margin-top: 3em; justify-content: center;">Search for Patients!</div>
        </div> -->
        <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons
            [ngClass]="data.data.length===0 ? 'patients-content-paginator-hide' : ''" (page)="pageChange()"
            [length]="totalRecords" />
    </div>

    <span>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="100" *ngIf="data.data.length == 0 && !isLoading"
            class="no-patients">
            {{"No Patients available." | translate}}
        </div>
    </span>

    <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" class="allpatients-spinner"><nz-spin
            nzSimple></nz-spin>
    </div>

</div>