import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddPatientService } from '../../../../services/addpatient.service';
import {
  ImageCroppedEvent,
  ImageCropperComponent as NgxImageCropperComponent,
} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css'],
})
export class ImageCropperComponent {
  @ViewChild('cropper') cropper!: NgxImageCropperComponent;

  croppedImage: Blob | null | undefined;
  imageChangedEvent: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    private readonly addPatientService: AddPatientService
  ) {
    if (data && data.imageChangedEvent) {
      this.imageChangedEvent = data.imageChangedEvent;
    }
  }

  imageCroppedHandler(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  closeDialogBox() {
    this.addPatientService.setProfilePicture(null);
    this.dialogRef.close();
  }

  cropAndUploadImage() {
    this.addPatientService.setProfilePicture(this.croppedImage!);
    this.dialogRef.close();
  }
}
