<form [formGroup]="medicationForm" (ngSubmit)="submitForm()"
    [ngClass]="{'medication-submitted': medicationSubmitted, 'medication-not-submitted': !medicationSubmitted}">
    <nz-layout class="table-margin">
        <nz-header class="table-header">
            <div class="table-header-row margin-lr">
                <div class="table-header-cell">{{ "Add Medicinal Product" |
                    translate }}</div>
            </div>
        </nz-header>
        <nz-content class="table-content">

            <div class="search-group margin-15">
                <div fxLayout="row" class="margin-1">
                    <nz-radio-group formControlName="radioValue" nzName="radiogroup">
                        <label nz-radio nzValue="0" class="radio-label">{{"Brand Name" | translate}}</label>
                        <label nz-radio nzValue="1" class="radio-label">{{"Active Substance" | translate}}</label>
                        <label nz-radio nzValue="2" class="radio-label">{{"ATC Code" | translate}}</label>
                    </nz-radio-group>
                </div>

                <div fxLayout="row" class="margin-1">
                    <nz-select *ngIf="this.medicationForm.value?.radioValue == 0" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Brand Name' | translate" [nzLoading]="isBrandNameLoading"
                        formControlName="searchValue" class="width-100 medication" [nzOptions]="brandNameDropdown"
                        nzNoAnimation>
                        <!-- <ng-container *ngFor="let et of brandNameDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                        </ng-container> -->
                    </nz-select>

                    <nz-select *ngIf="this.medicationForm.value?.radioValue == 1" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Active Substance' | translate" [nzLoading]="isActiveSubstanceLoading"
                        formControlName="searchValue" class="width-100 medication" [nzOptions]="activeSubstanceDropdown"
                        nzNoAnimation>
                        <!-- <ng-container *ngFor="let et of activeSubstanceDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                        </ng-container> -->
                    </nz-select>

                    <nz-select *ngIf="this.medicationForm.value?.radioValue == 2" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'ATC Code' | translate" [nzLoading]="isAtcLoading"
                        formControlName="searchValue" class="width-100 medication" [nzOptions]="atcDropdown"
                        nzNoAnimation>
                        <!-- <ng-container *ngFor="let et of atcDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                        </ng-container> -->
                    </nz-select>
                </div>
            </div>

            <!-- DESCRIPTION -->
            <nz-layout class="table-margin margin-15">
                <nz-header class="table-header">
                    <div class="table-header-row margin-lr">
                        <div class="table-header-cell">{{ "Description" |
                            translate }}</div>
                    </div>
                </nz-header>
                <nz-content class="table-content">
                    <div fxLayout="row" class="">
                        <div fxLayout="col" fxFlex="50" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"ATC Code" | translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <textarea rows="4" nz-input class="width-100 disabled-txtarea"
                                            [disabled]="true">{{this.pharmacyObj?.atc}}</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="col" fxFlex="50" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Active Substance(s)" | translate}}
                                    </div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <textarea rows="4" nz-input class="width-100 disabled-txtarea"
                                            [disabled]="true">{{this.pharmacyObj?.activeSubstance}}</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="">
                        <div fxLayout="col" fxFlex="50" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Product Name" | translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <textarea rows="1" nz-input class="width-100 disabled-txtarea"
                                            [disabled]="true">{{this.pharmacyObj?.productName}}</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="col" fxFlex="50" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Strength" | translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <textarea rows="1" nz-input class="width-100 disabled-txtarea"
                                            [disabled]="true">{{this.pharmacyObj?.packageStrength}}</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="">
                        <div fxLayout="col" fxFlex="100" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Pharmaceutical Dose Form" |
                                        translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <textarea rows="1" nz-input class="width-100 disabled-txtarea"
                                            [disabled]="true">{{this.pharmacyObj?.packageDescription}}</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-content>
            </nz-layout>

            <!-- POSOLOGY -->
            <nz-layout class="table-margin margin-15">
                <nz-header class="table-header">
                    <div class="table-header-row margin-lr">
                        <div class="table-header-cell">{{ "Posology" |
                            translate }}</div>
                    </div>
                </nz-header>
                <nz-content class="table-content">
                    <!-- FIRST ROW -->
                    <div fxLayout="row" class="">
                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Quantinty Value" | translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <input type="number" nz-input class="width-100 medication"
                                            formControlName="quantintyValue"
                                            [placeholder]="'Quantinty Value' | translate">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Units per Intake" | translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <nz-select nzShowSearch nzAllowClear
                                            [nzPlaceHolder]="'Units per Intake' | translate" [nzLoading]="isLoading"
                                            formControlName="unitsPerIntakeValue" class="width-100 medication">
                                            <ng-container *ngFor="let et of dropdownData?.units">
                                                <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                                            </ng-container>
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Frequency of Intake" | translate}}
                                    </div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100" fxLayoutAlign="start center">
                                        <div style="margin-right: 0.5em;">Every:</div> <input type="number" nz-input
                                            class="width-100 medication" formControlName="freqOfIntakeValue"
                                            [placeholder]="'Frequency of Intake' | translate">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Frequency of Intake Unit" |
                                        translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <nz-select nzShowSearch nzAllowClear
                                            [nzPlaceHolder]="'Frequency of Intake Unit' | translate"
                                            [nzLoading]="isLoading" formControlName="freqOfIntakeUnitValue"
                                            class="width-100 medication">
                                            <ng-container *ngFor="let et of dropdownData?.masterTimingUnit">
                                                <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                                            </ng-container>
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- SECOND ROW -->
                    <div fxLayout="row" class="">
                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Route of Administration" |
                                        translate}} <span class="optional">{{"(optional)" | translate}}</span></div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <!-- <input type="text" nz-input class="width-100"
                                           ['placeholder' | translate]="Route of Administration" formControlName="routeofAdminValue"> -->

                                        <nz-select nzShowSearch nzAllowClear
                                            [nzPlaceHolder]="'Route of Administration' | translate"
                                            [nzLoading]="isLoading" formControlName="routeOfAdminValue"
                                            class="width-100 medication">
                                            <ng-container *ngFor="let et of dropdownData?.routeOfAdministration">
                                                <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                                            </ng-container>
                                        </nz-select>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Duration of Treatment" | translate}}
                                    </div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <input type="number" nz-input class="width-100 medication"
                                            [placeholder]="'Duration of Treatment' | translate"
                                            formControlName="durationOfTreatmentValue">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Duration Units" | translate}}</div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100" fxLayoutAlign="start center">
                                        <nz-select nzShowSearch nzAllowClear
                                            [nzPlaceHolder]="'Duration Units' | translate" [nzLoading]="isLoading"
                                            formControlName="durationUnitsValue" class="width-100 medication">
                                            <ng-container *ngFor="let et of dropdownData?.masterTimingUnit">
                                                <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                                            </ng-container>
                                        </nz-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxLayout="col" fxFlex="25" class="margin-1 width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100 label">{{"Onset Date & Time" | translate}}
                                    </div>
                                </div>
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <input class="width-100 medication" type="datetime-local"
                                            [placeholder]="'Date' | translate" formControlName="dateValue"
                                            [max]="getCurrentDateTime()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-content>
            </nz-layout>

            <!-- <nz-layout class="table-margin margin-15">
                <nz-header class="table-header">
                    <div class="table-header-row margin-lr">
                        <div class="table-header-cell">{{ "Assigner" |
                            translate }}
                        </div>
                    </div>
                </nz-header>
                <nz-content class="table-content padding-1">
                    <div fxLayout="row" class="width-100">

                        <div fxLayout="row" class="width-100 margin-1">
                            <div fxLayout="col" fxFlex="20" class="width-100 label">Assigned Doctor</div>
                            <div fxLayout="col" fxFlex="80" class="width-100" fxLayoutAlign="start center">
                                <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="Assigned Doctor"
                                    [nzLoading]="isLoading" formControlName="assignedDoctorValue"
                                    class="width-100 medication" [nzOptions]="doctors" nzNoAnimation>
                                </nz-select>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" class="width-100">

                        <div fxLayout="row" class="width-100 margin-1">
                            <div fxLayout="col" fxFlex="20" class="width-100 label">Profession</div>
                            <div fxLayout="col" fxFlex="80" class="width-100" fxLayoutAlign="start center">
                                <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="Profession" [nzLoading]="isLoading"
                                    formControlName="professionValue" class="width-100 medication">
                                    <ng-container *ngFor="let et of dropdownData?.profession">
                                        <nz-option [nzLabel]="et.name" [nzValue]="et.id.toString()"></nz-option>
                                    </ng-container>
                                </nz-select>
                            </div>
                        </div>
                    </div>
                </nz-content>
            </nz-layout> -->

            <!-- INSTRUCTIONS TO PATIENT -->
            <nz-layout class="table-margin margin-15">
                <nz-header class="table-header">
                    <div class="table-header-row margin-lr">
                        <div class="table-header-cell">{{ "Instructions to Patient" |
                            translate }}
                            <span class="optional">{{"(optional)" | translate}}</span>
                        </div>
                    </div>
                </nz-header>
                <nz-content class="table-content">
                    <div fxLayout="row" class="">
                        <div fxLayout="col" fxFlex="100" class="width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <textarea rows="3" nz-input class="width-100"
                                            [placeholder]="'Instructions to Patient...' | translate"
                                            formControlName="instructionsToPatientValue"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-content>
            </nz-layout>

            <!-- ADVICES TO DISPENCER -->
            <nz-layout class="table-margin margin-15">
                <nz-header class="table-header">
                    <div class="table-header-row margin-lr">
                        <div class="table-header-cell">{{ "Advice to Dispencer" |
                            translate }}
                            <span class="optional">{{"(optional)" | translate}}</span>
                        </div>
                    </div>
                </nz-header>
                <nz-content class="table-content">
                    <div fxLayout="row" class="">
                        <div fxLayout="col" fxFlex="100" class="width-100">
                            <div class="width-100">
                                <div fxLayout="row" class="width-100">
                                    <div fxLayout="col" class="width-100">
                                        <textarea rows="3" nz-input class="width-100"
                                            [placeholder]="'Advice to Dispencer...' | translate"
                                            formControlName="advicesToDispencerValue"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-content>
            </nz-layout>
        </nz-content>
    </nz-layout>

    <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit"
            [nzLoading]="isLoading || isActiveSubstanceLoading || isAtcLoading || isBrandNameLoading || isDropdownLoading"
            [disabled]="isLoading || isActiveSubstanceLoading || isAtcLoading || isBrandNameLoading || isDropdownLoading">{{"Save"
            | translate}}</button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
            [disabled]="isLoading">{{"Cancel" | translate}}</button>
    </div>
</form>