<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Laboratory File') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="laboratoryForm" (ngSubmit)="submitForm()"
            [ngClass]="{'laboratory-submitted': laboratorySubmitted, 'laboratory-not-submitted': !laboratorySubmitted}">

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Category" | translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs laboratory" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Category' | translate" formControlName="categoryValue"
                        [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of laboratoryDropdown?.categories">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Exam Title"|translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs laboratory" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Exam Title...' | translate" formControlName="examTitleValue"
                        [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of laboratoryDropdown?.examTitles">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs laboratory" type="date" [placeholder]="'Date...' | translate"
                        formControlName="dateValue" [max]="this?.laboratoryForm?.value?.dateValue|| getCurrentDate()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"File" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <!-- <nz-upload class="inputs laboratory" style="margin-left:1em" [(nzFileList)]="fileList"
                        [nzBeforeUpload]="beforeUpload" [nzMultiple]="false" [nzLimit]="1" nzAccept=".pdf">
                        <button nz-button type="button">
                            <i nz-icon nzType="icons:upload"></i>
                            Select File
                        </button>
                    </nz-upload> -->

                    <input class="laboratory upload" [ngClass]="{'ng-invalid' : !this.laboratoryForm.value?.fileValue}"
                        style="margin-left:1em" type="file" (change)="onFileSelected($event)" accept=".pdf" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Description" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs laboratory" type="text" [placeholder]="'Description...' | translate"
                        formControlName="descriptionValue" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>