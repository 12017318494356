import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { Component } from '@angular/core';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { IPreviewDraft } from 'src/app/interfaces/Detailed/IPreviewDraftDetailed';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { AllergiesExpandComponent } from '../components/alerts-section/allergies/allergies-expand/allergies-expand.component';
import { ProcedureExpandComponent } from '../components/medical-problems-section/procedure/procedure-expand/procedure-expand.component';
import { MedicalDeviceExpandComponent } from '../components/medical-problems-section/medical-device/medical-device-expand/medical-device-expand.component';
import { CurrentProblemsExpandComponent } from '../components/medical-problems-section/current-problems/current-problems-expand/current-problems-expand.component';
import { Router } from '@angular/router';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import {
  IDynamicItem,
  IDynamicTable,
} from 'src/app/interfaces/DynamicTable/IDynamicDetailed';
import { IQuestionnaireDetailed } from 'src/app/interfaces/Questionnaire/IQuestionnaireDetailed';
import { DynamicQuestionnaireService } from 'src/app/services/dynamicQuestionnaire.service';

@Component({
  selector: 'app-preview-summary',
  templateUrl: './preview-summary.component.html',
  styleUrls: ['./preview-summary.component.css'],
})
export class PreviewSummaryComponent {
  public allergiesExpandComp = AllergiesExpandComponent;
  public procedureExpandComp = ProcedureExpandComponent;
  public medicalDeviceExpandComp = MedicalDeviceExpandComponent;
  public problemExpandComp = CurrentProblemsExpandComponent;

  private destroy$ = new Subject<void>();

  data?: IPreviewDraft;

  switchValue?: boolean = false;

  pdValue?: number;

  pdList: NzSelectOptionInterface[] = [];

  noteValue?: string = '';

  isLoading: boolean = false;
  isPdLoading: BooleanInput = false;

  questionnaireData: any[] = [];

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly questionnaireService: DynamicQuestionnaireService,
    private readonly router: Router
  ) {}

  handleEndedValue(value: boolean) {
    this.eocService.eocEndedSub.next(value);
  }

  setPD(id: number) {
    this.eocService.setPD(id);
  }

  setNote(note: any) {
    this.eocService.setNote(note);
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.getQuestionnaireData().then((data) => {
      this.questionnaireData = data;
      this.isLoading = false; // Update loading status
    });
    this.eocService.unvalidatePreview();
    this.eocService.unsetNote();
    this.eocService.unsetPd();
    this.eocService.eocEndedSub.next(false);

    this.eocService.potentialDiagnosisSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number | undefined) => {
        this.pdValue = data || undefined;
      });

    this.isLoading = true;
    this.isPdLoading = true;
    this.eocService
      .getPreviewDraft(this.eocService.currentVisitId())
      ?.subscribe({
        next: (d) => {
          this.data = d?.data;
          this.isLoading = false;

          if (d?.data?.getPotentialDiagnosis?.id) {
            this.eocService.setPD(d?.data?.getPotentialDiagnosis?.id);
          }

          if (d?.data?.isEmpty == false) {
            this.eocService.validatePreview();
          }
        },
        error: (err) => {
          Swal.fire({
            text: 'Unable to retrieve Draft Records!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });

    this.eocService.getPotentialDiagnosis().subscribe({
      next: (d) => {
        this.pdList = d?.data?.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });
        this.isPdLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve Potential Diagnosis!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLaboratoryData(
    laboratoryData: IDynamicTable<IDynamicItem>[],
    groupId: number
  ) {
    return laboratoryData.filter((dataItem) => dataItem.groupId == groupId);
  }

  getQuestionnaireData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.questionnaireService
        .getQuestionnaireData(1) // questionnaire template id (inpatient = 1, outpatient = 2)
        .subscribe(
          (data: IQuestionnaireDetailed) => {
            if (data && data.questionnaire && data.answers) {
              const questionnaire = data.questionnaire;
              const answers = data.answers.answer;

              // Total number of pages
              const totalNumberOfPages = questionnaire.pages.length;

              // Total number of questions
              const totalNumberOfQuestions = questionnaire.pages.reduce(
                (total, page) => total + page.elements.length,
                0
              );

              // Number of questions completed
              const questionsCompleted = answers.length;

              // Number of questions remaining
              const questionsRemaining =
                totalNumberOfQuestions - questionsCompleted;

              // Completion percentage
              const completionPercentage =
                ((questionsCompleted / totalNumberOfQuestions) * 100).toFixed(
                  2
                ) + '%';

              // Determine the status
              const status = data.isSubmitted
                ? 'Submitted'
                : 'Not Submitted (Ready for Submission)';

              // Create the data object
              const questionaireDataDto = [
                {
                  dateTime: data.creationDate || '',
                  totalNumberOfPages: `${totalNumberOfPages} pages`,
                  totalNumberOfQuestions: `${totalNumberOfQuestions} questions`,
                  completionPercentage: `${Math.round(
                    parseFloat(completionPercentage)
                  )}% completed`,
                  questionsCompleted: `${questionsCompleted} out of ${totalNumberOfQuestions} questions completed`,
                  questionsRemaining: `${questionsRemaining} questions remaining`,
                  status,
                },
              ];

              // Resolve the promise with the data
              resolve(questionaireDataDto);
            } else {
              resolve([]); // Resolve with an empty array if there's no data
            }
          },
          (error) => {
            reject(error); // Reject the promise in case of an error
          }
        );
    });
  }
}
