/* src/app/components/ehr/patients/components/medical-problems-section/current-problems/current-problems-expand/current-problems-expand.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
nz-header {
  background-color: white !important;
}
.ant-table-pagination {
  background-color: white !important;
}
.ant-table-wrapper {
  background-color: white !important;
}
::ng-deep .ant-table-small {
  border: 1px solid #f0f0f0 !important;
}
.allergy-expanded {
}
.allergy-details {
  background-color: white !important;
  height: auto;
  border: 1px solid #f4f4f6;
  border-radius: 5px;
  padding: 20px;
}
.allergy-reactions {
  background-color: white !important;
  height: auto;
  border: 1px solid #f4f4f6;
  border-radius: 5px;
  padding: 20px;
}
.ant-layout-content {
  background-color: white;
  border-radius: 5px;
}
.detail-label {
  background-color: white !important;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  border-radius: 4px;
}
.detail-info {
  background-color: white !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 5px;
  border-radius: 4px;
}
.detail-row {
  border-bottom: 1px solid #f0f0f0;
}
/*# sourceMappingURL=current-problems-expand.component-PGB5R723.css.map */
