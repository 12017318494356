import { createReducer, on } from '@ngrx/store';
import * as menuActions from '../actions/menu.actions';
import { IModule } from '../interfaces/IModule';

export interface MenuState {
  loaded: boolean;
  menu: IModule[];
}

export const initialState: MenuState = {
  loaded: false,
  menu: [],
};

export const menuReducer = createReducer(
  initialState,
  on(menuActions.loadMenu, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(menuActions.loadMenuSuccess, (state, { menu }) => ({
    ...state,
    loaded: true,
    menu,
  })),
  on(menuActions.loadMenuFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    error,
  }))
);
