<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
  [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Vaccination') | translate" (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="vaccinationForm" (ngSubmit)="submitForm()"
      [ngClass]="{'vaccination-submitted': vaccinationSubmitted, 'vaccination-not-submitted': !vaccinationSubmitted}">
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Vaccine" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs vaccination" nzShowSearch nzAllowClear [nzPlaceHolder]="'Vaccine' | translate"
            formControlName="vaccineValue" [nzLoading]="isLoading">
            <ng-container *ngFor="let et of vaccinationDropDown?.vaccine">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Disease Targeted" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs vaccination" nzShowSearch nzAllowClear [nzPlaceHolder]="'Disease Targeted' | translate"
            formControlName="diseaseTargetedValue" [nzLoading]="isLoading" [nzOptions]="diseaseTargeted" nzNoAnimation>
            <!-- <ng-container *ngFor="let sp of vaccinationDropDown?.diesesTarget">
              <nz-option [nzLabel]="sp.name" [nzValue]="sp.id"></nz-option>
            </ng-container> -->
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Number of series in doses" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs vaccination" type="number"[placeholder]="'Number of series in doses...' | translate"
            formControlName="numSeriesDosesValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Medicinal Product Name" | translate}}</label><span class="optional">{{"(optional)" |
            translate}}</span>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs vaccination" type="text"[placeholder]="'Medicinal Product Name...' | translate"
            formControlName="medicinalProductNameValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Marketing Authorization Holder" | translate}}</label><span class="optional">{{"(optional)" | translate}}</span>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs vaccination" type="text"
           [placeholder]="'Marketing Authorization Holder... (ex. Faizer)' | translate"
            formControlName="marketingAuthorizationHolderValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Batch/Lot Number" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs vaccination" type="text"[placeholder]="'Batch/Lot Number...' | translate"
            formControlName="batchLotNumberValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Description" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs vaccination" type="text"[placeholder]="'Description ...' | translate"
            formControlName="descriptionValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Vaccination Next Date" | translate}}</label><span class="optional">{{"(optional)" |
            translate}}</span>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs vaccination" type="date"[placeholder]="'Vaccination Next Date...' | translate"
            formControlName="nextDateValue" [min]="getCurrentDate()" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Person's Country" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs vaccination" nzShowSearch nzAllowClear [nzPlaceHolder]="'Person\'s country' | translate"
            formControlName="countryValue" [nzLoading]="isLoading">
            <ng-container *ngFor="let sp of vaccinationDropDown?.countries">
              <nz-option [nzLabel]="sp.name" [nzValue]="sp.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Assigned Doctor" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs vaccination" nzShowSearch nzAllowClear [nzPlaceHolder]="'Assigned Doctor' | translate"
            formControlName="doctorValue" [nzLoading]="isLoading" [nzOptions]="doctors" nzNoAnimation>
            <!-- <ng-container *ngFor="let sp of vaccinationDropDown?.doctors">
              <nz-option [nzLabel]="sp.fullName" [nzValue]="sp.id"></nz-option>
            </ng-container> -->
          </nz-select>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
          [disabled]="isLoading">{{"Save" | translate}}</button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
          [disabled]="isLoading">{{"Cancel" | translate}}</button>
      </div>
    </form>
  </ng-container>
  <ng-template>
    <button type="submit">{{"Save" | translate}}</button>
  </ng-template>
</nz-modal>