<nz-layout>
    <nz-content>
        <ng-container>
            <app-reusable-table title="3D-CT Parameters"
                [columns]="['Depth of Trachea', 'Distance from Skin to Trache', 'Distance from Cricothyroid to Carina', 'Distance between Tracheal and Cartilages', 'Anatomical Variations']"
                [rowFields]="['depthOfTrachea', 'distanceFromSkinToTrachea', 'distanceFromCricothyroidToCarina', 'distanceBetweenTrachealCartilages', 'anatomicalVariations']"
                [data]="ctParameters" [addComponent]="addComp" [deleteFun]="deleteFun" [isLoading]="isLoading"
                [openModalFun]="openModalFun" [isPreview]="false" />
        </ng-container>
    </nz-content>
</nz-layout>