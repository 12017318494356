<ng-container *ngFor="let ward of wardsData.wards">
  <div fxLayout="row" fxFlex="24" class="ward-container">

    <div fxLayout="column" fxFlex="100" class="card">
      <div fxLayout="row" fxFlex="100" class="card-header">
        <mat-icon fontIcon="hotel" class="card-icon"></mat-icon>
        <div class="card-title">{{"Beds" | translate}}</div>
      </div>

      <div fxLayout="column" fxFlex="100" class="card-content">
        <div fxLayout="row" fxFlex="100" class="card-header">
          <div class="card-ward">{{ ward.floorName }}</div>
        </div>
        <div fxLayout="row" fxFlex="100" class="card-header">
          <div fxLayout="column" fxFlex="40">
            <div class="card-ward">{{ ward.wardName }}</div>
          </div>


        </div>

        <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center" class="card-header">

          <div class="card-value-percentage">
            <div class="card-percentage">
              <div class="card-tile">
                <span style="font-size:smaller; margin-right: 3px;">{{"Occupancy" | translate}}</span>
                <span class="card-percentage increase">{{ ward.takenBeds }}</span>/{{ ward.totalBeds }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>