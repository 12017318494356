/* src/app/components/ehr/workspace/incoming-referrals/incoming-referrals.component.css */
::ng-deep .ant-modal-footer .ant-btn-dangerous {
  background-color: red !important;
  color: white !important;
  border: none !important;
}
::ng-deep .ant-modal-footer .ant-btn-dangerous:hover {
  background-color: darkred !important;
}
h2 {
  margin: 0;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
li {
  margin: 1.5em 0;
  padding: 0;
}
b {
  margin-right: 3px;
}
.demo-app {
  display: flex;
  min-height: 100%;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  font-size: 14px;
}
.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}
.fc {
  max-height: 75vh;
  margin: 0 auto;
  max-width: 75vw;
}
::ng-deep .fc-theme-standard .fc-scrollgrid {
  background: white !important;
  max-width: 100%;
}
::ng-deep .fc .fc-button-primary {
  background-color: rgba(118, 195, 188, 0.4) !important;
  border: 1px solid rgba(118, 195, 188, 0.4) !important;
  color: black !important;
}
::ng-deep .fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #0d9488 !important;
  border: 1px solid #0d9488 !important;
  color: white !important;
}
::ng-deep .fc .fc-button-primary:disabled {
  cursor: not-allowed;
  background-color: #a0a0a0 !important;
  border: 1px solid #a0a0a0 !important;
  color: black !important;
}
::ng-deep .fc-col-header-cell-cushion {
  color: #0d9488 !important;
}
::ng-deep .fc-daygrid-day-number {
  color: #0d9488 !important;
}
::ng-deep .fc-list-day-side-text {
  color: #0d9488 !important;
}
::ng-deep .fc-list-day-text {
  color: #0d9488 !important;
}
::ng-deep .fc-h-event .fc-event-main {
  color: #000;
}
::ng-deep .fc-popover {
  z-index: 1000 !important;
}
/*# sourceMappingURL=incoming-referrals.component-PNT7GRS2.css.map */
