import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() addressForm: FormGroup = new FormGroup({});

  // Value Sets
  @Input() countriesList: ICoding[] = [];

  public street?: string;
  public streetNUmber?: string;
  public apartmentNumber?: string;
  public town?: string;
  public postcode?: string;
  public district?: string;
  public country?: string = '';

  isCountryCyprus: boolean = false;

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];
  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.addressForm.addControl('street', this.formBuilder.control(''));
    this.addressForm.addControl('streetNumber', this.formBuilder.control(''));
    this.addressForm.addControl(
      'apartmentNumber',
      this.formBuilder.control('')
    );
    this.addressForm.addControl('town', this.formBuilder.control(''));
    this.addressForm.addControl('postcode', this.formBuilder.control(''));
    this.addressForm.addControl('district', this.formBuilder.control(''));
    this.addressForm.addControl('country', this.formBuilder.control(''));
  }

  // Method to handle place of birth change
  onCountryChange(placeId: number): void {
    this.isCountryCyprus = placeId === 55; // Cyprus id is 55
  }

  enter(event: any) {
    event.preventDefault();

    const nextButton = document.getElementById(
      'address-next-btn'
    ) as HTMLButtonElement;
    if (nextButton) {
      nextButton.click();
    } else {
      console.warn('Button not found.');
    }
  }
}
