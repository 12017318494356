<nz-layout>
    <nz-content>
        <ng-container>
            <app-reusable-table title="Resolved Problems"
                [columns]="['Problem Diagnosis','Problem','Diagnosis Assertion','Resolution Circumstances','End Date','Status']"
                [rowFields]="['problem','problemName','diagnosisAssertion','resolutionCircumstancesText','endDate','statusCode']"
                [data]="resolvedProblems" [addComponent]="addComp" [expandComponent]="expandComp"
                [deleteFun]="deleteFun" [isLoading]="isLoading" [openModalFun]="openModalFun" [isPreview]="false" />
        </ng-container>
    </nz-content>
</nz-layout>