<div class="layout">

  <button class="resetPassword" mat-raised-button (click)="resetPassword()">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-icon>vpn_key</mat-icon>
      {{ "Reset Password" | translate }}
    </div>
  </button>

  <button class="logout" mat-raised-button (click)="logout()">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-icon>exit_to_app</mat-icon>
      {{ "Logout" | translate }}
    </div>
  </button>
</div>