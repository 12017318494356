<nz-layout>
  <nz-header class="section-header">
    {{ "Capnography" | translate }}
  </nz-header>
  <nz-content>
    <ng-container>
      <app-capnography />
    </ng-container>
  </nz-content>
</nz-layout>
