/* src/app/components/newreset/newreset.component.css */
.left-half {
  min-height: 100vh;
}
.left-image {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
}
@media only screen and (max-width: 1279.99px) {
  .left-image {
    width: 100%;
    max-height: 400px;
    min-height: 400px;
  }
  .left-half {
    min-height: 400px;
  }
}
.logo {
  max-width: 300px;
  margin-bottom: 2em;
}
.w-100 {
  width: 100%;
}
.login-text {
  font-size: 16px;
  font-weight: 600;
}
.username {
  margin-top: 1em;
  width: 100%;
  border-radius: 5px;
  padding: 14px;
  border: 1px solid #ccc;
  min-height: 52px;
}
.password {
  padding-right: 30px;
  margin-top: 1em;
  width: 100%;
  border-radius: 5px;
  padding: 14px;
  border: 1px solid #ccc;
  min-height: 52px;
}
.reset-btn {
  margin-top: 1em;
  background-color: #317f88;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-family: inherit;
  min-height: 52px;
}
.remember-me {
  margin-left: 5px;
}
.password-input-container {
  position: relative;
  width: 100%;
}
.password {
  width: 100%;
  padding-right: 35px;
  box-sizing: border-box;
}
.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.fa {
  position: absolute;
  right: 10px;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
}
/*# sourceMappingURL=newreset.component-6V2PDLN6.css.map */
