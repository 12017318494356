<nz-layout [ngClass]="removeMargin ? 'remove-margin' : 'margint'">
    <nz-content>
        <ng-container>
            <app-reusable-table title="Arterial Blood Gas"
                [columns]="['Date & Time', 'pH','HCO3', 'PaCO2', 'PaO2', 'SaO2']"
                [rowFields]="['onSetDateTime', 'ph', 'hco3', 'paco2', 'pao2', 'sao2']" [data]="arterialBloodGases"
                [addComponent]="addComp" [deleteFun]="deleteFun" [isLoading]="isLoading" [openModalFun]="openModalFun"
                [isPreview]="false" [refValues]="referenceValues" />
        </ng-container>
    </nz-content>
</nz-layout>