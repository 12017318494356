import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CalendarEventsService } from 'src/app/services/calendarEvents.service';
import { Subject, takeUntil } from 'rxjs';
import { createEventId } from '../event-utils';
import { NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { Calendar, EventApi } from '@fullcalendar/core';
import { ResourceSourceInput } from '@fullcalendar/resource';

@Component({
  selector: 'app-add-calendar-event',
  standalone: true,
  templateUrl: './add-calendar-event.component.html',
  styleUrls: ['./add-calendar-event.component.css'],
  imports: [
    NzModalModule,
    NzSelectModule,
    NzInputModule,
    NzSwitchModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    TranslateModule,
    FlexLayoutModule,
    NzDatePickerModule,
  ],
})
export class AddCalendarEventComponent implements OnInit, OnDestroy {
  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef;

  private destroy$ = new Subject<void>();
  isLoading = false;

  // Dynamic resources (patients)
  // patientsList: { id: string; title: string }[] = []; // Array to hold the resources
  patientsList: any[] = [];

  personnelList: any[] = [];
  departmentList: any[] = [];

  colorOptions = [
    { name: 'red', value: 'rgba(201, 105, 77, 0.4)' },
    { name: 'yellow', value: 'rgba(216, 201, 111, 0.4)' },
    { name: 'blue', value: 'rgba(128, 186, 226, 0.4)' },
    { name: 'green', value: 'rgba(13, 148, 136, 0.4)' },
    { name: 'purple', value: 'rgba(149, 128, 226, 0.4)' },
  ];

  colorOpacity: { [key: string]: number } = {
    red: 1,
    yellow: 1,
    blue: 1,
    green: 1,
    purple: 1,
  };

  // Validator to check if start date is less than end date
  startLessThanEndValidator = (
    formGroup: FormGroup
  ): { [key: string]: any } | null => {
    const start = formGroup.get('start')?.value;
    const end = formGroup.get('end')?.value;

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (startDate >= endDate) {
        return { startAfterEnd: true };
      }
    }
    return null;
  };

  // Validator to ensure either 'allDay' is true or 'end' date is provided
  requireAllDayOrEndValidator = (
    formGroup: FormGroup
  ): { [key: string]: any } | null => {
    const allDay = formGroup.get('allDay')?.value;
    const end = formGroup.get('end')?.value;

    if (!allDay && !end) {
      return { endDateOrAllDayRequired: true };
    }
    return null;
  };

  eventForm: FormGroup = this.fb.group(
    {
      title: [null, [Validators.required]],
      description: null,
      department: [null, [Validators.required]],
      patients: [null, [Validators.required]], // Required field for patients
      personnel: [[], [Validators.required]],
      start: [null, [Validators.required]],
      end: null,
      allDay: false,
      eventColor: [
        {
          value: this.colorOptions.find((color) => color.name === 'blue')
            ?.value,
          disabled: true,
        },
        Validators.required,
      ],
    },
    {
      validators: [
        this.startLessThanEndValidator,
        this.requireAllDayOrEndValidator,
      ],
    }
  );

  editingEvent: EventApi | null = null;
  calendarApi!: Calendar;

  isVisible = false;

  constructor(
    private fb: FormBuilder,
    private calendarEventService: CalendarEventsService,
    private modalService: NzModalService
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalRef.destroy();
  }

  ngOnInit(): void {
    this.calendarEventService.addCalendarEventModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (modalData: {
          showModal: boolean;
          start?: Date;
          end?: Date;
          event?: EventApi;
          calendarApi?: Calendar;
          patients?: ResourceSourceInput;
          personnel?: ResourceSourceInput;
          departments?: ResourceSourceInput;
        }) => {
          this.isVisible = modalData.showModal;
          if (modalData.calendarApi) {
            this.calendarApi = modalData.calendarApi;
          }
          if (modalData.patients) {
            this.patientsList = Array.isArray(modalData.patients)
              ? modalData.patients
              : [];
          }

          if (modalData.personnel) {
            this.personnelList = Array.isArray(modalData.personnel)
              ? modalData.personnel
              : [];
          }

          if (modalData.departments) {
            this.departmentList = Array.isArray(modalData.departments)
              ? modalData.departments
              : [];
          }

          if (modalData.event) {
            // Editing an existing event
            const event = modalData.event;
            this.editingEvent = event;
            this.eventForm.patchValue({
              title: event.title,
              description: event.extendedProps?.['description'],
              department: modalData.event.extendedProps['department']?.map(
                (department: any) => department.id
              ),
              patients: modalData.event.extendedProps['patients']?.map(
                (patient: any) => patient.id
              ),
              personnel: modalData.event.extendedProps['personnel']?.map(
                (personnel: any) => personnel.id
              ),
              start: event.start ? this.formatDate(event.start) : null,
              end: event.end ? this.formatDate(event.end) : null,
              allDay: event.allDay,
              eventColor: event.backgroundColor || 'blue',
            });

            this.onColorChange(event.backgroundColor || 'blue');
          } else if (modalData.start) {
            // Adding a new event
            const startDate = this.formatDate(modalData.start);
            const endDate = modalData.end
              ? this.formatDate(modalData.end)
              : null;

            this.eventForm.patchValue({
              start: startDate,
              end: endDate,
            });
          }
        }
      );

    this.eventForm.get('allDay')?.valueChanges.subscribe((allDay: boolean) => {
      if (allDay) {
        this.eventForm.get('eventColor')?.enable(); // Enable color selection if allDay is true
      } else {
        this.eventForm.get('eventColor')?.disable(); // Disable color selection if allDay is false
      }
    });
  }

  // Format date helper function
  formatDate(date: Date): string {
    const pad = (n: number) => (n < 10 ? '0' + n : n);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  onColorChange(selectedColor: string) {
    for (const color of this.colorOptions) {
      this.colorOpacity[color.name] = color.name === selectedColor ? 1 : 0.3;
    }
  }

  // Handle form submission
  submitForm(): void {
    if (this.eventForm.valid) {
      const formValue = this.eventForm.value;
      const startDate = formValue.start ? new Date(formValue.start) : null;
      const endDate = formValue.end ? new Date(formValue.end) : null;

      const selectedPatients = this.patientsList
        .filter((patient) => formValue.patients.includes(patient.id))
        .map((patient) => patient.title);

      const selectedPersonnel = this.personnelList
        .filter((personnel) => formValue.personnel.includes(personnel.id))
        .map((personnel) => personnel.title);

      const selectedDepartments = this.departmentList
        .filter((department) => formValue.department.includes(department.id))
        .map((department) => department.title);

      if (this.editingEvent) {
        const event = this.editingEvent;
        event.setProp('title', formValue.title);
        event.setStart(startDate!);
        event.setEnd(endDate);
        event.setAllDay(formValue.allDay);
        event.setExtendedProp('description', formValue.description);
        event.setExtendedProp('department', selectedDepartments);
        event.setExtendedProp('patients', selectedPatients); // Set the patients from resources
        event.setExtendedProp('personnel', selectedPersonnel);
        event.setProp('borderColor', formValue.eventColor);
        event.setProp('backgroundColor', formValue.eventColor);
        event.setResources(formValue.patients);

        this.calendarApi.getEventById(this.editingEvent.id)?.remove();
        this.calendarEventService.addEvent(event);
      } else {
        const newEvent = {
          id: createEventId(),
          resourceIds: formValue.patients,
          title: formValue.title,
          start: startDate,
          end: endDate,
          allDay: formValue.allDay,
          extendedProps: {
            description: formValue.description,
            department: selectedDepartments,
            patients: selectedPatients, // Set the patients from resources
            personnel: selectedPersonnel,
          },
          borderColor: formValue.eventColor,
          backgroundColor: formValue.eventColor,
        };
        this.calendarEventService.addEvent(newEvent);
      }

      this.eventForm.markAsPristine();
      this.handleCancel();
    }
  }

  handleCancel() {
    if (this.eventForm.dirty) {
      this.modalService.confirm({
        nzTitle: 'Discard changes?',
        nzContent:
          'You have unsaved changes. Do you really want to discard them?',
        nzOkText: 'Yes',
        nzCancelText: 'No',
        nzOnOk: () => {
          this.editingEvent = null;
          this.eventForm.reset();
          this.calendarEventService.addCalendarEventModalSub.next({
            showModal: false,
          });
          this.modalRef.destroy();
        },
        nzOnCancel: () => {},
      });
    } else {
      this.eventForm.reset();
      this.calendarEventService.addCalendarEventModalSub.next({
        showModal: false,
      });
      this.modalRef.destroy();
    }
  }
}
