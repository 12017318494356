<div>
    <div fxLayout="row" fxLayout.lt-lg="column">
        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" class="left-half">
            <img src="../../../assets/images/loginScreen/loginBuilding.png" class="left-image">
        </div>


        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" fxLayoutAlign="center center">

            <div fxLayout="column" fxFlex="60">
                <div fxLayout="row" class="w-100 forgot-line" fxLayoutAlign="center center">
                    <img src="../../../assets/images/loginScreen/3aHealth.png" class="logo">
                </div>


                <div fxLayout="row" class="w-100 login-text" fxLayoutAlign="start start">
                    {{"Enter a new password"|translate}}
                </div>

                <div class="password-input-container" style="position: relative; width: 100%;">
                    <input nz-input [type]="passwordFieldType" class="password" placeholder="Password" />
                    <i class="fa" [ngClass]="passwordFieldType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
                        (click)="togglePasswordVisibility()"></i>
                </div>

                <div class="password-input-container" style="position: relative; width: 100%;">
                    <input nz-input [type]="passwordFieldType2" class="password" placeholder="Password" />
                    <i class="fa" [ngClass]="passwordFieldType2 === 'password' ? 'fa-eye' : 'fa-eye-slash'"
                        (click)="togglePasswordVisibility2()"></i>
                </div>

                <button nz-button class="reset-btn">{{"Reset Password"|translate}}</button>

            </div>
        </div>
    </div>
</div>