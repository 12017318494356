<nz-layout>
    <nz-content><ng-container>
            <app-reusable-table title="Current Medication"
                [columns]="['Onset Date & Time','End Date & Time','Product','Package', 'Unit', 'Frequency','Duration']"
                [rowFields]="['onSetDateTime','endDateTime','productCombination','packageCombination', 'unitCombination','frequencyCombination','durationTreatmentCombination']"
                [data]="currentMedication?.getAll" [dataGetList]="currentMedication" [isLoading]="isLoading"
                [expandComponent]="expandComp" [isNoKnownLoading]="isNoKnownLoading" [deleteFun]="deleteFun"
                [handleToggleFun]="handleToggleFun" [openModalFun]="openModalFun" [isPreview]="false" />
        </ng-container>
    </nz-content>
</nz-layout>