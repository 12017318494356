import { EventInput } from '@fullcalendar/core';
import { ResourceSourceInput } from '@fullcalendar/resource';

let eventGuid = 0;
const TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

// Helper function to generate date strings in YYYY-MM-DD format
function addDays(dateStr: string, days: number): string {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + days);
  return date.toISOString().replace(/T.*$/, '');
}

// Define color options
// Define color options using RGBA values
const colors = {
  default: 'rgba(128, 186, 226, 0.4)', // blue
  red: 'rgba(201, 105, 77, 0.4)',
  yellow: 'rgba(216, 201, 111, 0.4)',
  green: 'rgba(13, 148, 136, 0.4)',
  purple: 'rgba(149, 128, 226, 0.4)',
};

export const PATIENTS_LIST: ResourceSourceInput = [
  { id: '0', title: 'Patient A' },
  { id: '1', title: 'Patient B' },
  { id: '2', title: 'Patient C' },
  { id: '3', title: 'Patient D' },
  { id: '4', title: 'Patient E' },
  { id: '5', title: 'Patient F' },
  { id: '6', title: 'Patient G' },
  { id: '7', title: 'Patient H' },
  { id: '8', title: 'Patient I' },
  { id: '9', title: 'Patient J' },
  { id: '10', title: 'Patient K' },
  { id: '11', title: 'Patient L' },
  { id: '12', title: 'Patient M' },
  { id: '13', title: 'Patient N' },
  { id: '14', title: 'Patient O' },
  { id: '15', title: 'Patient P' },
  { id: '16', title: 'Patient Q' },
  { id: '17', title: 'Patient R' },
  { id: '18', title: 'Patient S' },
  { id: '19', title: 'Patient T' },
  { id: '20', title: 'Patient U' },
  { id: '21', title: 'Patient V' },
  { id: '22', title: 'Patient W' },
  { id: '23', title: 'Patient X' },
  { id: '24', title: 'Patient Y' },
  { id: '25', title: 'Patient Z' },
];

export const PERSONNEL_LIST: ResourceSourceInput = [
  { id: '0', title: 'Doctor Doe' },
  { id: '1', title: 'Nurse Jane' },
  { id: '2', title: 'Speech therapist Smith' },
  { id: '3', title: 'Social worker Sarah' },
  { id: '4', title: 'Anesthetist Smith' },
  { id: '5', title: 'Trainer Alex' },
  { id: '6', title: 'Receptionist Smith' },
  { id: '7', title: 'Nurse Sam' },
];

export const DEPARTMENT_LIST: ResourceSourceInput = [
  { id: '0', title: 'Amen speech therapy' },
  { id: '1', title: 'General checkup' },
  { id: '2', title: 'Outpatients care' },
  { id: '3', title: 'Surgery' },
  { id: '4', title: 'Staff training' },
  { id: '5', title: 'Client support' },
  { id: '6', title: 'Project management' },
  { id: '7', title: 'Administration' },
  { id: '8', title: 'Other' },
];

// Initialize events with colors
export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'All-day event',
    start: TODAY_STR,
    extendedProps: {
      personnel: ['Doctor Doe', 'Speech therapist Smith'],
      department: 'Amen speech therapy',
      patients: ['patient Doe', 'patient Smith'],
    },
    description: 'Lecture',
    backgroundColor: colors.default,
    borderColor: colors.default,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'Timed event',
    start: TODAY_STR + 'T00:00:00',
    end: TODAY_STR + 'T03:00:00',
    extendedProps: {
      personnel: ['Nurse Jane', 'Doctor Doe'],
      department: 'General checkup',
      patients: 'patients Smith',
    },
    description: 'Overnight observation',
    backgroundColor: colors.green, // example of setting a specific color
    borderColor: colors.green,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'Timed event',
    start: TODAY_STR + 'T12:00:00',
    end: TODAY_STR + 'T15:00:00',
    extendedProps: {
      personnel: ['Doctor Green', 'Physiotherapist Kim'],
      department: 'Physiotherapy',
      patients: 'patients Lee',
    },
    description: 'Physical therapy session',
    backgroundColor: colors.yellow,
    borderColor: colors.yellow,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'Meeting with client',
    start: addDays(TODAY_STR, 1) + 'T09:00:00',
    end: addDays(TODAY_STR, 1) + 'T10:00:00',
    extendedProps: {
      personnel: ['Social worker Sarah', 'Nurse Sam'],
      department: 'Client support',
      patients: 'Client Joe',
    },
    description: 'Consultation with family',
    backgroundColor: colors.red,
    borderColor: colors.red,
    // textColor: colors.red,
    // classNames: 'fc-event-main ng-star-inserted',
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'Project deadline',
    start: addDays(TODAY_STR, 2),
    extendedProps: {
      personnel: ['Project manager Julia', 'Nurse Emily'],
      department: 'Project management',
      patients: 'None',
    },
    description: 'Project report submission',
    backgroundColor: colors.default,
    borderColor: colors.default,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'Conference Call',
    start: addDays(TODAY_STR, 3) + 'T14:00:00',
    end: addDays(TODAY_STR, 3) + 'T16:00:00',
    extendedProps: {
      personnel: ['Doctor Brown', 'IT Support John'],
      department: 'Telemedicine',
      patients: 'patients Cooper',
    },
    description: 'Telemedicine conference call',
    backgroundColor: colors.purple,
    borderColor: colors.purple,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'Team Lunch',
    start: addDays(TODAY_STR, 4) + 'T12:00:00',
    end: addDays(TODAY_STR, 4) + 'T13:00:00',
    extendedProps: {
      personnel: ['Entire Team'],
      department: 'Administration',
      patients: 'None',
    },
    description: 'Casual team lunch',
    backgroundColor: colors.default,
    borderColor: colors.default,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'All-day training session',
    start: addDays(TODAY_STR, 5),
    extendedProps: {
      personnel: ['Trainer Alex', 'Nurse Mark'],
      department: 'Staff training',
      patients: 'None',
    },
    description: 'CPR and emergency response training',
    backgroundColor: colors.green,
    borderColor: colors.green,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'Workshop',
    start: addDays(TODAY_STR, 7) + 'T10:00:00',
    end: addDays(TODAY_STR, 7) + 'T12:00:00',
    extendedProps: {
      personnel: ['Instructor Claire', 'Speech therapist Jane'],
      department: 'Speech therapy workshop',
      patients: 'Group of patients',
    },
    description: 'Speech therapy techniques workshop',
    backgroundColor: colors.yellow,
    borderColor: colors.yellow,
  },
  {
    id: createEventId(),
    resourceIds: ['a', 'b'],
    title: 'All-day event next week',
    start: addDays(TODAY_STR, 10),
    extendedProps: {
      personnel: ['Doctor Lee', 'Therapist Sam'],
      department: 'Outpatients care',
      patients: 'patients Gordon',
    },
    description: 'Routine outpatients checkup',
    backgroundColor: colors.default,
    borderColor: colors.default,
  },
];

export function createEventId() {
  return String(eventGuid++);
}
