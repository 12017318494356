<nz-layout>
    <nz-content><ng-container>
            <app-reusable-table title="Current Problems"
                [columns]="['Problem Diagnosis','Problem','Diagnosis Assertion','Resolution Circumstances','Start Date','Status']"
                [rowFields]="['problem','problemName','diagnosisAssertion','resolutionCircumstancesText','onSetDate','statusCode']"
                [data]="currentProblems?.getAll" [dataGetList]="currentProblems" [expandComponent]="expandComp"
                [addComponent]="addComp" [isLoading]="isLoading" [isNoKnownLoading]="isNoKnownLoading"
                [deleteFun]="deleteFun" [handleToggleFun]="handleToggleFun" [openModalFun]="openModalFun"
                [isPreview]="false" />
        </ng-container>
    </nz-content>
</nz-layout>