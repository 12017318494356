import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { IUserManagement } from '../../../../interfaces/IUserManagement';
import { IUserManagementEdit } from '../../../../interfaces/IUserManagementEdit';
import { UserManagementService } from '../../../../services/userManagement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from 'src/app/services/locale.service';
import { TranslateService } from '@ngx-translate/core';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { Subscription, forkJoin, EMPTY } from 'rxjs';
import { tap, catchError, skipWhile, skip } from 'rxjs/operators';

@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.css'],
})
export class UserManagementEditComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup;
  roles: any[] = [];
  locales: any[] = [];
  loading = false;
  isFormChanged: boolean = false;
  formSubscription!: Subscription;
  isInitialLoading: boolean = true;
  private initialPhoneNumberValue: any;
  usernamePattern = /^[a-zA-Z0-9.]{6,20}$/;

  editedUser: IUserManagement = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    roles: [],
    locale: '',
    email: '',
    phoneNumber: '',
    requiredActions: false,
  };

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.buildForm();
    this.loading = true;

    forkJoin({
      dropdownData: this.userManagement.getDropdownData(),
      userData: this.userManagement.getUserById(
        this.route.snapshot.queryParams['id']
      ),
    }).subscribe({
      next: ({ dropdownData, userData }) => {
        // Process dropdown data
        this.roles = dropdownData.data.roles.map((role: any) => ({
          ...role,
          translatedName: this.translate.instant(role.name),
          name: role.name,
        }));
        this.locales = dropdownData.data.locale;

        // Process user data
        this.editedUser = { ...userData.data };

        // Now we can patch form values
        this.patchFormValues();

        // Store the initial phone number value
        this.initialPhoneNumberValue = this.formGroup.get('phoneNumber')?.value;

        // Now we can subscribe to form changes
        this.subscribeToFormChanges();

        // Delay setting `isInitialLoading` to false
        setTimeout(() => {
          this.isInitialLoading = false;
        }, 0);
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching data:', err);
        this.loading = false;
      },
    });
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }

  buildForm() {
    // Initialize form controls
    this.formGroup = this.formBuilder.group({
      username: [
        '',
        [Validators.required, Validators.pattern(this.usernamePattern)],
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      roles: [[], Validators.required],
      locale: ['', Validators.required],
    });
    console.log('Form initialized:', this.formGroup);
  }

  patchFormValues() {
    this.formGroup.patchValue(
      {
        username: this.editedUser.username,
        firstName: this.editedUser.firstName,
        lastName: this.editedUser.lastName,
        email: this.editedUser.email,
        phoneNumber: this.editedUser.phoneNumber,
        roles: this.editedUser?.roles?.map((r) => r.id),
        locale:
          this.locales.find((locale) => locale.code === this.editedUser.locale)
            ?.id || null,
      },
      { emitEvent: false } // Suppress valueChanges emission
    );

    this.formGroup.markAsPristine();
    this.isFormChanged = false;
    console.log('Form values patched and marked as pristine');
  }
  subscribeToFormChanges() {
    this.formSubscription = this.formGroup.valueChanges
      .pipe(skip(1)) // Skip the first emission to avoid the phoneNumber emit
      .subscribe(() => {
        this.isFormChanged = this.isFormDirtyExcludingPhoneNumber();
        this.cdr.detectChanges();
      });
  }
  private isFormDirtyExcludingPhoneNumber(): boolean {
    const controls = this.formGroup.controls;
    const isOtherControlsDirty = Object.keys(controls).some((key) => {
      if (key === 'phoneNumber') {
        return false; // We'll handle phoneNumber separately
      }
      return controls[key].dirty;
    });

    // Compare current phoneNumber value with initial value
    const phoneControl = this.formGroup.get('phoneNumber');
    const isPhoneNumberChanged =
      JSON.stringify(phoneControl?.value) !==
      JSON.stringify(this.initialPhoneNumberValue);

    return isOtherControlsDirty || isPhoneNumberChanged;
  }

  sendMail() {
    const userId = this.route.snapshot.queryParams['id'];

    this.userManagement.sendMailVerification(userId).subscribe({
      next: (response) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Mail verification sent successfully!'
          )}`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Unable to send mail verification!'
          )}`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error sending mail verification:', err);
      },
    });
  }

  saveChanges() {
    if (this.formGroup.valid) {
      this.loading = true;

      const userId = this.route.snapshot.queryParams['id'];

      const selectedLocaleId = this.formGroup.get('locale')?.value;
      const selectedLocale = this.locales.find(
        (locale) => locale.id == selectedLocaleId
      );
      const selectedRoleIds = this.formGroup.get('roles')?.value;

      let requestBody: IUserManagementEdit = {
        userUpdate: {
          username: this.formGroup.get('username')?.value,
          firstName: this.formGroup.get('firstName')?.value,
          lastName: this.formGroup.get('lastName')?.value,
          email: this.formGroup.get('email')?.value,
          attributes: {
            locale: selectedLocale?.code,
            phoneNumber: this.formGroup.get('phoneNumber')?.value.e164Number,
          },
        },
        roleInfo: this.roles
          .filter((r) => selectedRoleIds?.includes(r.id))
          .map((r) => {
            return {
              name: r.name,
              id: r.id,
            };
          }),
      };

      this.userManagement
        .saveUser(userId, requestBody)
        .subscribe({
          next: (response) => {
            Swal.fire({
              text: `${this.translate.instant('User updated successfully!')}`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.formGroup.markAsPristine();
            this.isFormChanged = false;
            this.router.navigate(['ehr/userManagement']);
          },
          error: (err) => {
            Swal.fire({
              text: err.error.message,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error saving user changes:', err);
          },
        })
        .add(() => {
          this.loading = false;
        });
    } else {
      this.formGroup.markAllAsTouched();
      Swal.fire({
        text: `Please fill in all required fields with valid data.`,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        color: 'white',
        background: '#ffd470',
        timer: 3000,
      });
    }
  }

  cancelEdit() {
    this.formGroup.markAsPristine(); // Mark the form as pristine
    this.isFormChanged = false; // Reset the change flag
    this.router.navigate(['ehr/userManagement']);
  }

  getPhoneNumberControl(): FormControl<string | null> {
    return (
      (this.formGroup.get('phoneNumber') as FormControl<string | null>) ||
      new FormControl<string | null>(null)
    );
  }
}
