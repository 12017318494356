<div class="dashboard-container">
  <div fxLayout="row" class="pb30">
    <div
      fxLayout="column"
      fxFlex="10"
      fxLayoutAlign="center start"
      class="w-100 title"
    >
      {{ "Dashboard" | translate }}
    </div>
    <div fxLayout="column" fxFlex="90" fxLayoutAlign="center end" class="w-100">
      <button
        class="export-data-btn"
        nz-button
        nzType="primary"
        (click)="exportData()"
      >
        <i nz-icon nzType="icons:exportIcon"></i>
        {{ "Export Data" | translate }}
      </button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="50px" class="plr30 toggle">
    <app-dashboard-bed-availability-by-ward
      style="width: 100%"
    ></app-dashboard-bed-availability-by-ward>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="toggle">
    {{ "Inpatients" | translate }}
    <nz-switch [(ngModel)]="inpatientsView" class="toggle-btn"></nz-switch>
    {{ "Admissions" | translate }}
  </div>
  <div
    *ngIf="!inpatientsView"
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutGap="30px"
    class="p30"
  >
    <div fxFlex.gt-sm="33" fxFlex="100">
      <app-dashboard-inpatient-by-gender
        [isAMEN]="true"
      ></app-dashboard-inpatient-by-gender>
    </div>
    <div fxFlex.gt-sm="33" fxFlex="100">
      <app-dashboard-inpatient-by-older-age></app-dashboard-inpatient-by-older-age>
    </div>
    <div fxFlex.gt-sm="31" fxFlex="100">
      <app-dashboard-moh-dmsw></app-dashboard-moh-dmsw>
    </div>
  </div>

  <div
    *ngIf="inpatientsView"
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutGap="30px"
    class="p30"
  >
    <div fxFlex.gt-sm="33" fxFlex="100">
      <app-dashboard-admissions-by-gender
        [isAMEN]="true"
      ></app-dashboard-admissions-by-gender>
    </div>
    <div fxFlex.gt-sm="33" fxFlex="100">
      <app-dashboard-admissions-by-older-age></app-dashboard-admissions-by-older-age>
    </div>
    <div fxFlex.gt-sm="31" fxFlex="100">
      <app-dashboard-admissions-moh-dmsw></app-dashboard-admissions-moh-dmsw>
    </div>
  </div>

  <div class="daterange-items">
    <div fxLayout="row" fxLayoutAlign="end center" class="p50">
      <nz-range-picker
        style="border-color: rgba(0, 0, 0, 0.45); border-radius: 4px"
        [(ngModel)]="dateRange"
        [nzAllowClear]="true"
        [nzShowToday]="true"
        [nzFormat]="'dd-MM-YYYY'"
        [nzDisabledDate]="disabledDate"
        (ngModelChange)="onDateRangeSelected($event)"
      ></nz-range-picker>
    </div>

    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="30px" class="pa30">
      <div fxFlex.gt-sm="50" fxFlex="100">
        <app-dashboard-registration-history-date
          [dateTimeRange]="selectedDates"
        ></app-dashboard-registration-history-date>
      </div>
      <div fxFlex.gt-sm="50" fxFlex="100">
        <app-dashboard-registration-history-month
          [dateTimeRange]="selectedDates"
        ></app-dashboard-registration-history-month>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.lt-lg="column"
    fxLayoutGap="30px"
    class="pa30 toggle"
  >
    <div fxFlex.gt-sm="50" fxFlex="100">
      <app-dashboard-average-registration-status-times
        [dateTimeRange]="selectedDates"
      ></app-dashboard-average-registration-status-times>
    </div>
    <div fxFlex.gt-sm="50" fxFlex="100"></div>
  </div>
</div>
