import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IAddVitalSign } from 'src/app/interfaces/VitalSign/IAddVitalSign';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-vital-signs-add-edit',
  templateUrl: './vital-signs-add-edit.component.html',
  styleUrls: ['./vital-signs-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class VitalSignsAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;
  isDropdownLoading = false;

  isEdit = false;
  editVitalSignId?: number;

  vitalSignSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  vitalSignForm: FormGroup = new FormGroup({});

  constructor(
    private readonly vitalSignService: VitalSignService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isDropdownLoading = true;
    this.isEdit = false;

    this.vitalSignForm = this.formBuilder.group({
      onSetValue: [null, Validators.required],
      temperatureValue: [null, Validators.required],
      respiratoryRateValue: [null, Validators.required],
      heartRateValue: [null, Validators.required],
      systolicValue: [null, Validators.required],
      diastolicValue: [null, Validators.required],
      urineOutputValue: [null],
      spo2Value: [null, Validators.required],
    });

    this.vitalSignService.vitalSignEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editVitalSignId = data?.id;

          this.vitalSignForm.patchValue({
            onSetValue:
              data?.onSetDateTime && this.convertToLocale(data?.onSetDateTime),
            temperatureValue: data?.temperature,
            respiratoryRateValue: data?.respiratoryRate,
            heartRateValue: data?.heartRate,
            systolicValue: data?.systolicBloodPressure,
            diastolicValue: data?.diastolicBloodPressure,
            urineOutputValue: data?.urineOutput,
            spo2Value: data?.spO2,
          });
        } else {
          this.isEdit = false;
          this.editVitalSignId = undefined;

          this?.vitalSignForm.reset();

          this.vitalSignForm.patchValue({
            onSetValue: this.convertToLocale(),
          });
        }
      });

    this.vitalSignService.vitalSignModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    // this.vitalSignForm.reset();

    this.vitalSignService.vitalSignModalSub.next(false);

    this.vitalSignSubmitted = false;

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.vitalSignSubmitted = true;

    if (this.vitalSignForm.valid) {
      const vitalSignObj: IAddVitalSign = {};

      vitalSignObj.id = this.editVitalSignId;

      vitalSignObj.onSetDateTime = moment(
        this.vitalSignForm.value?.onSetValue
      ).format();
      vitalSignObj.temperature = this.vitalSignForm.value?.temperatureValue;
      vitalSignObj.respiratoryRate =
        this.vitalSignForm.value?.respiratoryRateValue;
      vitalSignObj.heartRate = this.vitalSignForm.value?.heartRateValue;
      vitalSignObj.systolicBloodPressure =
        this.vitalSignForm.value?.systolicValue;
      vitalSignObj.diastolicBloodPressure =
        this.vitalSignForm.value?.diastolicValue;
      vitalSignObj.urineOutput = this.vitalSignForm.value?.urineOutputValue;
      vitalSignObj.spO2 = this.vitalSignForm.value?.spo2Value;

      if (this.isEdit) {
        this.vitalSignService.editVitalSign(vitalSignObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Vital Signs edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.vitalSignService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit Vital Signs!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      } else {
        this.vitalSignService.addVitalSign(vitalSignObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Vital Signs added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.vitalSignService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add Vital Signs!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  getCurrentDateTime() {
    return new Date().toISOString().slice(0, 16);
  }

  // CONVERT UTC TO LOCAL
  convertToLocale(date?: string) {
    const currentDateTime = date ? moment(date).format() : moment().format();

    const isoDate = currentDateTime.slice(0, 16);

    return isoDate;
  }
}
