import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule, NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { IProcedureDropdown } from '../../../../../../../interfaces/Procedure/IProcedureDropdown';
import { ProcedureService } from '../../../../../../../services/procedure.service';
import { IAddProcedure } from '../../../../../../../interfaces/Procedure/IAddProcedure';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import moment from 'moment';

@Component({
  standalone: true,
  selector: 'app-procedure-add-edit',
  templateUrl: './procedure-add-edit.component.html',
  styleUrls: ['./procedure-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
    NzNoAnimationModule,
  ],
})
export class ProcedureAddEditComponent {
  private destroy$ = new Subject<void>();

  procedureDropdown: IProcedureDropdown | undefined;

  procedures: NzSelectOptionInterface[] = [];

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editProcedureId?: number;

  procedureSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  procedureForm: FormGroup = new FormGroup({});

  constructor(
    private readonly procedureService: ProcedureService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.procedureService.getProcedureDropdown().subscribe({
      next: (pro) => {
        this.procedureDropdown = pro?.data;

        this.procedures = pro?.data.procedureType.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });

        this.isLoading = false;
      },
      error: (err) => {},
    });

    this.procedureForm = this.formBuilder.group({
      procedureValue: [null, Validators.required],
      bodySiteValue: [null, Validators.required],
      dateValue: [null, Validators.required],
      descriptionValue: [null, Validators.required],
    });

    this.procedureService.procedureEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editProcedureId = data?.id;

          this.procedureForm.patchValue({
            procedureValue: data?.procedureId,
            bodySiteValue: data?.bodySite,
            dateValue: data?.date,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editProcedureId = undefined;

          this?.procedureForm.reset();

          this.procedureForm.patchValue({
            dateValue: this.getCurrentDate(),
          });
        }
      });

    this.procedureService.procedureModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.procedureForm.reset();

    this.procedureService.procedureModalSub.next(false);

    this.procedureSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.procedureSubmitted = true;

    if (this.procedureForm.valid) {
      const procedureObj: IAddProcedure = {};

      procedureObj.id = this.editProcedureId;
      procedureObj.procedureId = this.procedureForm.value?.procedureValue;
      procedureObj.bodySite = this.procedureForm.value?.bodySiteValue;
      procedureObj.date = this.procedureForm.value?.dateValue;
      procedureObj.description = this.procedureForm.value?.descriptionValue;

      if (this.isEdit) {
        this.procedureService.editProcedure(procedureObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Procedure edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.procedureService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit procedure!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      } else {
        this.procedureService.addProcedure(procedureObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Procedure added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.procedureService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add procedure!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  // CONVERT UTC TO LOCAL
  convertToLocale(date?: string) {
    const currentDateTime = date ? moment(date).format() : moment().format();

    const isoDate = currentDateTime.slice(0, 16);

    return isoDate;
  }
}
