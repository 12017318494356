import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, catchError, map, of } from 'rxjs';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { ICoding } from 'src/app/interfaces/ICoding';
import { AdmissionService } from 'src/app/services/admission.service';
import { IRejectPatientObj } from 'src/app/interfaces/AdmissionPatient/IRejectPatientObj';


@Component({
  selector: 'app-admission-reject-modal',
  templateUrl: './admission-reject-modal.component.html',
  styleUrls: ['./admission-reject-modal.component.css'],
})
export class AdmissionRejectModalComponent implements OnInit {
  @Output() rejectSuccess = new EventEmitter<void>();

  rejectForm: FormGroup;
  formSubmitted = false;
  isLoading = false;

  patientId: string;
  rejectReasonsList: ICoding[];

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private admissionService: AdmissionService,
    @Inject(NZ_MODAL_DATA)
    public data: { patientId: string; rejectReasonsList: ICoding[] }
  ) {
    this.patientId = data.patientId;
    this.rejectReasonsList = data.rejectReasonsList;

    this.rejectForm = this.fb.group({
      rejectReason: ['', Validators.required],
      notes: [''],
    });
  }

  ngOnInit(): void {}

  submitForm(): void {
    this.formSubmitted = true;
    if (this.rejectForm.valid) {
      this.isLoading = true;
      const rejectData = this.rejectForm.value;
      this.save(rejectData).subscribe((result: boolean) => {
        this.isLoading = false;
        if (result) {
          // Emit success event
          this.rejectSuccess.emit();
          // Handle successful save
          this.modal.destroy();
        } else {
          // Handle save failure
        }
      });
    }
  }

  save(rejectData: any): Observable<boolean> {
    const rejectObj: IRejectPatientObj = {
      rejectionReasonId: rejectData.rejectReason,
      notes: rejectData.notes,
    };

    return this.admissionService
      .rejectPatient(Number(this.patientId), rejectObj)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  cancel(): void {
    this.modal.destroy();
  }
}
