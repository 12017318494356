import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver-es';
import { IAdmissionFile } from '../interfaces/AdmissionPatient/AdmissionProfile/IAdmissionFile';
import { IAddDocument } from '../interfaces/AdmissionPatient/AdmissionProfile/IAddDocument';

@Injectable()
export class SupportingDocumentsService {
  public supportingDocumentModalSub = new BehaviorSubject<boolean>(false);
  public supportingDocumentEditSub = new BehaviorSubject<
    IAdmissionFile | undefined
  >(undefined);
  public supportingDocumentDataSub = new BehaviorSubject<IAdmissionFile[]>([]);
  public supportingDocumentLoadingSub = new BehaviorSubject<boolean>(false);
  public supportingDocumentLoadingSub$ =
    this.supportingDocumentLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.supportingDocumentLoadingSub.next(true);
    await this.getSupportingDocumentFiles(
      this.patientService.patientIDsignal()
    ).subscribe({
      next: (response) => {
        this.supportingDocumentDataSub.next(response?.data!);
        this.supportingDocumentLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve Supporting Documents Files!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching Supporting Documents files:', err);
      },
    });
  }

  getSupportingDocumentFiles(
    patientId: string
  ): Observable<IGenericServiceResponse<IAdmissionFile[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IAdmissionFile[]>>(
      `${environment.BACKEND_URL}Patient/Files/${patientId}`,
      { headers }
    );
  }

  getPDF(fileId: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get(
      `${
        environment.BACKEND_URL
      }Patient/DownloadPatientFile/${fileId}/${this.patientService.patientIDsignal()}`,
      {
        headers: headers,
        responseType: 'blob',
      }
    );
  }

  downloadPDF(item: IAdmissionFile): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .get(
        `${environment.BACKEND_URL}Patient/DownloadPatientFile/${
          item.id
        }/${this.patientService.patientIDsignal()}`,
        {
          headers: headers,
          responseType: 'blob',
        }
      )
      .subscribe(
        (blob) => {
          const file = new Blob([blob], { type: 'application/pdf' });
          saveAs(file, `${item.fileType}.pdf`);
        },
        (error) => {
          Swal.fire({
            text: 'Unable to download the document!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.error('Error downloading PDF:', error);
        }
      );
  }

  addSupportingDocumentFile(documentObj: IAddDocument) {
    const formData = new FormData();
    formData.append('File', documentObj?.file!);
    formData.append('FileTypeId', documentObj?.fileTypeId!);
    formData.append('PatientId', this.patientService.patientIDsignal());

    return this._http.post(
      `${environment.BACKEND_URL}Patient/UploadPatientFile`,
      formData
    );
  }

  deleteSupportingDocumentFile(fileId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${
        environment.BACKEND_URL
      }Patient/DeletePatientFile/${fileId}/${this.patientService.patientIDsignal()}`,
      { headers: headers }
    );
  }

  deleteSupportingDocumentFileFromTable(fileId: string) {
    this.supportingDocumentDataSub.next(
      this.supportingDocumentDataSub.value.filter(
        (file) => file.id.toString() !== fileId
      )
    );
  }
}
