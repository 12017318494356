<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Pregnancy Outcome') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="pregnancyOutcomeForm" (ngSubmit)="submitForm()"
            [ngClass]="{'outcome-submitted': pregnancyOutcomeSubmitted, 'outcome-not-submitted': !pregnancyOutcomeSubmitted}">
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Pregnancy Outcome" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs outcome" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Pregnancy Outcome' | translate" formControlName="outcomeValue"
                        [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let et of outcomeDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Number of Children" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs outcome" type="number"
                        [placeholder]="'Number of Children' | translate" formControlName="numberOfChildrenValue" />
                </div>
            </div>


            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>