<nz-layout class="margint">
    <nz-content>
      <ng-container>
        <app-reusable-reverse-table
          title="Comorbidities"
          [rowNames]="[
            'Respiratory disease',
            'Hypertension',
            'COPD',
            'Heart Disease',
            'Diabetes mellitus type II',
            'Malignancy',
            'Stroke',
            'Immunosuppression',
            'No Comorbidities'
          ]"
          [rowFields]="[
            'isRespiratoryDisease',
            'isHypertension',
            'isCopd',
            'isHeartDisease',
            'isDiabetesMellitus',
            'isMalignancy',
            'isStroke',
            'isImmunosuppression',
            'isNoComorbidities'
          ]"
          colIdentifier="date"
          [data]="comorbidity || []"
          [addComponent]="addComp"
          [deleteFun]="deleteFun"
          [isLoading]="isLoading"
          [openModalFun]="openModalFun"
          [isPreview]="false"
          />
      </ng-container>
    </nz-content>
  </nz-layout>
