import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IDashboardChart } from '../interfaces/Dashboard/IDashboardChart';
import { IDateTimeRange } from '../interfaces/Dashboard/IDateTimeRange';
import { saveAs } from 'file-saver-es';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private _http: HttpClient) {}

  getRegisteredPatientsByMoh(): Observable<
    IGenericServiceResponse<IDashboardChart>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}AccommodationData/Dashboard/InPatientRegisteredByMoh`,
      {
        headers: headers,
      }
    );
  }

  getAdmissionsByMoh(): Observable<IGenericServiceResponse<IDashboardChart>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}Dashboard/AdmissionsByMoh`,
      {
        headers: headers,
      }
    );
  }

  getRegistrationHistoryDate(
    dateTimeRange: IDateTimeRange | null
  ): Observable<IGenericServiceResponse<IDashboardChart>> {
    const offset = new Date().getTimezoneOffset() / 60;
    return this._http.post<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}Dashboard/RegistrationHistoryByDate`,
      {
        startDate: dateTimeRange?.startDate,
        endDate: dateTimeRange?.endDate,
        offset: offset,
      }
    );
  }

  getRegistrationHistoryMonth(
    dateTimeRange: IDateTimeRange | null
  ): Observable<IGenericServiceResponse<IDashboardChart>> {
    return this._http.post<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}Dashboard/RegistrationHistoryByMonth`,
      {
        startDate: dateTimeRange?.startDate,
        endDate: dateTimeRange?.endDate,
        offset: new Date().getTimezoneOffset() / 60,
      }
    );
  }

  getAverageRegistrationStatusTimes(): Observable<
    IGenericServiceResponse<IDashboardChart>
  > {
    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}Dashboard/AdmissionAverageProcessingTime`
    );
  }

  exportAMENData(dateTimeRange: IDateTimeRange | null): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .post(
        `${environment.BACKEND_URL}Dashboard/ExportAmen`,
        {
          startDate: dateTimeRange?.startDate,
          endDate: dateTimeRange?.endDate,
          offset: -new Date().getTimezoneOffset() / 60,
        },
        {
          headers: headers,
          responseType: 'blob',
        }
      )
      .subscribe(
        (blob: any) => {
          const file = new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(file, 'Dashboard_' + this.formatDateToCustom() + '.xlsx');
        },
        (error) => {
          Swal.fire({
            text: 'Unable to download the Document!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.error('Error downloading PDF:', error);
        }
      );
  }

  exportDashboardData(dateTimeRange: IDateTimeRange | null): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .post(
        `${environment.BACKEND_URL}Dashboard/ExportDash`,
        {
          startDate: dateTimeRange?.startDate,
          endDate: dateTimeRange?.endDate,
          offset: -new Date().getTimezoneOffset() / 60,
        },
        {
          headers: headers,
          responseType: 'blob',
        }
      )
      .subscribe(
        (blob: any) => {
          const file = new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(file, 'Dashboard_' + this.formatDateToCustom() + '.xlsx');
        },
        (error) => {
          Swal.fire({
            text: 'Unable to download the Document!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.error('Error downloading PDF:', error);
        }
      );
  }

  formatDateToCustom(): string {
    const date = new Date();
    return `${date.getFullYear()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}${date
      .getHours()
      .toString()
      .padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
  }
}
