import { Component, ViewChild } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { IAddHysteroscopyFile } from 'src/app/interfaces/HysteroscopyFile/IAddHysteroscopyFile';
import { IHysteroscopyFileDropdown } from 'src/app/interfaces/HysteroscopyFile/IHysteroscopyFileDropdown';

@Component({
  selector: 'app-hysteroscopy-file-add',
  templateUrl: './hysteroscopy-file-add.component.html',
  styleUrls: ['./hysteroscopy-file-add.component.css'],
})
export class HysteroscopyFileAddComponent {
  private destroy$ = new Subject<void>();

  hysteroscopyDropdown: IHysteroscopyFileDropdown | undefined;

  fileObj?: File;

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editProblemsId?: number;

  hysteroscopySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  hysteroscopyForm: FormGroup = new FormGroup({});

  constructor(
    private readonly hysteroscopyService: HysteroscopyFileService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;

    this.hysteroscopyService.getHysteroscopyDropdown().subscribe({
      next: (pro) => {
        this.hysteroscopyDropdown = pro?.data;
        this.isLoading = false;
      },
      error: (err) => {},
    });

    this.hysteroscopyForm = this.formBuilder.group({
      anatomicalPositionValue: [null, Validators.required],
      dateValue: [null, Validators.required],
      descriptionValue: [null],
      fileValue: [null, Validators.required],
    });

    this.hysteroscopyService.hysteroscopyEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editProblemsId = data?.id;

          this.hysteroscopyForm.patchValue({
            anatomicalPositionValue: data?.anatomicalPositionId,
            anatomicalPositionTitleValue: data?.anatomicalPosition,
            dateValue: data?.date,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editProblemsId = undefined;

          this?.hysteroscopyForm.reset();
        }
      });

    this.hysteroscopyService.hysteroscopyModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  handleCancel() {
    this.hysteroscopyForm.reset();

    this.hysteroscopyService.hysteroscopyModalSub.next(false);

    this.hysteroscopySubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.hysteroscopySubmitted = true;

    if (this.fileObj && this.hysteroscopyForm.valid) {
      const hysteroscopyObj: IAddHysteroscopyFile = {};

      hysteroscopyObj.id = this.editProblemsId;
      hysteroscopyObj.anatomicalPositionId =
        this.hysteroscopyForm.value?.anatomicalPositionValue;
      hysteroscopyObj.date = this.hysteroscopyForm.value?.dateValue;
      hysteroscopyObj.description =
        this.hysteroscopyForm.value?.descriptionValue;
      hysteroscopyObj.file = this.fileObj;

      if (this.isEdit) {
        this.hysteroscopyService.editHysteroscopy(hysteroscopyObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Hysteroscopy edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.hysteroscopyService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit Hysteroscopy!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      } else {
        this.hysteroscopyService.addHysteroscopy(hysteroscopyObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Hysteroscopy added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.hysteroscopyService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add Hysteroscopy!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  onFileSelected(event: any) {
    const file: File | null = event.target.files[0];

    if (file) {
      this.fileObj = file;
      this.hysteroscopyForm.get('fileValue')?.setValue(file?.name);
      this.hysteroscopyForm.get('fileValue')?.setErrors(null);
    } else {
      this.fileObj = undefined;
      this.hysteroscopyForm.get('fileValue')?.setValue(undefined);
      this.hysteroscopyForm?.get('fileValue')?.setErrors({ invalid: true });
    }
  }
}
