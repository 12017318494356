import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Chart } from 'chart.js/auto';
import { HttpClient } from '@angular/common/http';
import { IGenericServiceResponse } from '../../../../interfaces/IServiceResponse';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { environment } from '../../../../../environments/environment';
import { IDateTimeRange } from '../../../../interfaces/Dashboard/IDateTimeRange';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-admissions-and-discharges',
  templateUrl: './dashboard-admissions-and-discharges.component.html',
  styleUrls: ['./dashboard-admissions-and-discharges.component.css'],
})
export class DashboardAdmissionsAndDischargesComponent {
  @Input() dateTimeRange: IDateTimeRange | null = null; // Input to receive the selected date range

  public AdmissionsAndDischargesData: IDashboardChart | null = null;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}
  public allZeroData: boolean = true;

  ngAfterViewInit(): void {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateTimeRange'] && !changes['dateTimeRange'].firstChange) {
      this.fetchData(); // Fetch data whenever dateTimeRange changes
    }
  }

  BarChart(): void {
    const labels = this.AdmissionsAndDischargesData?.labels || [];
    const datasets = this.AdmissionsAndDischargesData?.dataSet || [];

    const data = {
      labels: labels,
      datasets: datasets.map((dataset, index) => ({
        label: this.translate.instant(dataset?.label || ''),
        data: dataset.data,
        backgroundColor: [dataset.backgroundColor],
        borderColor: 'rgba(128, 128, 128, 0.6)',
        borderWidth: 1,
      })),
    };

    new Chart('admissionsAndDischarges', {
      type: 'bar',
      data: data,
      options: {
        indexAxis: 'x',
        elements: {
          bar: {
            borderWidth: 1,
            borderRadius: 10,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.translate.instant('Admissions And Discharges'),
          },
        },
      },
    });
  }

  fetchData(): void {
    let chartStatus = Chart.getChart('admissionsAndDischarges');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.http
      .post<IGenericServiceResponse<IDashboardChart>>(
        `${environment.BACKEND_URL}Dashboard/AdmissionsAndDischarges`,
        {
          startDate: this.dateTimeRange?.startDate,
          endDate: this.dateTimeRange?.endDate,
          offset: new Date().getTimezoneOffset() / 60,
        }
      )
      .subscribe((data) => {
        this.AdmissionsAndDischargesData = data.data;
        this.cdr.detectChanges();
        this.allZeroData = this.AdmissionsAndDischargesData?.dataSet!.every(
          (dataset) => dataset.data!.every((value) => parseFloat(value) === 0)
        );
        console.log(this.allZeroData);

        if (this.AdmissionsAndDischargesData && !this.allZeroData) {
          setTimeout(() => {
            this.BarChart();
          });
        }
      });
  }
}
