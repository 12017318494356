import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IAddGlasgow } from 'src/app/interfaces/Glasgow/IAddGlasgow';
import { IGlasgowDropdown } from 'src/app/interfaces/Glasgow/IGlasgowDropdown';
import { GlasgowService } from 'src/app/services/glasgow.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-glasgow-add-edit',
  templateUrl: './glasgow-add-edit.component.html',
  styleUrls: ['./glasgow-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class GlasgowAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;
  isDropdownLoading = false;

  isEdit = false;
  editGlasgowId?: number;

  glasgowSubmitted = false;

  dropdown: IGlasgowDropdown = {};

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  glasgowForm: FormGroup = new FormGroup({});

  constructor(
    private readonly glasgowService: GlasgowService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = true;
    this.isDropdownLoading = true;

    this.glasgowForm = this.formBuilder.group({
      dateValue: [null, Validators.required],
      eyeOpeningValue: [null, Validators.required],
      verbalResponseValue: [null, Validators.required],
      motorResponseValue: [null, Validators.required],
    });

    this.glasgowService.getGlasgowDropdown().subscribe({
      next: (pro) => {
        this.isDropdownLoading = false;
        this.dropdown = pro?.data;
      },
      error: (err) => {},
    });

    this.glasgowService.glasgowEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.isLoading = false;

          this.editGlasgowId = data?.id;

          this.glasgowForm.patchValue({
            dateValue:
              data?.onSetDateTime && this.convertToLocale(data?.onSetDateTime),
            eyeOpeningValue: data?.eyeOpeningId,
            verbalResponseValue: data?.verbalResponseId,
            motorResponseValue: data?.motorResponseId,
          });
        } else {
          this.isEdit = false;
          this.isLoading = false;

          this.editGlasgowId = undefined;

          this?.glasgowForm.reset();

          this.glasgowForm.patchValue({
            dateValue: this.convertToLocale(),
          });
        }
      });

    this.glasgowService.glasgowModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.glasgowForm.reset();

    this.glasgowService.glasgowModalSub.next(false);

    this.glasgowSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.glasgowSubmitted = true;

    if (this.glasgowForm.valid) {
      const glasgowObj: IAddGlasgow = {};

      glasgowObj.id = this.editGlasgowId;

      glasgowObj.onSetDateTime = moment(
        this.glasgowForm.value?.dateValue
      ).format();

      glasgowObj.eyeOpeningId = this.glasgowForm.get('eyeOpeningValue')?.value;
      glasgowObj.verbalResponseId = this.glasgowForm.get(
        'verbalResponseValue'
      )?.value;
      glasgowObj.motorResponseId =
        this.glasgowForm.get('motorResponseValue')?.value;

      if (this.isEdit) {
        this.glasgowService.editGlasgow(glasgowObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Glasgow record edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.glasgowService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to edit Glasgow record!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      } else {
        this.glasgowService.addGlasgow(glasgowObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Glasgow record added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.glasgowService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add Glasgow record!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  getCurrentDateTime() {
    return new Date().toISOString().slice(0, 16);
  }

  // CONVERT UTC TO LOCAL
  convertToLocale(date?: string) {
    const currentDateTime = date ? moment(date).format() : moment().format();

    const isoDate = currentDateTime.slice(0, 16);

    return isoDate;
  }
}
