import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { IAdmissionAddressInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionAddressInfo';
import { IAdmissionEmergencyContactsInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionEmergencyContactsInfo';
import { IAdmissionMedicalInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionMedicalInfo';
import { IAdmissionPersonalInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionPersonalInfo';
import { IPatientDetailed } from 'src/app/interfaces/Detailed/IPatientDetailed';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import { AddPatientService } from 'src/app/services/addpatient.service';
import { ICoding } from 'src/app/interfaces/ICoding';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IDoctor } from 'src/app/interfaces/IDoctor';
import { AdmissionPersonalInfoComponent } from './admission-personal-info/admission-personal-info.component';
import { AdmissionService } from 'src/app/services/admission.service';
import { IAdmissionDropdown } from 'src/app/interfaces/AdmissionPatient/IAdmissionDropdown';
import Swal from 'sweetalert2';
import { AdmissionRejectModalComponent } from '../admission-reject-modal/admission-reject-modal.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AdmissionAdmitModalComponent } from '../admission-admit-modal/admission-admit-modal.component';
import { AdmissionAddressInfoComponent } from './admission-address-info/admission-address-info.component';
import { AdmissionEmergencyContactsInfoComponent } from './admission-emergency-contacts-info/admission-emergency-contacts-info.component';
import { AdmissionMedicalInfoComponent } from './admission-medical-info/admission-medical-info.component';
import { AdmissionInsuranceComponent } from './admission-insurance/admission-insurance.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { EpisodeOfCareVisitStateService } from 'src/app/services/eocVisitState.service';

@Component({
  selector: 'app-admission-profile',
  templateUrl: './admission-profile.component.html',
  styleUrls: ['./admission-profile.component.css'],
})
export class AdmissionProfileComponent implements OnInit {
  @ViewChild(AdmissionAddressInfoComponent)
  addressInfoComp!: AdmissionAddressInfoComponent;

  @ViewChild(AdmissionEmergencyContactsInfoComponent)
  emergencyContactsInfoComp!: AdmissionEmergencyContactsInfoComponent;

  @ViewChild(AdmissionMedicalInfoComponent)
  medicalInfoComp!: AdmissionMedicalInfoComponent;

  @ViewChild(AdmissionPersonalInfoComponent)
  personalInfoComp!: AdmissionPersonalInfoComponent;

  @ViewChild(AdmissionInsuranceComponent)
  insuranceInfoComp!: AdmissionInsuranceComponent;

  @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();

  public admissionObj: any; // all the forms data combined
  public formSubmitted: boolean = false;
  public isEditMode: boolean = false;

  public admissionPersonalInfoData!: any;
  public admissionMedicalInfoData!: any;
  public admissionAddressInfoData!: any;
  public admissionEmergencyContactsInfoData!: any;
  public admissionInsuranceInfoData!: any;

  // Value Sets
  public bloodTypesList: ICoding[] = [];
  public gendersList: ICoding[] = [];
  public countriesList: ICoding[] = [];
  public documentTypeList: ICoding[] = [];
  public doctorList: IDoctor[] = [];
  public doctors: NzSelectOptionInterface[] = [];
  public placeOfBirthList: ICoding[] = [];
  public closestRelativesList: ICoding[] = [];
  public educationLevelList: ICoding[] = [];
  public familyStatusList: ICoding[] = [];
  public sourceOfIncomeList: ICoding[] = [];
  public religionList: ICoding[] = [];
  public fileList: ICoding[] = [];
  public immobilities: ICoding[] = [];
  public immobilityList: NzSelectOptionInterface[] = [];
  public insurances: ICoding[] = [];
  public insuranceList: NzSelectOptionInterface[] = [];

  public patientID: string = '';
  public patientInfo?: IPatientDetailed;
  public admissionDropdownData: IAdmissionDropdown = {};

  public avatarImageUrl?: any;

  public isPatientInfoLoaded: boolean = false;
  public isLoading: number = 0;

  public isUpdating: boolean = false;

  public canNavigateBack: boolean = false;

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private patientService: PatientService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly addPatient: AddPatientService,
    private formBuilder: FormBuilder,
    private readonly admissionService: AdmissionService,
    private readonly modal: NzModalService,
    private cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private location: Location,
    private episodeOfCareVisitStateService: EpisodeOfCareVisitStateService
  ) {}

  fetchData() {
    this.isLoading = 0;

    this.patientService.patientInfoSub.next({});
    this.patientService.patientLoadingSub.next(true);

    this.route.queryParams.subscribe((params) => {
      this.patientID = params['patientId'];
      this.canNavigateBack = params['canNavigateBack'] === 'true';
    });

    this.patientService.patientIDsignal.set(this.patientID);

    this.patientService.getPatientByID(this.patientID).subscribe({
      next: (response) => {
        this.patientService.patientInfoSub.next(response?.data!);
        this.patientService.patientLoadingSub.next(false);
        this.patientInfo = response?.data; // Assuming data is the property you want

        if (this.patientInfo) {
          this.setAdmissionProfileData(this.patientInfo);
          this.isPatientInfoLoaded = true;
        }

        this.patientService
          .getProfilePicture(this.patientInfo.id?.toString()!)
          .subscribe({
            next: (blob?: Blob) => {
              if (blob) {
                const reader = new FileReader();
                reader.onload = () => {
                  this.avatarImageUrl = reader.result;
                };
                reader.readAsDataURL(blob);
              }
            },
            error: (error) => {
              this.avatarImageUrl = undefined;
              console.error('Error fetching profile picture:', error);
            },
          });

        this.isLoading = this.isLoading + 1;
      },
      error: (err) => {
        console.error('Error fetching patient information:', err);
        this.isPatientInfoLoaded = true;
      },
    });
    this.addPatient.getPatientsDropDown().subscribe({
      next: (response) => {
        this.bloodTypesList = response?.data?.cachedData?.bloodTypes;
        this.gendersList = response?.data?.cachedData?.genders;
        this.countriesList = response?.data?.cachedData?.countries;
        this.documentTypeList = response?.data?.cachedData?.documentType;
        this.doctorList = response?.data?.doctors;

        this.placeOfBirthList = response?.data?.cachedData?.countries;
        this.closestRelativesList =
          response?.data?.cachedData?.closestRelatives;
        this.educationLevelList = response?.data?.cachedData?.educationLevels;
        this.familyStatusList = response?.data?.cachedData?.familyStatuses;
        this.sourceOfIncomeList = response?.data?.cachedData?.sourceOfIncome;
        this.religionList = response?.data?.cachedData?.religions;
        this.fileList = response?.data?.cachedData?.fileType;

        this.immobilities = response?.data?.cachedData?.immobility;
        this.insurances = response?.data?.cachedData?.insurances;

        this.immobilityList = this.immobilities.map((d) => {
          return { label: d.name, value: d.id };
        });

        this.insuranceList = this.insurances.map((d) => {
          return { label: d.name, value: d.id };
        });

        this.doctors = this.doctorList.map((d) => {
          return { label: d.fullName, value: d.id };
        });

        this.isLoading = this.isLoading + 1;
      },
      error: (err) => {},
    });

    this.admissionService.getDropdownData().subscribe({
      next: (d) => {
        // Default value - pending
        this.admissionDropdownData = d.data;
        this.isLoading = this.isLoading + 1;
      },
      error: (err) => {},
    });
  }

  ngOnInit() {
    this.fetchData();
  }

  setAdmissionProfileData(patientInfo: IPatientDetailed) {
    this.admissionPersonalInfoData = {
      id: patientInfo.id,
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      occupation: patientInfo.occupation,
      phone: patientInfo.phone,
      email: patientInfo.email,
      amountOfIncome: patientInfo.amountOfIncome,
      familyStatus: patientInfo.familyStatus,
      sourceOfIncome: patientInfo.sourceOfIncome,
      religion: patientInfo.religion,
      dateOfAdmission: patientInfo.dateOfAdmission,
      placeOfBirthDistrict: patientInfo.placeOfBirthDistrict,
      genderId: patientInfo.genderId,
      dateOfBirth: patientInfo.dateOfBirth,
      placeOfBirth: patientInfo.placeOfBirth,
      closestRelatives: patientInfo.closestRelatives,
      closestRelativesOther: patientInfo.closestRelativesOther,
      educationLevel: patientInfo.educationLevel,
      educationLevelOther: patientInfo.educationLevelOther,
      familyStatusId: patientInfo.familyStatusId,
      familyStatusOther: patientInfo.familyStatusOther,
      sourceOfIncomeId: patientInfo.sourceOfIncomeId,
      religionId: patientInfo.religionId,
      religionOther: patientInfo.religionOther,
      educationLevelId: patientInfo.educationLevelId,
      placeOfBirthId: patientInfo.placeOfBirthId,
      closestRelativesId: patientInfo.closestRelativesId,
      registrationStatusId: patientInfo.registrationStatusId,
      documentDto: patientInfo.documentDto,
      registrationAgentFullName: patientInfo.registrationAgentFullName,
    };

    this.admissionMedicalInfoData = {
      // doctorFullName: patientInfo.doctorFullName,
      assignedDoctors: patientInfo.assignedDoctors,
      bloodType: patientInfo.bloodType,
      height: patientInfo.height,
      weight: patientInfo.weight,
      // doctorId: patientInfo.doctorId,
      bloodTypeId: patientInfo.bloodTypeId,
      immobilityStatusId: patientInfo.immobilityStatusId,
      moh: patientInfo.moh,
    };

    this.admissionAddressInfoData = {
      addressStreet: patientInfo.addressStreet,
      addressTown: patientInfo.addressTown,
      addressPostCode: patientInfo.addressPostCode,
      addressDistrict: patientInfo.addressDistrict,
      addressCountry: patientInfo.addressCountry,
      addressStreetNumber: patientInfo.addressStreetNumber,
      addressApartmentNumber: patientInfo.addressApartmentNumber,
      addressCountryId: patientInfo.addressCountryId,
    };

    this.admissionEmergencyContactsInfoData = {
      contacts: patientInfo.emergencyContacts || [],
    };

    this.admissionInsuranceInfoData = {
      insurance: patientInfo.insurance?.map((i) => i.id) || [],
    };
  }

  isNotPending() {
    return this.patientInfo?.registrationStatusId != 4; // 4 is the id of Waiting List
  }

  isNotRejected() {
    return this.patientInfo?.registrationStatusId != 5; // 5 is the id of rejected
  }

  waitlistPatient() {
    Swal.fire({
      title: `<span style="font-size: 20px;">${this.translate.instant(
        'Add to Waiting List'
      )}</span>`,
      html: `${this.translate.instant(
        'Are you sure that you want to add the following individual to the Waiting List: '
      )} <span style="font-weight: bold;">${this.patientInfo?.firstName} ${
        this.patientInfo?.lastName
      }</span> ?`,

      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed && this.patientInfo?.id) {
        this.admissionService.waitlistPatient(this.patientInfo?.id).subscribe({
          next: (res) => {
            if (this.patientInfo) {
              this.patientInfo.registrationStatusId = 3; // Update the local state immediately | 3 is the id of waitlist
            }
            this.cdr.detectChanges(); // Manually trigger change detection
            this.dataChanged.emit(); // Emit the event here
            Swal.fire({
              text: `${this.translate.instant(
                'Patient added to wait list successfully!'
              )}`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant(
                'Unable to add patient to wait list!'
              )}`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error adding patient to Waiting List:', err);
          },
        });
      }
    });
  }

  openAdmitModal() {
    const modal: NzModalRef<AdmissionAdmitModalComponent> = this.modal.create({
      nzTitle: `${this.translate.instant('Admit Patient')} : ${
        this.patientInfo?.firstName
      } ${this.patientInfo?.lastName}`,
      nzContent: AdmissionAdmitModalComponent,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        patientId: this.patientID,
        bedsList: this.admissionDropdownData.bedAvailableId,
        admissionReasonsList: this.admissionDropdownData.admissionReasons,
      },
    });

    modal.getContentComponent().admitSuccess.subscribe(() => {
      this.fetchData();
      this.dataChanged.emit(); // Emit the event here
    });
  }

  openRejectModal(): void {
    const modal: NzModalRef<AdmissionRejectModalComponent> = this.modal.create({
      nzTitle: `${this.translate.instant('Reject Admission')} : ${
        this.patientInfo?.firstName
      } ${this.patientInfo?.lastName}`,
      nzContent: AdmissionRejectModalComponent,
      nzData: {
        patientId: this.patientID,
        rejectReasonsList: this.admissionDropdownData.rejectedReasons,
      },
      nzFooter: null,
      nzClosable: false,
    });

    modal.getContentComponent().rejectSuccess.subscribe(() => {
      if (this.patientInfo) {
        this.patientInfo.registrationStatusId = 5; // Update the local state immediately | 5 is the id of rejected
      }
      this.cdr.detectChanges(); // Manually trigger change detection
      this.dataChanged.emit(); // Emit the event here
    });
  }

  enterEditMode() {
    this.isEditMode = true;
  }

  requestCancel(onConfirm?: () => void) {
    Swal.fire({
      text: this.translate.instant(
        'Are you sure that you want to revert all the changes?'
      ),
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('No')}`,
      confirmButtonText: `${this.translate.instant('Yes')}`,
      confirmButtonColor: '#0d9488',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        this.revertPatientInfo();
        if (onConfirm) {
          onConfirm();
        }
      }
    });
  }
  revertPatientInfo() {
    // Resetting the form values to the initial patient information
    if (this.patientInfo) {
      this.setAdmissionProfileData(this.patientInfo);
    }

    // Resetting the form controls in each child component
    if (this.personalInfoComp) {
      this.personalInfoComp.initializeForm();
    }
    if (this.addressInfoComp) {
      this.addressInfoComp.initializeForm();
    }
    if (this.emergencyContactsInfoComp) {
      this.emergencyContactsInfoComp.setContacts([]);
    }
    if (this.medicalInfoComp) {
      this.medicalInfoComp.initializeForm();
    }
    if (this.insuranceInfoComp) {
      this.insuranceInfoComp.initializeForm();
    }

    this.personalInfoComp.avatarImageUrl = this.avatarImageUrl;

    // Exit edit mode
    this.isEditMode = false;

    // Trigger change detection
    this.cdr.detectChanges();
  }

  previousScreen() {
    if (this.isEditMode) {
      // If in edit mode, request cancellation, and only if confirmed, decide where to navigate
      this.requestCancel(() => {
        this.navigateBasedOnDiscardStatus();
      });
    } else {
      this.navigateBasedOnDiscardStatus();
    }
  }

  private navigateBasedOnDiscardStatus() {
    if (this.episodeOfCareVisitStateService.getVisitDiscarded()) {
      // Always navigate to inPatients if the visit was discarded
      this.router.navigate(['/ehr/inPatients']);
    } else {
      // Otherwise, navigate back
      this.location.back();
    }
  }

  updatePatientInfo() {
    this.isUpdating = true;
    const errorMessages: string[] = [];

    // Check each form for validity and collect error messages
    this.collectFormErrors(
      this.personalInfoComp.personalInfoForm,
      errorMessages
    );
    this.collectFormErrors(this.medicalInfoComp.medicalInfoForm, errorMessages);
    this.collectFormErrors(
      this.insuranceInfoComp.insuranceInfoForm,
      errorMessages
    );
    this.collectFormErrors(this.addressInfoComp.addressInfoForm, errorMessages);
    this.collectFormErrors(
      this.emergencyContactsInfoComp.emergencyContactsInfoForm,
      errorMessages
    );

    if (errorMessages.length > 0) {
      this.isUpdating = false;
      Swal.fire({
        title: 'Please check the following errors: ',
        html: errorMessages.join('<br>'),
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        color: 'white',
        background: '#ff6969',
        timer: 5000,
      });
      return;
    }
    this.personalInfoComp.personalInfoForm.get('email')?.enable();

    this.personalInfoComp.documentDto.controls.forEach((control) => {
      control.get('id')?.enable();
      control.get('documentCountryIssuedId')?.enable();
      control.get('documentNumber')?.enable();
      control.get('documentTypeId')?.enable();
    });

    const patientInfo: any = {};

    this.admissionAddressInfoData =
      this.addressInfoComp?.addressInfoForm?.value;
    this.admissionEmergencyContactsInfoData =
      this.emergencyContactsInfoComp?.emergencyContactsInfoForm?.value;
    this.admissionMedicalInfoData =
      this.medicalInfoComp?.medicalInfoForm?.value;
    this.admissionPersonalInfoData =
      this.personalInfoComp?.personalInfoForm?.value;
    this.admissionInsuranceInfoData =
      this.insuranceInfoComp?.insuranceInfoForm?.value;

    if (this.admissionPersonalInfoData.firstName) {
      patientInfo.firstName = this.admissionPersonalInfoData.firstName;
    }

    if (this.admissionPersonalInfoData.lastName) {
      patientInfo.lastName = this.admissionPersonalInfoData.lastName;
    }

    if (this.admissionPersonalInfoData.genderId) {
      patientInfo.genderId = this.admissionPersonalInfoData.genderId;
    }

    if (this.admissionPersonalInfoData.dateOfBirth) {
      patientInfo.dateOfBirth = this.admissionPersonalInfoData.dateOfBirth;
    }

    if (this.admissionPersonalInfoData.districtOfBirth) {
      patientInfo.placeOfBirthDistrict =
        this.admissionPersonalInfoData.districtOfBirth;
    }

    if (this.admissionMedicalInfoData.dateOfAdmission) {
      patientInfo.dateOfAdmission =
        this.admissionMedicalInfoData.dateOfAdmission;
    }

    if (this.admissionMedicalInfoData.height) {
      patientInfo.height = this.admissionMedicalInfoData.height;
    }

    if (this.admissionPersonalInfoData.phone) {
      patientInfo.phone = this.admissionPersonalInfoData.phone.e164Number;
    }

    if (this.admissionPersonalInfoData.email) {
      patientInfo.email = this.admissionPersonalInfoData.email;
    }

    if (this.admissionMedicalInfoData.bloodTypeId) {
      patientInfo.bloodTypeId = this.admissionMedicalInfoData.bloodTypeId;
    }

    if (this.admissionMedicalInfoData.weight) {
      patientInfo.weight = this.admissionMedicalInfoData.weight;
    }

    patientInfo.moh = this.admissionMedicalInfoData.moh;

    if (this.admissionMedicalInfoData.assignedDoctors) {
      patientInfo.assignedDoctors =
        this.admissionMedicalInfoData.assignedDoctors.map((id: any) => {
          return {
            doctorId: id,
            primaryDoctor: this.admissionMedicalInfoData?.primaryDoctorId == id,
            doctorFullname: this.doctorList.find((dd) => dd.id == id)?.fullName,
          };
        });
    }

    const addAddressDto: any = {};
    if (this.admissionAddressInfoData.addressStreet) {
      addAddressDto.street = this.admissionAddressInfoData.addressStreet;
    }

    if (this.admissionAddressInfoData.addressTown) {
      addAddressDto.town = this.admissionAddressInfoData.addressTown;
    }

    if (this.admissionAddressInfoData.addressPostCode) {
      addAddressDto.postCode = this.admissionAddressInfoData.addressPostCode;
    }

    if (this.admissionAddressInfoData.addressDistrict) {
      addAddressDto.district = this.admissionAddressInfoData.addressDistrict;
    }

    if (this.admissionAddressInfoData.addressStreetNumber) {
      addAddressDto.streetNumber =
        this.admissionAddressInfoData.addressStreetNumber;
    }

    if (this.admissionAddressInfoData.addressApartmentNumber) {
      addAddressDto.apartmentNumber =
        this.admissionAddressInfoData.addressApartmentNumber;
    }

    if (this.admissionAddressInfoData.addressCountryId) {
      addAddressDto.countryId = this.admissionAddressInfoData.addressCountryId;
    }

    patientInfo.addAddressDto = addAddressDto;

    if (this.admissionPersonalInfoData.documentDto) {
      patientInfo.documents = this.admissionPersonalInfoData.documentDto
        .map((doc: any) => {
          const document: any = {};
          if (doc.id) document.id = doc.id.value;
          if (doc.documentTypeId) document.documentTypeId = doc.documentTypeId;
          if (doc.documentNumber) document.documentNumber = doc.documentNumber;
          if (doc.documentCountryIssuedId)
            document.documentCountryIssuedId = doc.documentCountryIssuedId;
          return document;
        })
        .filter((doc: any) => Object.keys(doc).length > 0);
    }

    if (this.admissionPersonalInfoData.occupation) {
      patientInfo.occupation = this.admissionPersonalInfoData.occupation;
    }

    if (this.admissionPersonalInfoData.dateOfAdmission) {
      patientInfo.dateOfAdmission =
        this.admissionPersonalInfoData.dateOfAdmission;
    }

    if (this.admissionPersonalInfoData.placeOfBirthId) {
      patientInfo.placeOfBirthId =
        this.admissionPersonalInfoData.placeOfBirthId;
    }

    if (this.admissionPersonalInfoData.closestRelativesId) {
      patientInfo.closestRelativesId =
        this.admissionPersonalInfoData.closestRelativesId;
    }
    if (this.admissionPersonalInfoData.closestRelativesOther) {
      patientInfo.closestRelativesOther =
        this.admissionPersonalInfoData.closestRelativesOther;
    }

    if (this.admissionPersonalInfoData.educationLevelId) {
      patientInfo.educationLevelId =
        this.admissionPersonalInfoData.educationLevelId;
    }

    if (this.admissionPersonalInfoData.educationLevelOther) {
      patientInfo.educationLevelOther =
        this.admissionPersonalInfoData.educationLevelOther;
    }

    if (this.admissionPersonalInfoData.familyStatusId) {
      patientInfo.familyStatusId =
        this.admissionPersonalInfoData.familyStatusId;
    }

    if (this.admissionPersonalInfoData.familyStatusOther) {
      patientInfo.familyStatusOther =
        this.admissionPersonalInfoData.familyStatusOther;
    }

    if (this.admissionPersonalInfoData.sourceOfIncomeId) {
      patientInfo.sourceOfIncomeId =
        this.admissionPersonalInfoData.sourceOfIncomeId;
    }

    if (this.admissionPersonalInfoData.religionId) {
      patientInfo.religionId = this.admissionPersonalInfoData.religionId;
    }

    if (this.admissionPersonalInfoData.religionOther) {
      patientInfo.religionOther = this.admissionPersonalInfoData.religionOther;
    }

    if (this.admissionPersonalInfoData.amountOfIncome) {
      patientInfo.amountOfIncome =
        this.admissionPersonalInfoData.amountOfIncome.toString();
    }

    if (this.admissionMedicalInfoData.immobilityValue) {
      patientInfo.immobilityStatusId =
        this.admissionMedicalInfoData.immobilityValue;
    }

    if (this.admissionEmergencyContactsInfoData.contacts) {
      patientInfo.emergencyContacts =
        this.admissionEmergencyContactsInfoData.contacts
          .map((contact: any) => {
            const emergencyContact: any = {};
            if (contact.id) emergencyContact.id = contact.id.value;
            if (contact.documentTypeId)
              emergencyContact.documentTypeId = contact.documentTypeId;
            if (contact.documentNumber)
              emergencyContact.documentNumber = contact.documentNumber;
            if (contact.documentCountryIssuedId)
              emergencyContact.documentCountryIssuedId =
                contact.documentCountryIssuedId;
            if (contact.firstName)
              emergencyContact.firstName = contact.firstName;
            if (contact.lastName) emergencyContact.lastName = contact.lastName;
            if (contact.occupation)
              emergencyContact.occupation = contact.occupation;
            if (contact.phoneNumber)
              emergencyContact.phoneNumber = contact?.phoneNumber;
            if (contact.email) emergencyContact.email = contact.email;
            if (contact.closestRelativeId)
              emergencyContact.closestRelativeId = contact.closestRelativeId;
            if (contact.closestRelativeOther)
              emergencyContact.closestRelativeOther =
                contact.closestRelativeOther;
            const addressData: any = {};
            addressData.street = contact.addressData.street;
            addressData.town = contact.addressData.town;
            addressData.postCode = contact.addressData.postCode;
            addressData.district = contact.addressData.district;
            addressData.streetNumber = contact.addressData.streetNumber;
            addressData.apartmentNumber = contact.addressData.apartmentNumber;
            addressData.countryId = contact.addressData.countryId;

            emergencyContact.addressData = addressData;

            return emergencyContact;
          })
          .filter((contact: any) => Object.keys(contact).length > 0);
    }

    if (this.admissionPersonalInfoData.registrationStatusId) {
      patientInfo.registrationStatusId =
        this.admissionPersonalInfoData.registrationStatusId;
    }

    if (this.admissionInsuranceInfoData.insurance) {
      patientInfo.insurance = this.admissionInsuranceInfoData.insurance.map(
        (i: Number) => ({ id: i })
      );
    }

    if (this.patientInfo?.id) {
      this.patientService
        .updateAdmissionPatient(this.patientInfo?.id, patientInfo)
        .subscribe({
          next: (res) => {
            this.cdr.detectChanges(); // Manually trigger change detection
            this.dataChanged.emit(); // Emit the event here
            this.fetchData();
            this.personalInfoComp.personalInfoForm.get('email')?.disable();

            this.personalInfoComp.documentDto.controls.forEach((control) => {
              control.get('id')?.disable();
              control.get('documentCountryIssuedId')?.disable();
              control.get('documentNumber')?.disable();
              control.get('documentTypeId')?.disable();
            });
            this.isUpdating = false;
            Swal.fire({
              text: 'Patient updated successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            this.isUpdating = false;
            Swal.fire({
              text: 'Unable to update patient!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            this.revertPatientInfo();
            console.error('Error updating patient:', err);
          },
        });

      if (this.personalInfoComp.editedAvatarImage) {
        const formData = new FormData();
        formData.append('PatientId', this.patientInfo.id.toString());

        const blob = new Blob([this.personalInfoComp.editedAvatarImage], {
          type: 'image/jpeg',
        });
        formData.append(
          'PatientPicture',
          blob,
          this.patientInfo?.id?.toString() + 'picture.jpg'
        );

        this.addPatient.uploadProfilePicture(formData).subscribe({
          next: () => {},
          error: (err) => {
            let errorMessage = 'Unable to add patient profile picture!';

            if (err instanceof HttpErrorResponse && err.error?.message) {
              errorMessage += ` Error: ${err.error.message}`;
            }
            Swal.fire({
              text: errorMessage,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      }
    }
    this.isEditMode = false;
  }

  collectFormErrors(
    form: FormGroup,
    errorMessages: string[],
    prefix: string = ''
  ) {
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      const controlErrors = control?.errors;

      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          const errorMessage = this.getErrorMessage(
            keyError,
            key,
            controlErrors[keyError],
            prefix
          );
          if (errorMessage) {
            errorMessages.push(errorMessage);
          }
        });
      }

      if (control instanceof FormGroup) {
        this.collectFormErrors(
          control,
          errorMessages,
          `${prefix}${this.getFieldName(key)}: `
        );
      }

      if (control instanceof FormArray) {
        control.controls.forEach((group: AbstractControl, index: number) => {
          this.collectFormErrors(
            group as FormGroup,
            errorMessages,
            `${prefix}${this.getFieldName(key)} number ${index + 1}: `
          );
        });
      }
    });
  }

  getErrorMessage(
    keyError: string,
    key: string,
    errorValue: any,
    prefix: string
  ): string | null {
    const fieldName = this.getFieldName(key);
    switch (keyError) {
      case 'required':
        return `• ${prefix}${fieldName} is required.`;
      case 'email':
        return `• ${prefix}${fieldName} must be a valid email address.`;
      case 'pattern':
        return `• ${prefix}${fieldName} format is invalid.`;
      case 'minlength':
        return `• ${prefix}${fieldName} must be at least ${errorValue.requiredLength} characters long.`;
      case 'maxlength':
        return `• ${prefix}${fieldName} cannot be more than ${errorValue.requiredLength} characters long.`;
      default:
        return `• ${prefix}${fieldName} is invalid.`;
    }
  }

  getFieldName(fieldName: string) {
    const fieldNames: { [key: string]: string } = {
      //Personal info form fields
      firstName: 'First Name',
      lastName: 'Last Name',
      districtOfBirth: 'District of Birth',
      dateOfBirth: 'Date of Birth',
      placeOfBirthId: 'Place of Birth',
      phone: 'Phone Number',
      email: 'Email',
      familyStatusId: 'Family Status',
      familyStatusOther: 'Other Family Status',
      closestRelativesId: 'Closest Relatives',
      closestRelativesOther: 'Other Closest Relatives',
      educationLevelId: 'Education Level',
      educationLevelOther: 'Other Education Level',
      occupation: 'Occupation',
      amountOfIncome: 'Amount of Income',
      dateOfAdmission: 'Date of Admission',
      sourceOfIncomeId: 'Source of Income',
      religionId: 'Religion',
      religionOther: 'Other Religion',
      registrationStatusId: 'Registration Status',
      documentDto: 'Document',
      // Medical info form fields
      assignedDoctors: 'Assigned Doctors',
      immobilityValue: 'Immobility',
      immobilityStatusId: 'Immobility Status',
      genderId: 'Gender',
      bloodTypeId: 'Blood Type',
      height: 'Height',
      weight: 'Weight',
      registrationAgentFullName: 'Registration Agent',
      primaryDoctorId: 'Primary Doctor',
      // Insurance info form fields
      insurance: 'Insurance',
      // Emergency contacts info form fields
      contacts: 'Contact',
      'contacts.firstName': 'Contact First Name',
      'contacts.lastName': 'Contact Last Name',
      'contacts.occupation': 'Contact Occupation',
      'contacts.phoneNumber': 'Contact Phone Number',
      phoneMockControl: 'Contact Phone Number',
      'contacts.email': 'Contact Email',
      'contacts.documentTypeId': 'Contact Document Type',
      'contacts.documentNumber': 'Contact Document Number',
      'contacts.documentCountryIssuedId': 'Contact Document Country Issued',
      'contacts.addressData.street': 'Contact Address Street',
      'contacts.addressData.streetNumber': 'Contact Address Street Number',
      'contacts.addressData.apartmentNumber':
        'Contact Address Apartment Number',
      'contacts.addressData.postCode': 'Contact Address Post Code',
      'contacts.addressData.district': 'Contact Address District',
      'contacts.addressData.town': 'Contact Address Town',
      'contacts.addressData.countryId': 'Contact Address Country',
      // Address info form fields
      addressStreet: 'Address Street',
      addressTown: 'Address Town',
      addressPostCode: 'Address Post Code',
      addressDistrict: 'Address District',
      addressCountryId: 'Address Country',
      addressStreetNumber: 'Address Street Number',
      addressApartmentNumber: 'Address Apartment Number',
    };

    return fieldNames[fieldName] || fieldName;
  }

  markAllFieldsAsTouched() {
    this.personalInfoComp.personalInfoForm.markAllAsTouched();
    this.medicalInfoComp.medicalInfoForm.markAllAsTouched();
    this.insuranceInfoComp.insuranceInfoForm.markAllAsTouched();
    this.addressInfoComp.addressInfoForm.markAllAsTouched();
    this.emergencyContactsInfoComp.emergencyContactsInfoForm.markAllAsTouched();
  }
}
