import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { SupportingDocumentsService } from 'src/app/services/supportingDocuments.service';
import { IAddDocument } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAddDocument';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { AddPatientService } from 'src/app/services/addpatient.service';
import { IGenericServiceResponse } from 'src/app/interfaces/IServiceResponse';
import { IPatientsDropdown } from 'src/app/interfaces/IPatientsDropdown';

@Component({
  selector: 'app-supporting-documents-add',
  templateUrl: './supporting-documents-add.component.html',
  styleUrls: ['./supporting-documents-add.component.css'],
})
export class SupportingDocumentsAddComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  documentDropdown: { id: number; name: string }[] = [];
  fileObj?: File;
  isVisible = false;
  isLoading = false;
  isEdit = false;
  documentSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef;

  supportingDocumentForm: FormGroup = new FormGroup({});

  constructor(
    private readonly supportingDocumentsService: SupportingDocumentsService,
    private readonly addPatientService: AddPatientService,

    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    console.log('HERE');

    // Fetch dropdown data for file types (example)
    // Fetch dropdown data for file types
    this.addPatientService.getPatientsDropDown().subscribe({
      next: (res: IGenericServiceResponse<IPatientsDropdown>) => {
        this.documentDropdown = res?.data?.cachedData?.fileType;
        console.log(this.documentDropdown);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });

    // Initialize form
    this.supportingDocumentForm = this.formBuilder.group({
      fileTypeId: [null, Validators.required],
      file: [null, Validators.required],
    });

    // Subscribe to modal visibility from the service
    this.supportingDocumentsService.supportingDocumentModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((isVisible) => {
        this.isVisible = isVisible;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalRef.destroy();
  }

  handleCancel() {
    this.supportingDocumentForm.reset();
    this.supportingDocumentsService.supportingDocumentModalSub.next(false);
    this.documentSubmitted = false;
    this.isLoading = false;
    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.documentSubmitted = true;

    if (this.fileObj && this.supportingDocumentForm.valid) {
      const documentObj: IAddDocument = {
        fileTypeId: this.supportingDocumentForm.value.fileTypeId,
        patientId: '',
        file: this.fileObj,
      };

      // Add a new document
      this.supportingDocumentsService
        .addSupportingDocumentFile(documentObj)
        .subscribe({
          next: () => {
            Swal.fire({
              text: 'Supporting Document added successfully!',
              toast: true,
              position: 'bottom-end',
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.supportingDocumentsService.getData();
            this.handleCancel();
          },
          error: () => {
            Swal.fire({
              text: 'Unable to add Supporting Document!',
              toast: true,
              position: 'bottom-end',
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            this.handleCancel();
          },
        });
    } else {
      this.isLoading = false;
    }
  }

  onFileSelected(event: any) {
    const file: File | null = event.target.files[0];

    if (file) {
      this.fileObj = file;
      this.supportingDocumentForm.get('file')?.setValue(file.name);
      this.supportingDocumentForm.get('file')?.setErrors(null);
    } else {
      this.fileObj = undefined;
      this.supportingDocumentForm.get('file')?.setValue(undefined);
      this.supportingDocumentForm.get('file')?.setErrors({ invalid: true });
    }
  }
}
