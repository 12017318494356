import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { ITubeCharacteristicsDetailed } from '../interfaces/TubeCharacteristics/ITubeCharacteristicsDetailed';
import { IAddTubeCharacteristics } from '../interfaces/TubeCharacteristics/IAddTubeCharacteristics';
import { ITubeCharacteristicsDropdown } from '../interfaces/TubeCharacteristics/ITubeCharacteristicsDropdown';

@Injectable()
export class TubeCharacteristicsService {
  public tubeCharacteristicsModalSub = new BehaviorSubject<boolean>(false);

  public tubeCharacteristicsEditSub = new BehaviorSubject<
    ITubeCharacteristicsDetailed | undefined
  >(undefined);

  public tubeCharacteristicsDataSub = new BehaviorSubject<
    ITubeCharacteristicsDetailed[]
  >([]);

  public tubeCharacteristicsLoadingSub = new BehaviorSubject<boolean>(false);
  public tubeCharacteristicsLoadingSub$ =
    this.tubeCharacteristicsLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  async getData() {
    this.tubeCharacteristicsLoadingSub.next(true);
    this.getTubeCharacteristics(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.tubeCharacteristicsDataSub.next(response?.data!);
        this.tubeCharacteristicsLoadingSub.next(false);
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve Tube Characteristics data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error fetching Tube Characteristics data:', err);
      },
    });
  }

  getTubeCharacteristics(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<ITubeCharacteristicsDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<
      IGenericServiceResponse<ITubeCharacteristicsDetailed[]>
    >(
      `${environment.BACKEND_URL}TubeCharacteristic/${patientId}?visitId=${visitId}`,
      {
        headers: headers,
      }
    );
  }

  getTubeCharacteristicsDropdown(): Observable<
    IGenericServiceResponse<ITubeCharacteristicsDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<
      IGenericServiceResponse<ITubeCharacteristicsDropdown>
    >(`${environment.BACKEND_URL}TubeCharacteristic/DropdownData`, {
      headers: headers,
    });
  }

  addTubeCharacteristics(abgObj: IAddTubeCharacteristics) {
    abgObj.visitId = this.eocService.currentVisitId();
    abgObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}TubeCharacteristic`,
      abgObj,
      {
        headers: headers,
      }
    );
  }

  editTubeCharacteristics(abgObj: IAddTubeCharacteristics) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}TubeCharacteristic/${abgObj.id}`,
      abgObj,
      {
        headers: headers,
      }
    );
  }

  deleteTubeCharacteristics(
    medicalAlertId: string
  ): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}TubeCharacteristic/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deleteTubeCharacteristicsFromTable(tubeCharacteristicsId: string) {
    // REMOVE THE Tube Characteristics record
    this.tubeCharacteristicsDataSub.next(
      this.tubeCharacteristicsDataSub.value?.filter(
        (abg) => abg.id?.toString() != tubeCharacteristicsId
      )
    );
  }
}
