import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { ICoding } from 'src/app/interfaces/ICoding';
import { SocialHistoryService } from '../../../../../../../services/socialHistory.service';
import { IAddSocialHistory } from '../../../../../../../interfaces/SocialHistory/IAddSocialHistory';

@Component({
  standalone: true,
  selector: 'app-social-history-add-edit',
  templateUrl: './social-history-add-edit.component.html',
  styleUrls: ['./social-history-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class SocialHistoryAddEditComponent {
  private destroy$ = new Subject<void>();
  public socialHistoryDropdown?: ICoding[];
  public timingUnitDropdown?: ICoding[];

  isVisible = false;
  isLoading = false;
  isDropdownLoading = false;

  isEdit = false;
  editSocialHistoryId?: number;

  socialHistorySubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  socialHistoryForm: FormGroup = new FormGroup({});

  constructor(
    private readonly socialHistoryService: SocialHistoryService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isDropdownLoading = true;
    this.isEdit = false;

    this.socialHistoryForm = this.formBuilder.group({
      socialHistoryIdValue: [null, Validators.required],
      unitAmountValue: [null, Validators.required],
      customTimingUnitIdValue: [null, Validators.required],
      descriptionValue: [null, Validators.required],
    });

    this.socialHistoryService.getSocialHistoryDropdown().subscribe({
      next: (res) => {
        this.socialHistoryDropdown = res?.data?.socialHistory;
        this.timingUnitDropdown = res?.data?.timingUnit;
        this.isDropdownLoading = false;
      },
      error: (err) => {
        console.error('Error fetching dropdown data:', err);
      },
    });

    this.socialHistoryService.socialHistoryEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editSocialHistoryId = data?.id;

          this.socialHistoryForm.patchValue({
            socialHistoryIdValue: data?.socialHistoryId,
            customTimingUnitIdValue: data?.customTimingUnitId,
            unitAmountValue: data?.unitAmount,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editSocialHistoryId = undefined;

          this?.socialHistoryForm.reset();
        }
      });

    this.socialHistoryService.socialHistoryModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.socialHistoryForm.reset();

    this.socialHistoryService.socialHistoryModalSub.next(false);

    this.socialHistorySubmitted = false;

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.socialHistorySubmitted = true;

    if (this.socialHistoryForm.valid) {
      const socialHistoryObj: IAddSocialHistory = {};

      socialHistoryObj.id = this.editSocialHistoryId;
      socialHistoryObj.socialHistoryId =
        this.socialHistoryForm.value?.socialHistoryIdValue;
      socialHistoryObj.customTimingUnitId =
        this.socialHistoryForm.value?.customTimingUnitIdValue;
      socialHistoryObj.unitAmount =
        this.socialHistoryForm.value?.unitAmountValue;
      socialHistoryObj.description =
        this.socialHistoryForm.value?.descriptionValue;

      if (this.isEdit) {
        this.socialHistoryService
          .editSocialHistory(socialHistoryObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Social history edited successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.socialHistoryService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to edit social history!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      } else {
        this.socialHistoryService.addSocialHistory(socialHistoryObj).subscribe({
          next: () => {
            Swal.fire({
              text: 'Social history added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.socialHistoryService.getData();

            this.handleCancel();
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to add social history!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });

            this.handleCancel();
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
