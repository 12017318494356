/* src/app/components/ehr/patients/components/patient-characteristics/patient-characteristics.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-layout {
  background: #f4f4f6;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
.section-header {
  margin-bottom: 1em;
}
/*# sourceMappingURL=patient-characteristics.component-BXFGNRMA.css.map */
