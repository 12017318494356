import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '../services/token.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard extends KeycloakAuthGuard implements CanActivate {
  constructor(
    protected override readonly keycloakAngular: KeycloakService,
    router: Router,
    private readonly userService: UserService,
    private readonly translate: TranslateService,
    private readonly tokenService: TokenService
  ) {
    super(router, keycloakAngular);
  }
  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!this.authenticated) {
      await this.keycloakAngular.login({ redirectUri: window.location.href });
      return false;
    }

    this.keycloakAngular.getToken().then((token) => {
      if (token) {
        const base64Decoded = this.tokenService.base64UrlDecode(
          token.split('.')[1]
        );
        const payload = JSON.parse(this.tokenService.utf8Decode(base64Decoded));
        this.userService.setLocale(payload.locale);
        this.translate.use(payload.locale || 'en');
      }
    });
    return true;
  }
}
