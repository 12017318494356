<div id="calendar" class="demo-app-main">
  <full-calendar
    #calendar
    *ngIf="calendarVisible()"
    [options]="calendarOptions()"
  >
    <ng-template #eventContent let-arg>
      <b>{{ arg.timeText }}</b>
      <i>{{ arg.event.title }}</i>
    </ng-template>
  </full-calendar>
</div>
<div *ngComponentOutlet="addCalentarEventComp"></div>
