/* src/app/components/ehr/in-patients/in-patients.component.css */
.patients-search {
  margin-top: 2em;
  margin-bottom: 2em;
  height: 10%;
}
.patients-table {
  height: 90%;
  min-width: 100%;
}
.mat-mdc-form-field-focus-overlay {
  background-color: red !important;
}
.patients-search-input {
  height: 35px !important;
  margin-right: 3em;
}
@media (max-width: 1140px) {
  .patients-search-input {
    margin-right: 1em !important;
  }
}
.patients-search-buttons {
  height: 35px !important;
  margin-right: 1em;
}
.mat-mdc-input-element {
  height: 35px !important;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
::placeholder {
  color: #c2c2c2;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
}
.search-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-button:hover {
  cursor: pointer;
}
.clear-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: white;
  color: #317d88;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-button {
  height: 100%;
  border: 1px solid #0d9488;
  border-radius: 4px;
  background-color: #0d9488;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear-button:hover {
  cursor: pointer;
}
.register-button:hover {
  cursor: pointer;
}
.add-button {
  border: 1px solid #317d88;
  border-radius: 15px;
  background-color: white;
  color: #317d88;
  padding: 5px;
  font-size: 12px;
  min-width: calc(90% + 5px);
}
.add-button:hover {
  color: white;
  background-color: #317d88;
  cursor: pointer;
}
.discharge-button {
  border: 1px solid rgb(202, 79, 79);
  border-radius: 15px;
  background-color: rgb(202, 79, 79);
  color: white;
  padding: 5px;
  font-size: 12px;
  min-width: 45%;
}
.discharge-button:hover {
  background-color: rgb(149, 62, 62);
  cursor: pointer;
}
.view-button {
  background-color: #0d9488;
  color: white;
  border: 1px solid #317d88;
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
  min-width: 45%;
  margin-left: 5px;
}
.view-button:hover {
  background-color: #317d88;
  color: white;
  cursor: pointer;
}
.full-width {
  margin-left: 0px;
  min-width: calc(90% + 5px) !important;
}
.patients-content-paginator-hide {
  display: none !important;
}
.parent-body {
  height: 100%;
}
.patients-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
}
::ng-deep .mat-mdc-paginator-outer-container {
  margin-top: 0em;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
input {
  color: black !important;
}
textarea {
  border: 1px solid #d9d9d9;
  margin-left: 1em;
  padding-left: 11px;
}
::ng-deep .mat-column-inpatient-actions {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 200px !important;
  padding-right: 0px !important;
}
::ng-deep .mat-column-location {
  max-width: 300px !important;
  width: 300px !important;
  min-width: 100px !important;
}
::ng-deep .mat-column-patientFullName {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 100px !important;
}
::ng-deep .mat-column-sepsis {
  max-width: 150px !important;
  width: 150px !important;
  min-width: 150px !important;
}
nz-progress {
  width: 100% !important;
}
.inputs {
  width: 100%;
}
.footer {
  margin-top: 2em;
}
.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}
.cancel-btn:hover {
  background: lightgray !important;
}
.save-btn {
  margin-right: 1em;
}
.add-btn {
  margin-bottom: 1em;
}
.form-row {
  margin-top: 1em;
}
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
  margin-left: 1em;
  width: 100%;
}
nz-select {
  margin-left: 1em;
}
label {
  font-weight: 500;
}
::ng-deep .ant-modal {
  min-width: 520px !important;
  width: 50% !important;
}
.reactions-container {
  margin-top: 2em;
  padding: 1.5em;
  border: 2px solid rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}
.left-margin {
  margin-left: 1em;
}
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
.ant-tooltip,
.ant-tooltip-inner,
.ant-tooltip-arrow {
  display: none !important;
}
::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}
::ng-deep .ant-btn:focus {
  color: black;
}
::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}
::ng-deep .material-icons {
  font-size: 22px;
}
::ng-deep .table-header.ant-layout-header {
  background-color: rgba(118, 195, 188, 0.15) !important;
  border: 1px solid #d8d8d8 !important;
}
.title {
  padding-left: 1em;
  padding-right: 1em;
}
.table-header-row {
  height: 100%;
}
.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}
.no-known-content {
  display: flex !important;
}
.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}
::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}
::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}
::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}
.add-icon {
  margin-right: 0.2em;
}
@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}
.remove-btn {
  color: rgb(24, 146, 136) !important;
}
.remove-btn:hover {
  cursor: pointer;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
form {
  border: none !important;
}
.bed-submitted .ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.discharge-submitted .ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
::ng-deep .ant-modal-content {
  border-radius: 10px;
}
::ng-deep .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
}
::ng-deep nz-embed-empty {
  font-size: 16px !important;
  font-weight: 500 !important;
}
::ng-deep .ant-picker {
  margin-left: 1em !important;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}
.patient-dropdown {
  width: 100%;
}
:is() .ant-progress-inner {
  background-color: rgba(49, 125, 136, 0.2) !important;
}
:is() .ant-progress-inner {
  background-color: rgba(255, 184, 67, 0.29) !important;
}
:is() .ant-progress-inner {
  background-color: rgba(229, 86, 89, 0.25) !important;
}
.low-risk {
  background-color: #d0f0cf;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.mild-risk {
  background-color: #fff9d4;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.moderate-risk {
  background-color: #ffedd4;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.high-risk {
  background-color: #ffdfdf;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.moderate-rv {
  color: orange;
}
.severe-rv {
  color: red;
}
::ng-deep .mat-mdc-table {
  overflow-x: auto;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
.no-patients {
  margin-top: 15em;
  font-size: large;
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
::ng-deep .mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mdc-circular-progress__determinate-circle {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mat-mdc-progress-spinner {
  stroke: rgb(13, 148, 136) !important;
}
::ng-deep .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: rgb(13, 148, 136) !important;
}
.inPatient-spinner {
  margin-top: 3em;
}
/*# sourceMappingURL=in-patients.component-QMXJR3TZ.css.map */
