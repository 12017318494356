<nz-modal
  #modalRef
  nzSize
  [(nzVisible)]="isVisible"
  [nzTitle]="(isEdit ? 'Edit' : 'Add') + ' Supporting Document' | translate"
  (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel"
  nzOkText="Save"
  nzOkType="primary"
  [nzFooter]="null"
  class="custom-modal-class"
>
  <ng-container *nzModalContent>
    <form
      [formGroup]="supportingDocumentForm"
      (ngSubmit)="submitForm()"
      [ngClass]="{
        'document-submitted': documentSubmitted,
        'document-not-submitted': !documentSubmitted
      }"
    >
      <!-- File Type Field -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "File Type" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select
            class="inputs document"
            nzShowSearch
            nzAllowClear
            [nzPlaceHolder]="'File Type' | translate"
            formControlName="fileTypeId"
            [nzLoading]="isLoading"
          >
            <nz-option
              *ngFor="let type of documentDropdown"
              [nzLabel]="type.name"
              [nzValue]="type.id"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <!-- File Upload Field -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{ "File" | translate }}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input
            class="document upload"
            [ngClass]="{
              'ng-invalid': !this.supportingDocumentForm.value?.file
            }"
            style="margin-left: 1em"
            type="file"
            (change)="onFileSelected($event)"
            accept=".pdf"
          />
        </div>
      </div>

      <!-- Form Footer with Save/Cancel Buttons -->
      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button
          nz-button
          nzPrimary
          class="save-btn"
          type="submit"
          [nzLoading]="isLoading"
          [disabled]="isLoading"
        >
          {{ "Save" | translate }}
        </button>
        <button
          nz-button
          nzDanger
          class="cancel-btn"
          type="button"
          (click)="handleCancel()"
          [disabled]="isLoading"
        >
          {{ "Cancel" | translate }}
        </button>
      </div>
    </form>
  </ng-container>
</nz-modal>
