/* src/app/components/ehr/patients/components/medication-summary-section/current-medication/current-medication.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
/*# sourceMappingURL=current-medication.component-RS4MUJTB.css.map */
