/* src/app/components/ehr/register-patient/image-cropper/image-cropper.component.css */
.cropper-container {
  padding: 50px;
}
.image-cropper-dialog {
  max-width: 600px;
  padding: 20px;
}
.image-cropper-dialog .cropper-container {
  position: relative;
}
.image-cropper-dialog .cropper-container img {
  max-width: 100%;
}
.image-cropper-dialog .cropper-container .cropper-view-box {
  border: 2px solid #007bff;
  overflow: hidden;
}
.image-cropper-dialog .cropper-container .cropper-face {
  background-color: rgba(0, 0, 0, 0.6);
}
.image-cropper-dialog .zoom-buttons {
  margin-top: 10px;
}
.image-cropper-dialog .zoom-buttons button {
  margin-right: 5px;
}
.upload-image-btn {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-image-btn {
  height: 100%;
  border: 1px solid #883138;
  border-radius: 4px;
  background-color: #883138 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-image-btn,
.upload-image-btn:hover {
  cursor: pointer;
}
.row-size {
  justify-content: space-evenly;
  height: 50px;
}
/*# sourceMappingURL=image-cropper.component-HGS7VZ3Q.css.map */
