/* src/app/components/ehr/admission/admission-profile/admission-documents/document/document.component.css */
.document-container.odd-line {
  background-color: #f9f9f9;
}
.document-container.even-line {
  background-color: #e9e9e9;
}
.download:hover,
.delete-btn:hover {
  cursor: pointer;
}
.download,
.delete {
  margin-left: 0.5em;
}
.delete {
  background-color: red;
}
#inputFile::-webkit-file-upload-button {
  visibility: hidden;
}
.margin {
  margin: 15px;
}
/*# sourceMappingURL=document.component-F47AA6KT.css.map */
