<div fxLayout="row" class="allergy-expanded">
    <div fxLayout="col" fxFlex="100" class="allergy-details">
        <nz-layout>
            <nz-header>{{"Details" | translate}}</nz-header>
            <nz-content>
                <div fxLayout="row" class="detail-row">
                    <div fxLayout="col" fxFlex="50" class="detail-label">{{'Description' | translate}}</div>
                    <div fxLayout="col" fxFlex="50" class="detail-info">{{item.description || "-"}}</div>
                </div>

            </nz-content>
        </nz-layout>
    </div>
</div>
