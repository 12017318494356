/* src/app/components/ehr/register-patient/medical-info/medical-info.component.css */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
.add-patient-row {
  margin-bottom: 2em;
}
.add-patient-col {
  width: 100%;
  margin-left: 1em;
  margin-right: 2em;
  height: 35px !important;
}
.add-patient-inputZorro {
  height: 100% !important;
  width: 100%;
  height: 35px !important;
}
.add-patient-multi-inputZorro {
  height: 100% !important;
  width: 100%;
  min-height: 35px !important;
}
.add-patient-multi-inputZorro > ::ng-deep .ant-select-selector {
  height: 35px !important;
}
.add-patient-input {
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
  min-height: 35px;
}
select.ng-valid.ng-touched {
  color: black;
}
input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: #c2c2c2;
}
.add-patient-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
.submitted .ng-invalid {
  border: red 1px solid;
  border-radius: 2px;
}
.hio {
  margin-left: 1em;
}
.hio:hover {
  cursor: pointer;
}
.custom-checkbox {
  margin-left: 1em;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
.label-row-2 {
  margin-top: 3em;
  margin-bottom: 3em;
}
.radio-button-list label {
  display: block;
  padding: 5px 0;
}
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.radio-label input {
  margin-right: 8px;
}
input[type=radio] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #c2c2c2;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}
input[type=radio]:checked {
  background-color: #0d9488;
  border: 2px solid #0d9488;
}
input[type=radio]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}
.h100i {
  height: 100% !important;
}
::ng-deep .ant-select-selector {
  height: 100% !important;
}
/*# sourceMappingURL=medical-info.component-BNFQQCSE.css.map */
