<div class="margin">
  <span *ngIf="document" style="margin-left: 1em">
    <em>{{ document.filePath }}</em>
    <i nz-icon nzType="download" nzTheme="outline" class="download" (click)="downloadDocument()"></i>
  </span>
  <span *ngIf="document && isEditMode" style="margin-left: 1em">
    <i *ngIf="document" nz-icon nzType="delete" nzTheme="twotone" nzTwotoneColor="#ff0000" class="delete-btn"
      (click)="deleteDocument()"></i>
  </span>
  <input *ngIf="!document" #inputFile style="margin-left: 1em" type="file" (change)="onFileSelected($event)"
    accept=".pdf" [disabled]="!isEditMode" />
</div>