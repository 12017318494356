import { Injectable } from '@angular/core';
import { Calendar, EventApi } from '@fullcalendar/core';
import { ResourceSourceInput } from '@fullcalendar/resource';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CalendarEventsService {
  // For modal visibility control and passing selected dates
  public addCalendarEventModalSub = new BehaviorSubject<{
    showModal: boolean;
    start?: Date;
    end?: Date;
    event?: EventApi;
    calendarApi?: Calendar;
    patients?: ResourceSourceInput;
    personnel?: ResourceSourceInput;
    departments?: ResourceSourceInput;
  }>({
    showModal: false,
  });

  // BehaviorSubject to store and emit new events
  private eventSubject = new BehaviorSubject<any>(null);

  // Observable for components to subscribe to
  event$ = this.eventSubject.asObservable();

  // Emit new event (temporary until backend is created)
  addEvent(event: any) {
    this.eventSubject.next(event);
  }
}
