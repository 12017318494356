import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IAddArterialBloodGas } from 'src/app/interfaces/ArterialBloodGas/IAddArterialBloodGas';
import { ArterialBloodGasService } from 'src/app/services/arterialBloodGas.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-arterial-blood-gas-add-edit',
  templateUrl: './arterial-blood-gas-add-edit.component.html',
  styleUrls: ['./arterial-blood-gas-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class ArterialBloodGasAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editArterialBloodGasId?: number;

  arterialBloodGasSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  arterialBloodGasForm: FormGroup = new FormGroup({});

  constructor(
    private readonly arterialBloodGasService: ArterialBloodGasService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = false;

    this.arterialBloodGasForm = this.formBuilder.group({
      dateValue: [null, Validators.required],
      phValue: [null, Validators.required],
      hco3Value: [null, Validators.required],
      paco2Value: [null, Validators.required],
      pao2Value: [null, Validators.required],
      sao2Value: [null, Validators.required],
    });

    this.arterialBloodGasService.arterialBloodGasEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editArterialBloodGasId = data?.id;

          this.arterialBloodGasForm.patchValue({
            dateValue:
              data?.onSetDateTime && this.convertToLocale(data?.onSetDateTime),
            phValue: data?.ph,
            hco3Value: data?.hco3,
            paco2Value: data?.paco2,
            pao2Value: data?.pao2,
            sao2Value: data?.sao2,
          });
        } else {
          this.isEdit = false;
          this.editArterialBloodGasId = undefined;

          this?.arterialBloodGasForm.reset();

          this.arterialBloodGasForm.patchValue({
            dateValue: this.convertToLocale(),
          });
        }
      });

    this.arterialBloodGasService.arterialBloodGasModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.arterialBloodGasForm.reset();

    this.arterialBloodGasService.arterialBloodGasModalSub.next(false);

    this.arterialBloodGasSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.arterialBloodGasSubmitted = true;

    if (this.arterialBloodGasForm.valid) {
      const arterialBloodGasObj: IAddArterialBloodGas = {};

      arterialBloodGasObj.id = this.editArterialBloodGasId;

      arterialBloodGasObj.onSetDateTime = moment(
        this.arterialBloodGasForm.value?.dateValue
      ).format();

      arterialBloodGasObj.ph = this.arterialBloodGasForm.get('phValue')?.value;
      arterialBloodGasObj.hco3 =
        this.arterialBloodGasForm.get('hco3Value')?.value;
      arterialBloodGasObj.paco2 =
        this.arterialBloodGasForm.get('paco2Value')?.value;
      arterialBloodGasObj.pao2 =
        this.arterialBloodGasForm.get('pao2Value')?.value;
      arterialBloodGasObj.sao2 =
        this.arterialBloodGasForm.get('sao2Value')?.value;

      if (this.isEdit) {
        this.arterialBloodGasService
          .editArterialBloodGas(arterialBloodGasObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Arterial Blood Gas edited successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.arterialBloodGasService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to edit Arterial Blood Gas!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      } else {
        this.arterialBloodGasService
          .addArterialBloodGas(arterialBloodGasObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'Arterial Blood Gas added successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.arterialBloodGasService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to add Arterial Blood Gas!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      }
    } else {
      this.isLoading = false;
    }
  }

  getCurrentDateTime() {
    return new Date().toISOString().slice(0, 16);
  }

  // CONVERT UTC TO LOCAL
  convertToLocale(date?: string) {
    const currentDateTime = date ? moment(date).format() : moment().format();

    const isoDate = currentDateTime.slice(0, 16);

    return isoDate;
  }
}
