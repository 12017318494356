<nz-layout>
    <nz-content>
        <ng-container>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px">
                <div fxFlex="50" fxFlex.lt-lg="100">
                    <!-- <app-reusable-table class="w-100" title="Vital Signs" [columns]="[
                        'Onset Date',
            'Temperature (°C)',
            'Respiratory Rate (bpm)',
            'Heart Rate (bpm)','Systolic Blood Pressure (mm[Hg])',
            'Diastolic Blood Pressure (mm[Hg])'
        ,'Urine Output (ml/kg/h)', 'SpO2 (%)']" [rowFields]="['onSetDateTime','temperature','respiratoryRate','heartRate','systolicBloodPressure',
            'diastolicBloodPressure',
            'urineOutput',
            'spO2']" [data]="vitalSigns" [addComponent]="addComp" [isLoading]="isLoading"
                        [isNoKnownLoading]="isNoKnownLoading" [deleteFun]="deleteFun" [openModalFun]="openModalFun"
                        [isPreview]="false" [refValues]="referenceValues" /> -->

                    <app-reusable-reverse-table title="Vital Signs" [rowNames]="[
                    'Temperature (°C)',
                    'Respiratory Rate (bpm)',
                    'Heart Rate (bpm)','Systolic Blood Pressure (mm[Hg])',
                    'Diastolic Blood Pressure (mm[Hg])'
                ,'Urine Output (ml/kg/h)', 'SpO2 (%)']" [rowFields]="['temperature','respiratoryRate','heartRate','systolicBloodPressure',
            'diastolicBloodPressure',
            'urineOutput',
            'spO2']" colIdentifier="onSetDateTime" [data]="vitalSigns || []" [addComponent]="addComp"
                        [deleteFun]="deleteFun" [isLoading]="isLoading" [openModalFun]="openModalFun"
                        [isPreview]="false" />
                </div>
                <div fxFlex="50" fxFlex.lt-lg="100">
                    <app-vital-signs-chart class="w-100" [data]="vitalSigns" />
                </div>
            </div>
        </ng-container>
    </nz-content>
</nz-layout>