<form [formGroup]="admitForm" (ngSubmit)="submitForm()" [ngClass]="{ 'item-submitted': formSubmitted }"
  class="form-spacing">


  <div class="form-spacing">
    <!-- Building Dropdown -->
    <div class="input-row">
      <div class="label-row">
        <label>{{ "Building" | translate }}</label>
      </div>
      <div class="input-element">
        <nz-select formControlName="building" nzShowSearch nzAllowClear [nzPlaceHolder]="'Select Building' | translate"
          class="select" onChange="onBuildingChange($event)">
          <ng-container *ngFor="let b of ddBuildings">
            <nz-option [nzLabel]="b.name" [nzValue]="b"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>

    <!-- Floor Dropdown -->
    <div class="input-row">
      <div class="label-row">
        <label>{{ "Floor" | translate }}</label>
      </div>
      <div class="input-element">
        <nz-select formControlName="floor" nzShowSearch nzAllowClear [nzPlaceHolder]="'Select Floor' | translate"
          class="select" onChange="onFloorChange($event)">
          <ng-container *ngFor="let f of ddFloors">
            <nz-option [nzLabel]="f.name" [nzValue]="f"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>

    <!-- Ward Dropdown -->
    <div class="input-row">
      <div class="label-row">
        <label>{{ "Ward" | translate }}</label>
      </div>
      <div class="input-element">
        <nz-select formControlName="ward" nzShowSearch nzAllowClear [nzPlaceHolder]="'Select Ward' | translate"
          class="select" onChange="onWardChange($event)">
          <ng-container *ngFor="let w of ddWards">
            <nz-option [nzLabel]="w.name" [nzValue]="w"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>

    <!-- Room Dropdown -->
    <div class="input-row">
      <div class="label-row">
        <label>{{ "Room" | translate }}</label>
      </div>
      <div class="input-element">
        <nz-select formControlName="room" nzShowSearch nzAllowClear [nzPlaceHolder]="'Select Room' | translate"
          class="select" onChange="onRoomChange($event)">
          <ng-container *ngFor="let r of ddRooms">
            <nz-option [nzLabel]="r.name" [nzValue]="r"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>


    <!-- Bed Dropdown -->
    <div class="input-row">
      <div class="label-row">
        <label>{{ "Bed" | translate }}</label>
      </div>
      <div class="input-element">
        <nz-select formControlName="bed" nzShowSearch nzAllowClear [nzPlaceHolder]="'Select Bed' | translate"
          class="select">
          <!--                   onChange="onBedChange($event)">-->
          <ng-container *ngFor="let bed of ddBeds">
            <nz-option [nzLabel]="bed.name" [nzValue]="bed"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>





    <div class="input-row">
      <div class="label-row">
        <label>{{ "Admission Reason" | translate }}</label>
      </div>
      <div class="input-row-2">
        <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="'Select Admission Reason' | translate"
          formControlName="admissionReason" class="select">
          <ng-container *ngFor="let reason of admissionReasonsList">
            <nz-option [nzLabel]="reason.name" [nzValue]="reason.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>
    </div>

    <div class="input-row">
      <div class="label-row">
        <label>{{ "Notes" | translate }}<span class="optional">{{ "(optional)" | translate }}</span></label>
      </div>
      <div class="input-row-2">
        <textarea rows="4" nz-input class="text-area" placeholder="{{ 'Notes' | translate }}"
          formControlName="notes"></textarea>
      </div>
    </div>

  </div>

  <div class="btn-row">
    <button nz-button nzPrimary type="submit" [nzLoading]="isLoading" [disabled]="isLoading" class="save-btn">
      {{ "Save" | translate }}
    </button>
    <button nz-button nzDanger type="button" (click)="cancel()" [disabled]="isLoading" class="cancel-btn">
      {{ "Cancel" | translate }}
    </button>
  </div>
</form>
