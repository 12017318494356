import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { combineLatest, debounceTime, Subject } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { ImagingService } from 'src/app/services/imaging.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-imaging-section',
  templateUrl: './imaging-section.component.html',
  styleUrls: ['./imaging-section.component.css'],
})
export class ImagingSectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: number;
  @Output() selectedOptionChange = new EventEmitter<number>();

  constructor(private readonly imagingService: ImagingService) {}

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([this.imagingService.imagingLoadingSub$]).subscribe(
        ([value1]) => {
          const sectionPath = this.submenu?.find(
            (sm) => sm.id == this.selectedOption
          )?.path;
          if (!value1 && sectionPath) {
            const element = document.getElementById(sectionPath);
            if (element) {
              // menu, patient header, title, margin
              const offset = element.offsetTop - 120 - 66 - 64 - 16;
              window.scroll({
                top: offset,
                behavior: 'smooth',
              });

              this.scrollSubject
                .pipe(
                  debounceTime(100) // Adjust debounce time as needed (in milliseconds)
                )
                .subscribe(() => {
                  // Your existing scroll logic here
                  const imf = document.getElementById(paths.imagingFiles);
                  const mss = document.getElementById(
                    paths.multipleSclerosisSegmentation
                  );

                  if (mss) {
                    const rect = mss.getBoundingClientRect();
                    if (rect.top >= 120) {
                      this.selectedOption = this.submenu?.find(
                        (sm) => sm.path == paths.multipleSclerosisSegmentation
                      )?.id;

                      this.selectedOptionChange.emit(this.selectedOption);
                    }
                  }

                  if (imf) {
                    const rect = imf.getBoundingClientRect();
                    if (rect.top >= 120) {
                      this.selectedOption = this.submenu?.find(
                        (sm) => sm.path == paths.imagingFiles
                      )?.id;

                      this.selectedOptionChange.emit(this.selectedOption);
                    }
                  }
                });
            }
          }
        }
      );
    }, 500);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
