<nz-layout class="my-patients-table">
    <nz-header *ngIf="title" class="table-header">
        <div class="table-header-row margin-lr" *ngIf="(isEOC()||isInpatientView()) || isPreview || data?.length! > 0">
            <div class="table-header-cell">
                {{ title | translate }}
                <span class="margin-llr"
                    *ngIf="!isLoading && (((isEOC()||isInpatientView())) || isPreview) && ((this.dataGetList?.noKnown || this.dataGetList?.noInfo)
                || (this.dataGetList?.noKnown==false && this.dataGetList?.noInfo==false && this.dataGetList?.getAll==null && this.dataGetList?.getUnknown==null))">
                    <div class="no-known-content">
                        <nz-switch [ngModel]="this.dataGetList?.noKnown"
                            [nzDisabled]="this.dataGetList?.isSubmitted || isPreview" [nzLoading]="isNoKnownLoading"
                            (ngModelChange)="handleToggle()"></nz-switch>
                        <div>{{"No known" | translate}}</div>
                        <div class="add-title">&nbsp;</div>
                        <div class="add-title">{{title | translate}}</div>
                    </div>
                </span>
            </div>
            <div class="table-header-cell margin-lrr"><button nz-button nzShape="round" (click)="openModal()"
                    *ngIf="(isEOC()||isInpatientView()) && addComponent && openModalFun && !isPreview">
                    <div fxLayout="row">
                        <div fxLayout="col"><mat-icon class="add-icon">add_circle_outline</mat-icon>
                        </div>
                        <div fxLayout="col" class="add-title">
                            {{"Add" | translate}} {{title | translate}}
                        </div>
                    </div>
                </button>
            </div>
        </div>

        <div fxLayout="row" class="table-header-row"
            *ngIf="!(isEOC()||isInpatientView()) && !isPreview && !(data?.length! > 0)">
            <div fxLayout="col" class="title" *ngIf="!isLoading">
                {{ title | translate }} - {{getUnknownText() | translate}}
            </div>
        </div>
    </nz-header>
    <nz-content *ngIf="((data?.length! > 0 && !this.dataGetList?.noKnown) || isLoading) && !isNoKnownLoading">
        <ng-content></ng-content>
    </nz-content>
</nz-layout>

<div *ngComponentOutlet="addComponent"></div>