/* src/app/components/ehr/admission/admission-profile/admission-documents/admission-documents.component.css */
.document-container.odd-line {
  background-color: #f9f9f9;
}
.document-container.even-line {
  background-color: #e9e9e9;
}
.document-container {
  margin: 1em;
}
.document-container-top {
  margin-top: 0px !important;
}
/*# sourceMappingURL=admission-documents.component-W2WS264K.css.map */
