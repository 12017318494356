import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { Location } from '@angular/common';
import { IHysteroscopyFileDropdown } from 'src/app/interfaces/HysteroscopyFile/IHysteroscopyFileDropdown';
import { IAddHysteroscopyFile } from 'src/app/interfaces/HysteroscopyFile/IAddHysteroscopyFile';
import { IAddHysteroscopyVideo } from 'src/app/interfaces/HysteroscopyFile/IAddHysteroscopyVideo';
import { SpeechRecognitionService, continuous } from '@ng-web-apis/speech';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';

@Component({
  selector: 'app-hysteroscopy-examination',
  templateUrl: './hysteroscopy-examination.component.html',
  styleUrls: ['./hysteroscopy-examination.component.css'],
})
export class HysteroscopyExaminationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('videoElement') videoElement!: ElementRef;
  @ViewChild('statusText', { static: false }) statusTextElement!: ElementRef;
  @ViewChild('statusColor', { static: false }) statusColorElement!: ElementRef;

  videoStream!: MediaStream;
  devices!: MediaDeviceInfo[];
  selectedDeviceId!: string;
  hysteroscopyDropdown: IHysteroscopyFileDropdown | undefined;
  currentAnatomicalPositionIndex = 0; // 1st anatomical position index
  currentAnatomicalPosition = 'Cervical External Os'; // 1st anatomical position name
  paused = false;
  examinationStopped = false;
  recording = false;
  isAnyFileProcessing = false;
  error = '';
  showEmbeddedHuman = true;
  isListening = false;
  isFullscreen = false;
  lastCommand = '';
  mediaRecorder!: MediaRecorder;
  recordedFrames: Blob[] = [];
  recordingInterval: any;
  lastCommandTime: any;
  showVideo = true; // Initially, the video is displayed

  private subscriptions: Subscription[] = [];
  private speechRecognitionSubscription!: Subscription;
  private stop$ = new Subject<void>();

  base_path: string =
    '../../../../../../../assets/images/HysteroscopyExamination/';
  anatomical_positions: string[] = [
    'cervical_external_os',
    'cervical_internal_os',
    'cervical_canal',
    'panoramic_view',
    'fundus',
    'fundus_left',
    'cornua_left',
    'ostium_left',
    'fundus_right',
    'cornua_right',
    'ostium_right',
    'mid_uterus_anterior_wall',
    'mid_uterus_posterior_wall',
    'uterine_isthmus_anterior_wall',
    'uterine_isthmus_posterior_wall',
  ];

  images: { [key: number]: { location?: string } } = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private hysteroscopyFileService: HysteroscopyFileService,
    private location: Location,
    private speechRecognitionService: SpeechRecognitionService,
    private readonly patientService: PatientService,
    private cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly eocService: EpisodeOfCareService,
    private renderer: Renderer2
  ) {}

  async getVideoDevices(): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices
      .filter((device) => device.kind === 'videoinput')
      .map((device) => {
        // Modify the label to remove anything after the first space in parentheses
        const updatedLabel = device.label.replace(/\s*\(.*?\)$/, '');
        return {
          ...device,
          label: updatedLabel,
        };
      });
  }

  toggleVideoVisibility() {
    this.showVideo = !this.showVideo;
    this.cdr.detectChanges();
  }

  async initCamera() {
    try {
      const constraints: MediaStreamConstraints = {
        video: {
          deviceId: this.selectedDeviceId
            ? { exact: this.selectedDeviceId }
            : undefined,
        },
      };
      this.videoStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.videoElement.nativeElement.srcObject = this.videoStream;
      this.cdr.detectChanges();
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  }

  async switchCamera(deviceId: string) {
    this.selectedDeviceId = deviceId;
    await this.initCamera();
  }

  initializeImages(): void {
    this.anatomical_positions.forEach((position, index) => {
      this.images[index] = {
        location: `${this.base_path}${position}.png`,
      };
    });
  }

  async ngOnInit() {
    this.initializeImages();
    this.devices = await this.getVideoDevices();
    if (this.devices.length > 0) {
      this.selectedDeviceId = this.devices[0].deviceId;
      this.initCamera();
      this.updateStatusTextAndColor();
    } else {
      console.error('No video devices found.');
    }

    this.hysteroscopyFileService.getHysteroscopyDropdown().subscribe({
      next: (pro) => {
        this.hysteroscopyDropdown = pro?.data;
      },
      error: (err) => {},
    });

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  ngAfterViewInit() {
    this.updateStatusTextAndColor();
  }

  ngOnDestroy() {
    this.stopListening();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop());
    }
    if (this.speechRecognitionSubscription) {
      this.speechRecognitionSubscription.unsubscribe();
    }
    clearInterval(this.recordingInterval);
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop());
    }
    this.stop$.next();
    this.stop$.complete();

    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  speak(text: string) {
    speechSynthesis.cancel();

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.onend = () => {
      if (this.isListening) this.startVoiceCommands();
    };
    speechSynthesis.speak(utterance);
  }

  handleListening() {
    this.isListening = true;
    this.startVoiceCommands();
  }

  stopListening() {
    this.isListening = false;
    if (this.speechRecognitionSubscription) {
      this.speechRecognitionSubscription.unsubscribe();
    }
    this.stop$.next();
  }

  toggleListening() {
    if (this.isListening) {
      this.stopListening();
    } else {
      this.handleListening();
    }
  }
  updateStatusTextAndColor() {
    let status = { text: 'Live', color: '#ff4343' };

    if (!this.videoElement) {
      status = { text: 'No signal detected', color: 'rgba(255, 0, 0, 1)' };
    } else if (this.recording) {
      status = { text: 'Recording', color: 'rgba(255, 0, 0, 1)' };
    } else if (this.paused) {
      status = { text: 'Paused', color: 'rgba(255, 165, 0, 1)' };
    }

    // Update DOM directly using Renderer2
    this.renderer.setProperty(
      this.statusTextElement.nativeElement,
      'textContent',
      status.text
    );
    this.renderer.setStyle(
      this.statusColorElement.nativeElement,
      'color',
      status.color
    );
  }

  async takePicture() {
    this.isAnyFileProcessing = true;
    this.cdr.detectChanges();
    const canvas = document.createElement('canvas');
    const video = this.videoElement.nativeElement;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext('2d')
      ?.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL();
    let date = new Date();
    let formattedDate = [
      date.getFullYear(), // Year
      String(date.getMonth() + 1).padStart(2, '0'), // Month
      String(date.getDate()).padStart(2, '0'), // Day
    ].join('-');
    const hysteroscopyObj: IAddHysteroscopyFile = {
      id: 0,
      anatomicalPositionId: this.currentAnatomicalPositionIndex + 1,
      date: formattedDate,
      description: 'Picture captured from hysteroscopy examination',
      file: await this.dataURLToFile(
        dataURL,
        `${this.currentAnatomicalPosition}.png`
      ),
    };

    this.hysteroscopyFileService.addHysteroscopy(hysteroscopyObj).subscribe({
      next: () => {
        this.isAnyFileProcessing = false;
        this.cdr.detectChanges();
        this.speak('Picture saved to database');
      },
      error: (err) => {
        this.isAnyFileProcessing = false;
        this.cdr.detectChanges();
        this.speak('Error saving picture to database');
      },
    });
  }

  async dataURLToFile(dataURL: string, filename: string): Promise<File> {
    const res = await fetch(dataURL);
    const blob = await res.blob();
    return new File([blob], filename, { type: 'image/png' });
  }

  navigatePositions(direction: number) {
    const maxIndex = this.hysteroscopyDropdown?.anatomicalPosition?.length! - 1;

    if (
      (direction === -1 && this.currentAnatomicalPositionIndex === 0) ||
      (direction === 1 && this.currentAnatomicalPositionIndex === maxIndex)
    ) {
      this.speak(
        'You are already at the ' +
          (direction === -1 ? 'first' : 'last') +
          ' anatomical position.'
      );
      return;
    }

    this.hysteroscopyFileService
      .navigatePositions(this.currentAnatomicalPositionIndex + 1, direction)
      .subscribe({
        next: (response: any) => {
          this.currentAnatomicalPositionIndex = response.data.currentIndex - 1;
          this.currentAnatomicalPosition =
            response.data.currentAnatomicalPosition;
          this.speak(`Switched to ${this.currentAnatomicalPosition}`);
        },
        error: (err) => {
          this.speak('Error navigating anatomical position. Please try again.');
        },
      });
  }

  togglePauseResume() {
    if (this.videoElement.nativeElement.paused) {
      this.videoElement.nativeElement.play();
      this.paused = false;
    } else {
      this.videoElement.nativeElement.pause();
      this.paused = true;
    }
    this.speak(this.paused ? 'Video paused' : 'Video resumed');

    this.cdr.detectChanges();
    this.updateStatusTextAndColor();
  }

  async startRecording() {
    const options = { mimeType: 'video/webm;codecs=vp9' };
    this.recordedFrames = [];
    try {
      this.mediaRecorder = new MediaRecorder(this.videoStream, options);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      return;
    }

    this.mediaRecorder.onstop = (event) => {
      this.createMP4();
    };

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.recordedFrames.push(event.data);
      }
    };

    this.mediaRecorder.start(10); // collect 10ms of data
    this.speak('Recording started');
    this.recording = true;
    this.cdr.detectChanges();
    this.updateStatusTextAndColor();
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.isAnyFileProcessing = true;
    this.speak('Recording stopped. Wait for confirmation');
    this.recording = false;
  }

  async createMP4() {
    const videoBlob = new File(
      this.recordedFrames,
      `${this.currentAnatomicalPosition}.mp4`,
      {
        type: 'video/mp4',
      }
    );

    this.sendVideoToBackend(videoBlob);
  }

  sendVideoToBackend(videoBlob: File) {
    const hysteroscopyVideoObj: IAddHysteroscopyVideo = {
      anatomicalPositionId: this.currentAnatomicalPositionIndex + 1,
      date: new Date().toISOString().split('T')[0], // Format the date as yyyy-mm-dd
      description: 'Video captured from hysteroscopy examination',
      videoBlob: videoBlob,
    };

    this.hysteroscopyFileService
      .addHysteroscopyVideo(hysteroscopyVideoObj)
      .subscribe(
        () => {
          this.isAnyFileProcessing = false;
          this.speak('Video saved successfully');
          this.cdr.detectChanges();
        },
        (error) => {
          this.isAnyFileProcessing = false;
          this.speak(
            'Error while saving video. Please view the error logs after the examination'
          );
          this.cdr.detectChanges();
          this.recording = false;
          this.updateStatusTextAndColor();
          console.error('Error while saving video:', error);
        }
      );
  }

  stopExamination() {
    this.stopListening();
    Swal.fire({
      title: `${this.translate.instant('Are you sure?')}`,
      text: 'Do you really want to stop the examination?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes, stop it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.hysteroscopyFileService.setExaminationStatus(false);
      }
    });
  }

  startVoiceCommands() {
    if (this.speechRecognitionSubscription) {
      this.speechRecognitionSubscription.unsubscribe();
    }

    this.speechRecognitionSubscription = this.speechRecognitionService
      .pipe(retry(), continuous(), takeUntil(this.stop$))
      .subscribe((results: SpeechRecognitionResult[]) => {
        if (!this.isListening) {
          return;
        }

        if (!results || results.length === 0 || !results[0][0]) {
          return;
        }

        const command = results[0][0].transcript.trim().toLowerCase();
        this.lastCommand = command;

        // Debounce logic: Ignore subsequent commands within a short period
        const now = new Date().getTime();
        const debounceTime = 1000; // 1 second debounce time
        if (this.lastCommandTime && now - this.lastCommandTime < debounceTime) {
          return;
        }
        this.lastCommandTime = now;

        if (command.includes('pause')) {
          this.togglePauseResume();
        } else if (command.includes('resume')) {
          this.togglePauseResume();
        } else if (command.includes('picture')) {
          this.takePicture();
        } else if (command.includes('start recording')) {
          this.startRecording();
        } else if (command.includes('stop recording')) {
          this.stopRecording();
        } else if (command.includes('stop examination')) {
          this.stopExamination();
        } else if (command.includes('next')) {
          this.navigatePositions(1);
        } else if (command.includes('back')) {
          this.navigatePositions(-1);
        } else {
          // Continue listening even if the command is unrecognized
          this.startVoiceCommands();
        }
      });
  }

  toggleFullScreen() {
    const elem = document.documentElement;

    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        this.isFullscreen = true;
        this.cdr.detectChanges();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.isFullscreen = false;
        this.cdr.detectChanges();
      }
    }
  }
}
