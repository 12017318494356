<form [formGroup]="insuranceForm" [ngClass]="{ submitted: formSubmitted }">

  <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
    <div fxLayout="col" fxFlex="50" class="add-patient-col" fxLayoutAlign="start center">
      {{ "Insurance" | translate }}
    </div>
    <div fxLayout="col" fxFlex="50" class="add-patient-col" fxLayoutAlign="start center">
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
    <div fxLayout="column" fxFlex="50">
      <nz-select nzMode="multiple" nzShowSearch nzAllowClear [nzPlaceHolder]="'Insurance' | translate"
        formControlName="insurance" class="add-patient-multi-inputZorro">
        <ng-container *ngFor="let et of insuranceList">
          <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
        </ng-container>
      </nz-select>
    </div>
    <div fxLayout="column" fxFlex="auto"></div>
  </div>
</form>