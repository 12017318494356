export const paths = {
  // Sections
  alerts: 'alerts',
  dailyMonitoring: 'dailyMonitoring',
  medicalProblems: 'medicalProblems',
  medicalHistorySection: 'medicalHistory',
  pregnancy: 'pregnancy',
  gynecology: 'gynecology',
  hysteroscopy: 'hysteroscopy',
  medicationSummary: 'medicationSummary',
  socialHistory: 'socialHistory',
  laboratory: 'laboratory',
  imaging: 'imaging',
  healthCarePlan: 'carePlan',
  travelHistory: 'travelHistory',
  episodeOfCare: 'episodeOfCare',
  pilotStudy: 'pilotStudy',
  tracheostomy: 'tracheostomy',
  etiology: 'etiology',
  complication: 'complication',
  comorbidity: 'comorbidity',
  tracheostomy2: 'tracheostomy2',
  patientCharacteristics: 'patientCharacteristics',
  initialAssessments: 'initialAssessments',

  // Sub-items
  vitalSigns: 'vitalSigns',
  arterialBloodGas: 'arterialBloodGas',
  glasgow: 'glasgow',
  allergies: 'allergies',
  medicalAlert: 'medicalAlert',
  vaccinations: 'vaccinations',
  resolvedProblems: 'resolvedProblems',
  medicalHistory: 'medical',
  currentProblems: 'currentProblems',
  devicesAndImplants: 'deviceAndImplants',
  procedures: 'procedures',
  currentMedicines: 'medicines',
  pastMedicines: 'pastMedicines',

  // socialHistoryObservations: 'socialHistoryObservations',
  // socialHistoryQuestionnaire: 'socialHistoryQuestionnaire',

  laboratoryFiles: 'laboratoryFiles',
  laboratoryResults: 'laboratoryResults',

  imagingFiles: 'imagingFiles',
  multipleSclerosisSegmentation: 'multipleSclerosisSegmentation',

  ctParameters: 'cTParameters',
  tubeCharacteristics: 'tubeCharacteristics',
  capnography: 'capnography',
  hysteroscopyFiles: 'hysteroscopyFiles',

  socialQuestionnaireAmen: 'socialQuestionnaireAmen',
  supportingDocumentsAmen: 'supportingDocumentsAmen',

  // Workspace
  workspace: {
    myPatients: 'myPatients',
    incomingReferrals: 'incomingReferrals',
    outgoingReferrals: 'outgoingReferrals',
  },
};
