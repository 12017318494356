<nz-modal #modalRef nzSize [(nzVisible)]="isVisible" [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Procedure') | translate"
  (nzOnCancel)="handleCancel()" nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null"
  class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="procedureForm" (ngSubmit)="submitForm()"
      [ngClass]="{'allergies-submitted': procedureSubmitted, 'allergies-not-submitted': !procedureSubmitted}">
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Procedure" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs allergy" nzShowSearch nzAllowClear [nzPlaceHolder]="'Procedure' | translate"
            formControlName="procedureValue" [nzLoading]="isLoading" [nzOptions]="procedures" nzNoAnimation>
            <!-- <ng-container *ngFor="let et of procedureDropdown?.procedureType">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container> -->
          </nz-select>
        </div>
      </div>


      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Body Site" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs allergy" type="text" [placeholder]="'Body Site...' | translate"
            formControlName="bodySiteValue" />
        </div>
      </div>


      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Description" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs allergy" type="text" [placeholder]="'Description ...' | translate"
            formControlName="descriptionValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Date" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs allergy" type="date" [placeholder]="'Date...' | translate" formControlName="dateValue"
            [max]="getCurrentDate()" />
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
          [disabled]="isLoading">{{"Save" | translate}}</button>
        <button nz-button nzDanger #cancelbtn class="cancel-btn" type="button" (click)="handleCancel()"
          [disabled]="isLoading">{{"Cancel" | translate}}</button>
      </div>
    </form>
  </ng-container>
</nz-modal>