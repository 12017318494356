<div class="cropper-container">
  <h2>{{"Upload Profile Picture" | translate}}</h2>
  <p>{{"Please adjust the image dimensions" | translate}}</p>
  <image-cropper style="margin-bottom: 20px" #cropper [imageChangedEvent]="imageChangedEvent"
    (imageCropped)="imageCroppedHandler($event)" [maintainAspectRatio]="true" [aspectRatio]="1" [resizeToWidth]="450"
    [resizeToHeight]="450">
  </image-cropper>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="row-size">
    <button class="cancel-image-btn" (click)="closeDialogBox()">{{"Cancel" | translate}}</button>
    <button class="upload-image-btn" (click)="cropAndUploadImage()">
      {{"Upload Image" | translate}}
    </button>
  </div>
</div>