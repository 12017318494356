<div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" [ngClass]="{ preview: isPreview }">
  <div [fxFlex]="data?.length! > 0 && !isPreview ? '50' : '100'" fxFlex.lt-lg="100">
    <nz-layout class="table-margin">
      <nz-header class="table-header">
        <!--  -->
        <div *ngIf="isEOC() || isInpatientView() || isPreview || data?.length! > 0" class="table-header-row margin-lr">
          <div class="table-header-cell">
            {{ "Hysteroscopy Files" | translate }}
          </div>
          <!--  -->
          <div class="table-header-cell margin-lrr">
            <button nz-button nzShape="round" (click)="openModal()"
              *ngIf="(isEOC() || isInpatientView()) && !isPreview">
              <div fxLayout="row">
                <div fxLayout="col">
                  <mat-icon class="add-icon">add_circle_outline</mat-icon>
                </div>
                <div fxLayout="col" class="add-title">
                  {{ "Add" | translate }} {{ "Hysteroscopy File" | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>

        <div fxLayout="row" class="table-header-row" *ngIf="
            !(isEOC() || isInpatientView()) &&
            !isPreview &&
            !(data?.length! > 0)
          ">
          <div fxLayout="col" class="title" *ngIf="!isLoading">
            {{ "Hysteroscopy Files" | translate }} -
            {{ "No data available" | translate }}
          </div>
        </div>
      </nz-header>
      <nz-content *ngIf="data?.length! > 0 || isLoading">
        <nz-table #nzTable nzTableLayout="fixed" nzShowTitle="false" nzSize="middle"
          nzNoResult="No Hysteroscopy Files found" [nzLoading]="isLoading" [nzData]="data || []"
          [nzShowPagination]="pagination" [nzShowSizeChanger]="pagination" nzPaginationType="default"
          [nzFrontPagination]="pagination" [nzPageIndex]="currentPageIndex"
          (nzPageIndexChange)="handlePageIndexChange($event)" (nzPageSizeChange)="handlePageSizeChange($event)"
          [nzPageSizeOptions]="[10, 15, 20]" [nzPageSize]="pageSize">
          <thead>
            <tr>
              <ng-container *ngFor="
                  let col of ['Anatomical Position', 'Date', 'Description']
                ">
                <th>
                  {{ col | translate }}
                  <app-tooltip [fieldName]="col" area="Hysteroscopy" />
                </th>
              </ng-container>

              <th *ngIf="hasUnsubmitted() && !isPreview" nzWidth="70px">
                {{ "Actions" | translate }}
                <app-tooltip fieldName="Actions" area="Hysteroscopy" />
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of getData()">
              <tr
                [ngClass]="{'draft-entry': !item?.['isSubmitted'] && (isEOC()||isInpatientView()), 'selected-preview': fileItem?.id === item.id}"
                (click)="requestFile(item)">
                <ng-container *ngFor="
                    let field of ['anatomicalPosition', 'date', 'description']
                  ">
                  <td>{{ getValue(field, item[field]?.toString()) }}</td>
                </ng-container>

                <td *ngIf="
                    (isEOC() || isInpatientView()) &&
                    !item['isSubmitted'] &&
                    !isPreview
                  ">
                  <i nz-icon nzType="icons:delete" nzTheme="outline" class="delete-btn"
                    (click)="deleteFun(item?.id!); $event.stopPropagation()"></i>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </nz-content>
    </nz-layout>
  </div>
  <div *ngIf="data?.length! > 0 && !isPreview" [fxFlex]="50" fxFlex.lt-lg="100">
    <div class="document-view">
      <div class="preview-title">
        {{ "Preview" | translate }}
        {{
        fileItem?.anatomicalPosition
        ? " - " + fileItem?.anatomicalPosition
        : ""
        }}
        <span *ngIf="fileObj && !isPreviewLoading" nz-icon nzType="icons:download" nzTheme="outline"
          class="download-btn" (click)="downloadFile(fileItem!)"></span>
      </div>
      <div class="preview-content" fxLayoutAlign="center center">
        <div *ngIf="!fileObj && !isPreviewLoading">
          <i nz-icon nzType="icons:folder-view" nzTheme="outline" class="folder-view-icon"></i>
          <div>{{ "Select a Hysteroscopy File to preview!" | translate }}</div>
        </div>
        <div *ngIf="isPreviewLoading">
          <nz-spin nzSimple></nz-spin>
        </div>
        <img *ngIf="fileType === 'image' && fileObj" [src]="fileObj" alt="Image preview" style="max-width: 100%; height: auto;" />

        <video *ngIf="fileType === 'video' && fileObj" [src]="fileObj" controls class="video-viewer"></video>
      </div>
    </div>
  </div>
</div>

<!-- <nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
  [nzTitle]="('Preview' | translate) + (fileItem?.examTitle ? ' - ' + fileItem?.examTitle : '')"
  (nzOnCancel)="handleCancel()" nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null"
  class="custom-modal-class">

  <ng-container *nzModalContent>
    <video *ngIf="
      fileType === 'video' && fileObj && fileItem && !isPreviewLoading
    " controls class="modal-video">
      <source [src]="fileObj" type="video/mp4" *ngIf="fileObj?.includes('.mp4')" />
      <source [src]="fileObj" type="video/x-msvideo" *ngIf="fileObj?.includes('.avi')" />
      <source [src]="fileObj" type="video/quicktime" *ngIf="fileObj?.includes('.mov')" />
      Your browser does not support the video tag.
    </video>
  </ng-container>
</nz-modal> -->

<div *ngComponentOutlet="addComp"></div>
