<nz-layout>
    <nz-header *ngIf="!addScreen" class="section-header">
        <div fxLayout="row">
            <div fxLayout="col" fxFlex="30">
                {{'Medication Summary'|translate}}</div>
            <div fxLayout="col" fxFlex="70" fxLayoutAlign="end center">
                <button *ngIf="(isEOC()||isInpatientView())" nz-button nzPrimary style="float:right;" class="add-btn"
                    type="button" (click)="handleButton()"><i nz-icon nzType="icons:plus" nzTheme="outline"></i>
                    {{"Add Medication" | translate}}</button>
            </div>
        </div>
    </nz-header>
    <nz-content>
        <div *ngIf="!addScreen">
            <ng-container *ngFor="let cm of submenu">
                <app-current-medication *ngIf="cm.path==componentPaths.currentMedicines" [id]="cm.path" />
                <app-past-medication *ngIf="cm.path==componentPaths.pastMedicines" [id]="cm.path" />
            </ng-container>
        </div>
        <app-medication-add *ngIf="addScreen" />
    </nz-content>
</nz-layout>