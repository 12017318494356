import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EpisodeOfCareVisitStateService {
  private visitDiscardedSubject = new BehaviorSubject<boolean>(false);
  visitDiscarded$ = this.visitDiscardedSubject.asObservable();

  setVisitDiscarded(isDiscarded: boolean) {
    this.visitDiscardedSubject.next(isDiscarded);
  }

  getVisitDiscarded(): boolean {
    return this.visitDiscardedSubject.value;
  }
}
