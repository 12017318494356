import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAdmissionInsuranceInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionInsuranceInfo';

@Component({
  selector: 'app-admission-insurance',
  templateUrl: './admission-insurance.component.html',
  styleUrl: './admission-insurance.component.css',
})
export class AdmissionInsuranceComponent {
  @Input() admissionInsuranceInfoData?: IAdmissionInsuranceInfo;
  @Input() formSubmitted?: boolean = false;

  @Input() insurances: NzSelectOptionInterface[] = [];
  @Input() isEditMode: boolean = false;

  insuranceInfoForm: FormGroup = this.formBuilder.group({});

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.insuranceInfoForm = this.formBuilder.group({
      insurance: [
        {
          value: [],
          disabled: !this.isEditMode,
        },
      ],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['admissionInsuranceInfoData']) {
      this.initializeForm();
    }
    if (changes['isEditMode']) {
      this.setFormControlsState();
    }
  }

  initializeForm() {
    this.insuranceInfoForm.patchValue({
      insurance: this.admissionInsuranceInfoData?.insurance,
    });
  }

  setFormControlsState() {
    if (this.isEditMode) {
      this.insuranceInfoForm.enable();
    } else {
      this.insuranceInfoForm.disable();
    }
  }
}
