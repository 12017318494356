<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
  [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Capnography') | translate" (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="capnographyForm" (ngSubmit)="submitForm()"
      [ngClass]="{'capnography-submitted': capnographySubmitted, 'capnography-not-submitted': !capnographySubmitted}">

      <!-- Date -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Date" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs capnography" type="date" [placeholder]="'Date' | translate"
            formControlName="dateValue" [max]="getCurrentDate()">
        </div>
      </div>


      <!-- Positive End-Expiratory Pressure -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Positive end-expiratory pressure (PEEP)" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs capnography" type="number"
            [placeholder]="'Positive end-expiratory pressure (PEEP) ...' | translate"
            formControlName="positiveEndExpiratoryPressureValue" />
        </div>
      </div>

      <!-- End Tidal CO2 -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"End Tidal CO2" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs capnography" type="number" [placeholder]="'End Tidal CO2 ...' | translate"
            formControlName="endTidalValue" />
        </div>
      </div>

      <!-- Fraction of Inhaled Oxygen (FiO2) -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Fraction of inhaled oxygen (FiO2)" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs capnography" type="number"
            [placeholder]="'Fraction of inhaled oxygen (FiO2) ...' | translate"
            formControlName="fractionInhaledOxygenValue" />
        </div>
      </div>

      <!-- Tidal Volume (Vt) -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Tidal Volume (Vt)" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs capnography" type="number" [placeholder]="'Tidal Volume (Vt) ...' | translate"
            formControlName="tidalVolumeValue" />
        </div>
      </div>

      <!-- Respiratory Rate (RR) -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Respiratory Rate (RR)" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs capnography" type="number"
            [placeholder]="'Respiratory Rate (RR) ...' | translate" formControlName="respiratoryRateValue" />
        </div>
      </div>

      <!-- Pressure of Inhaled Oxygen (Paw) -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Pressure of inhaled oxygen (Paw)" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs capnography" type="number"
            [placeholder]="'Pressure of inhaled oxygen (Paw) ...' | translate"
            formControlName="pressureInhaledOxygenValue" />
        </div>
      </div>



      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
          [disabled]="isLoading">{{"Save" | translate}}</button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
          [disabled]="isLoading">{{"Cancel" | translate}}</button>
      </div>
    </form>
  </ng-container>
</nz-modal>