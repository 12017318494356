import { Component,Input } from '@angular/core';
import { IComorbidityDetailed } from 'src/app/interfaces/Comorbidity/IComorbidityDetailed';
import { ReusableTableExpandComponent } from 'src/app/common/reusable-table-expand/reusable-table-expand.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

@Component({
  standalone: true,
  selector: 'app-comorbidity-expand',
  templateUrl: './comorbidity-expand.component.html',
  styleUrls: ['./comorbidity-expand.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    ReusableTableExpandComponent,
    FlexModule,
  ],
})
export class ComorbidityExpandComponent {
  @Input() item: IComorbidityDetailed = {};

}