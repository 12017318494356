/* src/app/components/ehr/dashboard/dashboard-admissions-and-discharges/dashboard-admissions-and-discharges.component.css */
#divChart {
  display: flex !important;
  width: 100% !important;
  height: 40vh !important;
}
.chart-container {
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px;
  background-color: white;
}
#admissionsAndDischarges {
  width: 100%;
  height: 40vh;
}
/*# sourceMappingURL=dashboard-admissions-and-discharges.component-4ECTNKIQ.css.map */
