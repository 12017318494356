import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IWorkspacePatient } from '../interfaces/Workspace/IWorkspacePatient';
import { IQuickInfo } from '../interfaces/Workspace/IQuickInfo';
import { IWorkspacePatientSearch } from '../interfaces/Workspace/IWorkspacePatientSearch';

@Injectable()
export class WorkspaceService {
  constructor(private _http: HttpClient) {}

  getPatients(
    paramsObj: IWorkspacePatientSearch,
    pageSize: number = 10,
    pageNumber: number = 1
  ): Observable<IGenericServiceResponse<IWorkspacePatient[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IWorkspacePatient[]>>(
      `${environment.BACKEND_URL}Workspace/Search/${pageNumber}/${pageSize}`,
      {
        headers: headers,
        params: new HttpParams({ fromObject: paramsObj as any }),
      }
    );
  }

  getQuickInfo(id: string): Observable<IGenericServiceResponse<IQuickInfo>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IQuickInfo>>(
      `${environment.BACKEND_URL}Workspace/QuickInfo/${id}`,
      {
        headers: headers,
      }
    );
  }
}
