import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  realm: 'SaaSEHR',
  url: 'https://acc-auth.3ahealth.com/',
  clientId: 'ehr-ui',
};

export const environment = {
  production: false,
  keycloak: keycloakConfig,
  APP_URL: 'https://dev-ehr.3ahealth.com/',
  BACKEND_URL: 'https://dev-ehr-api.3ahealth.com/',
};
