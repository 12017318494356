import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { Subject, takeUntil } from 'rxjs';
import { ICoding } from 'src/app/interfaces/ICoding';
import { IAddMedication } from 'src/app/interfaces/Medication/IAddMedication';
import { IMedicationDetailed } from 'src/app/interfaces/Medication/IMedicationDetailed';
import { IMedicationDropdown } from 'src/app/interfaces/Medication/IMedicationDropdown';
import { IPharmacyDetailed } from 'src/app/interfaces/Pharmacy/IPharmacyDetailed';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-medication-add',
  templateUrl: './medication-add.component.html',
  styleUrls: ['./medication-add.component.css'],
})
export class MedicationAddComponent {
  private destroy$ = new Subject<void>();

  radioValue: string = '0';

  isLoading: boolean = false;
  isDropdownLoading: boolean = false;
  isBrandNameLoading: boolean = false;
  isAtcLoading: boolean = false;
  isActiveSubstanceLoading: boolean = false;

  dropdownData?: IMedicationDropdown;

  brandNameDropdown: NzSelectOptionInterface[] = [];
  atcDropdown: NzSelectOptionInterface[] = [];
  activeSubstanceDropdown: NzSelectOptionInterface[] = [];

  doctors: NzSelectOptionInterface[] = [];

  medicationForm: FormGroup = new FormGroup({});

  pharmacyObj?: IPharmacyDetailed;

  medicationSubmitted: boolean = false;

  isEdit: boolean = false;
  editId?: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pharmacyService: PharmacyService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isDropdownLoading = true;
    this.isBrandNameLoading = true;
    this.isAtcLoading = true;

    this.medicationForm = this.formBuilder.group({
      radioValue: ['0', Validators.required],
      searchValue: [null, Validators.required],
      quantintyValue: [null, Validators.required],
      unitsPerIntakeValue: [null, Validators.required],
      freqOfIntakeValue: [null, Validators.required],
      freqOfIntakeUnitValue: [null, Validators.required],
      routeOfAdminValue: [null],
      durationOfTreatmentValue: [null, Validators.required],
      durationUnitsValue: [null, Validators.required],
      dateValue: [null, Validators.required],
      // assignedDoctorValue: [null, Validators.required],
      // professionValue: [null, Validators.required],
      instructionsToPatientValue: [null],
      advicesToDispencerValue: [null],
    });

    this.currentMedicationService.getMedicationDropdown().subscribe({
      next: (data) => {
        this.dropdownData = data.data;

        this.doctors = data.data.doctors.map((d) => {
          return {
            label: d.fullName,
            value: d.id,
          };
        });

        this.isDropdownLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to get Medication Dropdown data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });

    this.currentMedicationService.getBrandNameDropdown().subscribe({
      next: (data) => {
        this.brandNameDropdown = data.data.brandNames.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });

        this.isBrandNameLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to get Brand Names!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });

    this.currentMedicationService.getAtcDropdown().subscribe({
      next: (data) => {
        this.atcDropdown = data.data.atcCode.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });

        this.isAtcLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to get ATCs!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });

    this.currentMedicationService.getActiveSubstanceDropdown().subscribe({
      next: (data) => {
        this.activeSubstanceDropdown = data.data.activeSubstance.map((d) => {
          return {
            label: d.name,
            value: d.id,
          };
        });

        this.isActiveSubstanceLoading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to get Active Substances!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });

    this.medicationForm
      .get('radioValue')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.pharmacyObj = undefined;

        this.medicationForm.get('searchValue')?.reset();
        this.medicationForm.get('quantintyValue')?.reset();
        this.medicationForm.get('unitsPerIntakeValue')?.reset();
        this.medicationForm.get('freqOfIntakeValue')?.reset();
        this.medicationForm.get('freqOfIntakeUnitValue')?.reset();
        this.medicationForm.get('routeOfAdminValue')?.reset();
        this.medicationForm.get('durationOfTreatmentValue')?.reset();
        this.medicationForm.get('durationUnitsValue')?.reset();
        this.medicationForm.get('dateValue')?.reset();
        // this.medicationForm.get('assignedDoctorValue')?.reset();
        // this.medicationForm.get('professionValue')?.reset();
        this.medicationForm.get('instructionsToPatientValue')?.reset();
        this.medicationForm.get('advicesToDispencerValue')?.reset();
      });

    this.medicationForm
      .get('searchValue')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.pharmacyObj = undefined;
        this.medicationForm.get('quantintyValue')?.reset();
        this.medicationForm.get('unitsPerIntakeValue')?.reset();
        this.medicationForm.get('freqOfIntakeValue')?.reset();
        this.medicationForm.get('freqOfIntakeUnitValue')?.reset();
        this.medicationForm.get('routeOfAdminValue')?.reset();
        this.medicationForm.get('durationOfTreatmentValue')?.reset();
        this.medicationForm.get('durationUnitsValue')?.reset();
        // this.medicationForm.get('assignedDoctorValue')?.reset();
        // this.medicationForm.get('professionValue')?.reset();
        this.medicationForm.get('instructionsToPatientValue')?.reset();
        this.medicationForm.get('advicesToDispencerValue')?.reset();

        let radio = this.medicationForm.get('radioValue')?.value;

        if (newValue && newValue != undefined && newValue != '') {
          if (radio == 0) {
            this.pharmacyService.getByBrandName(newValue).subscribe({
              next: (data) => {
                this.pharmacyObj = data.data;

                this.medicationForm
                  .get('routeOfAdminValue')
                  ?.setValue(data.data.routeOfAdministratorId?.toString());

                this.medicationForm
                  .get('unitsPerIntakeValue')
                  ?.setValue(data.data.quantityUnitId?.toString());
              },
              error: (err) => {},
            });
          } else if (radio == 1) {
            this.pharmacyService.getByActiveSubstance(newValue).subscribe({
              next: (data) => {
                this.pharmacyObj = data.data;
                this.medicationForm
                  .get('routeOfAdminValue')
                  ?.setValue(data.data.routeOfAdministratorId?.toString());

                this.medicationForm
                  .get('unitsPerIntakeValue')
                  ?.setValue(data.data.quantityUnitId?.toString());
              },
              error: (err) => {},
            });
          } else if (radio == 2) {
            this.pharmacyService.getByAtc(newValue).subscribe({
              next: (data) => {
                this.pharmacyObj = data.data;
                this.medicationForm
                  .get('routeOfAdminValue')
                  ?.setValue(data.data.routeOfAdministratorId?.toString());

                this.medicationForm
                  .get('unitsPerIntakeValue')
                  ?.setValue(data.data.quantityUnitId?.toString());
              },
              error: (err) => {},
            });
          }
        }
      });

    this.currentMedicationService.medicationEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editId = data?.id?.toString();

          this.medicationForm.get('radioValue')?.setValue('0');
          this.medicationForm
            .get('searchValue')
            ?.setValue(data?.productId?.toString());
          this.medicationForm
            .get('quantintyValue')
            ?.setValue(data?.quantity?.toString());
          this.medicationForm
            .get('unitsPerIntakeValue')
            ?.setValue(data?.quantityUnit?.toString());
          this.medicationForm
            .get('freqOfIntakeValue')
            ?.setValue(data?.frequencyOfIntakeAmount?.toString());
          this.medicationForm
            .get('freqOfIntakeUnitValue')
            ?.setValue(data?.frequencyOfIntakeUnitId?.toString());
          this.medicationForm
            .get('routeOfAdminValue')
            ?.setValue(data?.routeOfAdministrationId?.toString());
          this.medicationForm
            .get('durationOfTreatmentValue')
            ?.setValue(data?.durationOfTreatmentAmount?.toString());
          this.medicationForm
            .get('durationUnitsValue')
            ?.setValue(data?.durationOfTreatmentUnitId?.toString());

          // this.medicationForm
          //   .get('assignedDoctorValue')
          //   ?.setValue(data?.assignedDoctorId?.toString());
          // this.medicationForm
          //   .get('professionValue')
          //   ?.setValue(data?.professionId?.toString());

          this.medicationForm
            .get('instructionsToPatientValue')
            ?.setValue(data?.instructionPatient);

          this.medicationForm
            .get('advicesToDispencerValue')
            ?.setValue(data?.adviceToDispenser);

          data?.onSetDateTime &&
            this.medicationForm
              .get('dateValue')
              ?.setValue(
                data?.onSetDateTime && this.convertToLocale(data?.onSetDateTime)
              );
        } else {
          this.medicationForm.patchValue({
            dateValue: this.convertToLocale(),
          });
        }
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCurrentDateTime() {
    return new Date().toISOString().slice(0, 16);
  }

  // CONVERT UTC TO LOCAL
  convertToLocale(date?: string) {
    const currentDateTime = date ? moment(date).format() : moment().format();

    const isoDate = currentDateTime.slice(0, 16);

    return isoDate;
  }

  handleCancel() {
    this.currentMedicationService.medicationOpenSub.next(false);
  }

  submitForm() {
    this.medicationSubmitted = true;
    this.isLoading = true;

    if (this.medicationForm.valid) {
      let medicationObj: IAddMedication = {};

      medicationObj.onSetDateTime = moment(
        this.medicationForm.value?.dateValue
      ).format();

      medicationObj.productId = this.pharmacyObj?.productPk;
      medicationObj.atcId = this.pharmacyObj?.atcId?.toString();
      medicationObj.activeIngredientId =
        this.pharmacyObj?.activeSubstanceId?.toString();
      medicationObj.quantity = this.medicationForm
        .get('quantintyValue')
        ?.value?.toString();
      medicationObj.packageId = this.pharmacyObj?.packageId?.toString();
      medicationObj.quantityUnitId = this.medicationForm.get(
        'unitsPerIntakeValue'
      )?.value;
      medicationObj.frequencyOfIntakeAmount = this.medicationForm
        .get('freqOfIntakeValue')
        ?.value?.toString();
      medicationObj.frequencyOfIntakeUnitId = this.medicationForm.get(
        'freqOfIntakeUnitValue'
      )?.value;
      medicationObj.durationOfTreatmentAmount = this.medicationForm
        .get('durationOfTreatmentValue')
        ?.value?.toString();
      medicationObj.durationOfTreatmentUnitId =
        this.medicationForm.get('durationUnitsValue')?.value;
      medicationObj.routeOfAdministrationId =
        this.medicationForm.get('routeOfAdminValue')?.value;

      // medicationObj.assignedDoctorId = this.medicationForm.get(
      //   'assignedDoctorValue'
      // )?.value;
      // medicationObj.professionId =
      //   this.medicationForm.get('professionValue')?.value;

      medicationObj.instructionPatient = this.medicationForm.get(
        'instructionsToPatientValue'
      )?.value;
      medicationObj.adviceToDispenser = this.medicationForm.get(
        'advicesToDispencerValue'
      )?.value;

      if (this.isEdit) {
        medicationObj.id = this.editId;
        this.currentMedicationService.editMedication(medicationObj).subscribe({
          next: (data) => {
            this.isLoading = false;
            this.currentMedicationService.medicationOpenSub.next(false);

            Swal.fire({
              text: 'Medication edited successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            this.isLoading = false;
            Swal.fire({
              text: 'Unable to edit Medication!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else {
        this.currentMedicationService.addMedication(medicationObj).subscribe({
          next: (data) => {
            this.isLoading = false;
            this.currentMedicationService.medicationOpenSub.next(false);

            Swal.fire({
              text: 'Medication added successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            this.isLoading = false;
            Swal.fire({
              text: 'Unable to add Medication!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
