import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Chart } from 'chart.js/auto';
import { HttpClient } from '@angular/common/http';
import { IGenericServiceResponse } from '../../../../interfaces/IServiceResponse';
import { IDashboardChart } from '../../../../interfaces/Dashboard/IDashboardChart';
import { environment } from '../../../../../environments/environment';
import { IDateTimeRange } from '../../../../interfaces/Dashboard/IDateTimeRange';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard-registration-history-date',
  templateUrl: './dashboard-registration-history-date.component.html',
  styleUrls: ['./dashboard-registration-history-date.component.css'],
})
export class DashboardRegistrationHistoryDateComponent {
  @Input() dateTimeRange: IDateTimeRange | null = null; // Input to receive the selected date range

  public registrationHistoryDate: IDashboardChart | null = null;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private readonly dashboardService: DashboardService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateTimeRange'] && !changes['dateTimeRange'].firstChange) {
      this.fetchData(); // Fetch data whenever dateTimeRange changes
    }
  }

  BarChart(): void {
    // Initialize an empty array for allDataSets
    let allDataSets: number[][] = [];

    // Check if dataSets is defined and is an array
    if (Array.isArray(this.registrationHistoryDate?.dataSet)) {
      // Iterate over each dataset and process its data
      this.registrationHistoryDate?.dataSet.forEach((dataset) => {
        // Check if dataset and dataset.data are defined
        if (dataset.data) {
          // Map the data to numbers and concatenate to allDataSets
          const numericData = dataset.data.map(Number); // Convert each item to a number
          allDataSets.push(numericData);
        }
      });
    }

    const maxYAxisValue = Math.max(...allDataSets.flat()) + 2; // Adjust buffer as needed

    const data = {
      labels: this.registrationHistoryDate!.labels,
      datasets:
        this.registrationHistoryDate?.dataSet?.map((dataset, index) => ({
          label: this.translate.instant(dataset?.label || ''),
          data: allDataSets?.at(index) || [],
          backgroundColor: dataset.backgroundColor || 'rgba(0, 0, 0, 0.2)',
          borderColor: dataset.backgroundColor || 'rgba(0, 0, 0, 0.2)',
          borderWidth: 1,
        })) || [],
    };

    new Chart('registrationHistoryDate', {
      type: 'line',
      data: data,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            max: maxYAxisValue,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.translate.instant('Admissions Status by Date'),
          },
        },
      },
    });
  }

  // BarChart(): void {
  //   const rejectedDataSet = this.registrationHistoryDate?.dataSet?.at(0)?.data;
  //   const pendingDataSet = this.registrationHistoryDate?.dataSet?.at(1)?.data;
  //   const preapprovedDataSet = this.registrationHistoryDate?.dataSet?.at(2)?.data;
  //   const approvedDataSet = this.registrationHistoryDate?.dataSet?.at(3)?.data;

  //   const rejectedNum = rejectedDataSet?.map(Number);
  //   const pendingNum = pendingDataSet?.map(Number);
  //   const preapprovedNum = preapprovedDataSet?.map(Number);
  //   const approvedNum = approvedDataSet?.map(Number);
  //   const allDataSets = [
  //     ...rejectedNum!,
  //     ...pendingNum!,
  //     ...preapprovedNum!,
  //     ...approvedNum!,
  //   ];

  //   const maxYAxisValue = Math.max(...allDataSets) + 2; // Adjust buffer as needed

  //   const data = {
  //     labels: this.registrationHistoryDate!.labels,
  //     datasets: [
  //       {
  //         label: this.translate.instant('Rejected'),
  //         data: rejectedNum,
  //         backgroundColor: 'rgba(239, 29, 29, 0.8)',
  //         borderColor: 'rgba(239, 29, 29, 0.8)',
  //         borderWidth: 1,
  //       },
  //       {
  //         label: this.translate.instant('Pending'),
  //         data: pendingNum,
  //         backgroundColor: 'rgba(239, 146, 29, 0.8)',
  //         borderColor: 'rgba(239, 146, 29, 0.8)',
  //         borderWidth: 1,
  //       },
  //       {
  //         label: this.translate.instant('Waiting List'),
  //         data: preapprovedNum,
  //         backgroundColor: 'rgba(198, 239, 29, 0.8)',
  //         borderColor: 'rgba(198, 239, 29, 0.8)',
  //         borderWidth: 1,
  //       },
  //       {
  //         label: this.translate.instant('Approved'),
  //         data: approvedNum,
  //         backgroundColor: 'rgba(40, 239, 29, 0.8)',
  //         borderColor: 'rgba(40, 239, 29, 0.8)',
  //         borderWidth: 1,
  //       },
  //     ],
  //   };

  //   new Chart('registrationHistoryDate', {
  //     type: 'line',
  //     data: data,
  //     options: {
  //       maintainAspectRatio: false,
  //       scales: {
  //         y: {
  //           beginAtZero: true,
  //           max: maxYAxisValue,
  //         },
  //       },
  //       responsive: true,
  //       plugins: {
  //         legend: {
  //           position: 'top',
  //         },
  //         title: {
  //           display: true,
  //           text: this.translate.instant('Registration History by Date'),
  //         },
  //       },
  //     },
  //   });
  // }

  fetchData(): void {
    let chartStatus = Chart.getChart('registrationHistoryDate');
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.dashboardService
      .getRegistrationHistoryDate(this.dateTimeRange)
      .subscribe((data) => {
        this.registrationHistoryDate = data.data;
        this.cdr.detectChanges();
        if (this.registrationHistoryDate) {
          this.BarChart();
        }
      });
  }
}
