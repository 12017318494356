<nz-layout class="parent">
    <nz-content class="parent">
        <app-arterial-blood-gas [removeMargin]="true" />
        <app-vital-signs />
        <app-glasgow />

        <app-capnography />

        <nz-layout class="child">
            <nz-header class="table-header">
                <div fxLayout="row" class="table-header-row">
                    <div fxLayout="col" fxLayoutAlign="start center" fxFlex.gt-xl="15" fxFlex.xl="15" fxFlex.lt-xl="35"
                        class="title">
                        {{"Complications" | translate}}
                    </div>
                </div>
            </nz-header>
            <nz-content class="child"><app-complication /></nz-content>
        </nz-layout>

    </nz-content>
</nz-layout>