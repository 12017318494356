<nz-layout>
    <nz-header class="section-header">
        {{'Alerts'|translate}}
    </nz-header>
    <nz-content>
        <ng-container *ngFor="let cm of submenu">
            <app-allergies *ngIf="cm.path==componentPaths.allergies" [id]="cm.path" />
            <app-medical-alert *ngIf="cm.path==componentPaths.medicalAlert" [id]="cm.path" />
        </ng-container>
    </nz-content>
</nz-layout>