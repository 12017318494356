import {
  Component,
  Input,
  SimpleChanges,
  OnInit,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAdmissionMedicalInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionMedicalInfo';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-admission-medical-info',
  templateUrl: './admission-medical-info.component.html',
  styleUrls: ['./admission-medical-info.component.css'],
})
export class AdmissionMedicalInfoComponent implements OnInit, OnChanges {
  @Input() admissionMedicalInfoData?: IAdmissionMedicalInfo;
  @Input() formSubmitted?: boolean = false;
  @Input() bloodTypesList: ICoding[] = [];
  @Input() immobilityList: NzSelectOptionInterface[] = [];
  @Input() doctors: NzSelectOptionInterface[] = [];
  @Input() placeOfBirthList: ICoding[] = [];
  @Input() closestRelativesList: ICoding[] = [];
  @Input() educationLevelList: ICoding[] = [];
  @Input() familyStatusList: ICoding[] = [];
  @Input() sourceOfIncomeList: ICoding[] = [];
  @Input() religionList: ICoding[] = [];
  @Input() isEditMode: boolean = false;

  selectedDoctors: any[] = [];
  medicalInfoForm: FormGroup = this.formBuilder.group({});

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.medicalInfoForm = this.formBuilder.group({
      assignedDoctors: [
        {
          value: [],
          disabled: !this.isEditMode,
        },
        Validators.required,
      ],
      immobilityValue: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
        Validators.required,
      ],
      bloodTypeId: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      height: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      weight: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      primaryDoctorId: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      moh: [{ value: undefined, disabled: !this.isEditMode }],
    });

    this.populateSelectedDoctors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['admissionMedicalInfoData']) {
      this.initializeForm();
      this.populateSelectedDoctors();
    }
    if (changes['isEditMode']) {
      this.setFormControlsState();
    }
  }

  initializeForm() {
    this.medicalInfoForm.patchValue({
      assignedDoctors:
        this.admissionMedicalInfoData?.assignedDoctors?.map(
          (doc) => doc.doctorId
        ) || [],
      bloodTypeId: this.admissionMedicalInfoData?.bloodTypeId,
      height: this.admissionMedicalInfoData?.height,
      weight: this.admissionMedicalInfoData?.weight,
      primaryDoctorId: this.admissionMedicalInfoData?.assignedDoctors?.find(
        (d) => d.primaryDoctor
      )?.doctorId,
      immobilityValue: this.admissionMedicalInfoData?.immobilityStatusId,
      moh: this.admissionMedicalInfoData?.moh,
    });
  }

  setFormControlsState() {
    if (this.isEditMode) {
      this.medicalInfoForm.enable();
    } else {
      this.medicalInfoForm.disable();
    }
  }

  onDoctorSelectionChange(selectedDoctorIds: number[]) {
    this.selectedDoctors = selectedDoctorIds.map((id) => {
      const existingDoctor =
        this.admissionMedicalInfoData?.assignedDoctors?.find(
          (doc) => doc.doctorId === id
        );
      return {
        doctorId: id,
        doctorFullName:
          this.doctors.find((doc) => doc.value === id)?.label || '',
        primaryDoctor: existingDoctor ? existingDoctor.primaryDoctor : false,
      };
    });

    this.updatePrimaryDoctor();
  }

  setPrimaryDoctor(doctorId: number) {
    this.selectedDoctors.forEach((doc) => {
      doc.primaryDoctor = doc.doctorId === doctorId;
    });
    this.medicalInfoForm.patchValue({
      primaryDoctorId: doctorId,
    });

    if (this.formSubmitted) {
      this.checkPrimaryDoctorSelection();
    }
  }

  checkPrimaryDoctorSelection() {
    const hasPrimaryDoctor = this.selectedDoctors.some(
      (doc) => doc.primaryDoctor
    );
    if (!hasPrimaryDoctor) {
      alert('Please select at least one primary doctor.');
    }
  }

  populateSelectedDoctors() {
    if (this.admissionMedicalInfoData?.assignedDoctors) {
      this.selectedDoctors = this.admissionMedicalInfoData.assignedDoctors.map(
        (doc) => ({
          doctorId: doc.doctorId,
          doctorFullName: doc.doctorFullName,
          primaryDoctor: doc.primaryDoctor,
        })
      );
    }
    this.updatePrimaryDoctor();
  }

  updatePrimaryDoctor() {
    if (this.selectedDoctors.length > 0) {
      const primaryDoctorExists = this.selectedDoctors.some(
        (doc) => doc.primaryDoctor
      );
      if (!primaryDoctorExists) {
        this.selectedDoctors[0].primaryDoctor = true;
      }
      this.medicalInfoForm.patchValue({
        primaryDoctorId: this.selectedDoctors.find((doc) => doc.primaryDoctor)
          ?.doctorId,
      });
    } else {
      this.medicalInfoForm.patchValue({
        primaryDoctorId: '',
      });
    }
  }
}
