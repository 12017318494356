import { Component } from '@angular/core';
import { IDateTimeRange } from '../../../interfaces/Dashboard/IDateTimeRange';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';

import moment from 'moment';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  getDate6Months(): Date {
    const offset = -new Date().getTimezoneOffset() / 60;

    const currentDate = new Date(); // Get the current date
    const sixMonthsAgo = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 6,
      currentDate.getDate(),
      offset
    );

    return sixMonthsAgo;
  }
  dateRange?: Date[] = [this.getDate6Months(), new Date()];

  selectedDates: IDateTimeRange = {};

  constructor(
    private readonly nz: NzI18nService,
    private readonly dashboardService: DashboardService
  ) {
    // TODO - Temporary fix
    // NG Zorro Datepicker doesnt have a greek locale
    // dashboard.component.html:29 [NG-ZORRO]: Missing translations for "DatePicker.lang.rangeWeekPlaceholder" in language "el".
    // You can use "NzI18nService.setLocale" as a temporary fix.
    // Welcome to submit a pull request to help us optimize the translations!
    //
    // If you change locale to greek manually, it will break
    this.nz.setLocale(en_US);
  }

  ngOnInit() {
    const offset = -new Date().getTimezoneOffset() / 60;

    const start = this?.dateRange?.at(0);
    start?.setHours(offset);

    const end = this?.dateRange?.at(1);
    end?.setHours(offset);

    this.selectedDates = {
      startDate: start?.toISOString().split('T').at(0) + 'T00:00:01.0Z',
      endDate: end?.toISOString().split('T').at(0) + 'T23:59:59.0Z',
    };
  }

  onDateRangeSelected(dateRange: Date[]): void {
    const offset = -new Date().getTimezoneOffset() / 60;

    const start = this?.dateRange?.at(0);
    start?.setHours(offset);

    const end = this?.dateRange?.at(1);
    end?.setHours(offset);

    this.selectedDates = {
      startDate: start
        ? moment(start).format().split('T').at(0) + 'T00:00:01.0Z'
        : undefined,
      endDate: end
        ? moment(end).format().split('T').at(0) + 'T23:59:59.0Z'
        : undefined,
    };
  }

  disabledDate = (current: Date): boolean => {
    // You can implement logic to disable specific dates here
    return current && current.getTime() > Date.now();
  };

  exportData() {
    this.dashboardService.exportDashboardData(this.selectedDates);
  }
}
