<nz-header class="table-header">
  <div class="table-header-row margin-lr">
    <div class="table-header-cell">
      {{ "Social History Questionnaire" | translate }}
    </div>
  </div>
</nz-header>

<form
  style="margin-bottom: 10em"
  *ngIf="!isLoading"
  [formGroup]="formGroup"
  (ngSubmit)="onSubmit()"
>
  <nz-steps
    [nzCurrent]="current"
    [nzProgressDot]="progressTemplate"
    (nzIndexChange)="onIndexChange($event)"
    [nzDirection]="isLargeScreen ? 'horizontal' : 'vertical'"
  >
    <ng-container *ngFor="let page of pages; let i = index">
      <nz-step
        [nzTitle]="page.title"
        [nzDisabled]="disable && i > current"
        [nzDescription]="
          !isLargeScreen && i === current && page.elements
            ? dynamicQuestionsTemplate
            : ''
        "
      ></nz-step>
    </ng-container>
  </nz-steps>

  <!-- Template for dynamic questions in vertical mode -->
  <ng-template #dynamicQuestionsTemplate>
    <app-dynamic-questions
      [elements]="pages[current].elements"
      [formGroup]="formGroup"
    ></app-dynamic-questions>
  </ng-template>

  <ng-template #progressTemplate let-dot let-status="status" let-index="index">
    <span nz-popover>
      <ng-template [ngTemplateOutlet]="dot"></ng-template>
    </span>
  </ng-template>

  <!-- Render questions below steps in horizontal mode -->
  <app-dynamic-questions
    *ngIf="isLargeScreen && pages[current]?.elements"
    [elements]="pages[current].elements"
    [formGroup]="formGroup"
  ></app-dynamic-questions>

  <div class="steps-action">
    <div class="left-actions">
      <button
        nz-button
        nzType="default"
        (click)="pre($event)"
        [disabled]="!(current > 0)"
      >
        <span>{{ "Previous" | translate }}</span>
      </button>
      <button
        nz-button
        nzType="default"
        (click)="next($event)"
        [disabled]="!(current < pages.length - 1)"
      >
        <span>{{ "Next" | translate }}</span>
      </button>
    </div>
    <div class="right-actions">
      <button
        nz-button
        nzType="primary"
        type="submit"
        *ngIf="isInpatientView() || isEOC()"
      >
        <span>{{ "Submit" | translate }}</span>
      </button>
    </div>
  </div>
</form>

<nz-spin *ngIf="isLoading" [nzSpinning]="true" nzSize="large"></nz-spin>
