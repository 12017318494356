import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IAddEtiology } from 'src/app/interfaces/Etiology/IAddEtiology';
import { IEtiology } from 'src/app/interfaces/Etiology/IEtiology';
import { GetEtiology } from 'src/app/interfaces/Etiology/IGetEtiology';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { EtiologyService } from 'src/app/services/etiology.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-etiology',
  templateUrl: './etiology.component.html',
  styleUrls: ['./etiology.component.css'],
})
export class EtiologyComponent implements OnInit {
  initialEtiologyData: any[] = [];
  loading: boolean = false;
  addEtiologyObj: IAddEtiology = { etiologyId: 0 };
  getEtiologyData: GetEtiology[] = [];

  constructor(private etiologyService: EtiologyService) {}

  ngOnInit(): void {
    this.fetchAllEtiologyData();
    this.fetchVisitEtiologyData();
    this.synchronizeCheckedStates();
  }

  // get all etiology data from database
  fetchAllEtiologyData(): void {
    this.loading = true;
    this.etiologyService.getEtiologyDataDropDown().subscribe({
      next: (response) => {
        this.initialEtiologyData = response.data.etiology.map((item) => ({
          ...item,
          checked: false,
        }));
        this.loading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve etiology data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });
  }
  // get the visit's only etiology data
  fetchVisitEtiologyData(): void {
    this.loading = true;
    this.etiologyService.getEtiology().subscribe({
      next: (response) => {
        if (response.succeed && Array.isArray(response.data)) {
          this.getEtiologyData = response.data;
          this.synchronizeCheckedStates();
        }
        this.loading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve etiology data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        this.loading = false;
      },
    });
  }
  // update the initial estiology data checked values based on the visit's only etiology data
  synchronizeCheckedStates(): void {
    // Map through initialEtiologyData to update their 'checked' property
    this.initialEtiologyData = this.initialEtiologyData.map((initialItem) => {
      const isEtiologyChecked = this.getEtiologyData.some(
        (getEtiologyItem) =>
          getEtiologyItem.etiologyId === initialItem.id &&
          getEtiologyItem.isChecked
      );
      const isEtiologySubmitted = this.getEtiologyData.some(
        (getEtiologyItem) =>
          getEtiologyItem.etiologyId === initialItem.id &&
          getEtiologyItem.isSubmitted
      );
      return {
        ...initialItem,
        checked: isEtiologyChecked,
        isSubmitted: isEtiologySubmitted,
      };
    });
  }
  // checked etiologies
  get checkedItems(): any[] {
    return this.initialEtiologyData.filter((item) => item.checked);
  }
  // uncecked etiologies
  get uncheckedItems(): any[] {
    return this.initialEtiologyData.filter((item) => !item.checked);
  }

  //add etiology
  onCheckChange(item: any): void {
    this.loading = true;
    this.addEtiologyObj.etiologyId = item.id;
    this.etiologyService.addEtiology(this.addEtiologyObj).subscribe({
      next: () => {
        this.loading = false;
        Swal.fire({
          text: 'Etiology added successfully!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
        this.fetchVisitEtiologyData();
      },
      error: (err) => {
        this.loading = false;

        let errorMessage = 'Unable to add etiology!';

        //is an HttpErrorResponse and contains the error message
        if (err instanceof HttpErrorResponse && err.error?.message) {
          errorMessage += ` Error: ${err.error.message}`;
        }

        this.loading = false;
        Swal.fire({
          text: errorMessage,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });
  }

  //delete etiology
  onUncheckChange(item: any): void {
    this.loading = true;

    const matchingEtiology = this.getEtiologyData.find(
      (etiology) => etiology.etiologyId === item.id
    );
    if (matchingEtiology) {
      this.getEtiologyData.push(matchingEtiology);
      this.etiologyService.deleteEtiology(matchingEtiology.id).subscribe({
        next: () => {
          this.loading = false;
          Swal.fire({
            text: 'Etiology deleted successfully!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });
          this.fetchVisitEtiologyData();
        },
        error: (err) => {
          this.loading = false;

          let errorMessage = 'Unable to add etiology!';

          //is an HttpErrorResponse and contains the error message
          if (err instanceof HttpErrorResponse && err.error?.message) {
            errorMessage += ` Error: ${err.error.message}`;
          }

          this.loading = false;
          Swal.fire({
            text: errorMessage,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });
    }
  }
}
