/* src/app/components/ehr/patients/components/medical-problems-section/medical-problems-section.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-layout {
  background: #f4f4f6;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
.section-header {
  margin-bottom: 1em;
}
/*# sourceMappingURL=medical-problems-section.component-JW3S72O4.css.map */
