<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
  [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Health Care Plans') | translate" (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="carePlanForm" (ngSubmit)="submitForm()"
      [ngClass]="{'plan-submitted': carePlanSubmitted, 'plan-not-submitted': !carePlanSubmitted}">


      <div class="form-row" fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Description" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70" fxLayoutAlign="start start">
          <!-- <input nz-input class="inputs plan" type="text" placeholder="{{'Description ...' | translate}}"
            formControlName="descriptionValue" /> -->

          <textarea rows="4" nz-input [placeholder]="'Description ...' | translate" formControlName="descriptionValue"
            class="inputs plan"></textarea>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
          [disabled]="isLoading">{{"Save" | translate}}</button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
          [disabled]="isLoading">{{"Cancel" | translate}}</button>
      </div>
    </form>
  </ng-container>
  <ng-template>
    <button type="submit">{{"Save" | translate}}</button>
  </ng-template>
</nz-modal>