<div class="container">
    <div class="table-container">
        <h3>{{"Patient's Etiologies" | translate}}</h3>
        <nz-table [nzLoading]="loading" #checkedTable [nzData]="checkedItems" nzShowPagination="false">
            <thead>
                <tr>
                    <th>{{"Condition" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of checkedItems" [ngClass]="{'submitted-item': item.isSubmitted === false}">
                    <td>
                        <label nz-checkbox [ngModel]="item.checked" (ngModelChange)="onUncheckChange(item)">{{ item.name
                            }}</label>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>


    <div class="table-container">
        <h3>{{"All Etiologies" | translate}}</h3>
        <nz-table [nzLoading]="loading" #uncheckedTable [nzData]="uncheckedItems" nzShowPagination="false">
            <thead>
                <tr>
                    <th>{{"Condition" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of uncheckedItems">
                    <td>
                        <label nz-checkbox [ngModel]="item.checked" (ngModelChange)="onCheckChange(item)">{{ item.name
                            }}</label>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </div>
</div>