<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Resolved Problem') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="problemsForm" (ngSubmit)="submitForm()"
            [ngClass]="{'resolved-submitted': problemsSubmitted, 'resolved-not-submitted': !problemsSubmitted}">
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Problem Diagnosis" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs resolved" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Problem Diagnosis' | translate" formControlName="problemValue"
                        [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of problemsDropdown?.problemDiagnosis">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Type" | translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs resolved" nzShowSearch nzAllowClear [nzPlaceHolder]="'Type' | translate"
                        formControlName="typeValue" [nzLoading]="isLoading">
                        <nz-option nzLabel="Illness / Disorder" [nzValue]="0"></nz-option>
                        <nz-option nzLabel="Rare Disease" [nzValue]="1"></nz-option>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center"
                *ngIf="this.problemsForm.value.typeValue == 0">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Illness / Disorder" | translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs resolved" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Illness / Disorder' | translate" formControlName="illnessDisorderValue"
                        [nzLoading]="isIdLoading" [nzOptions]="idDropdown" nzNoAnimation>
                        <!-- <ng-container *ngFor="let et of idDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container> -->
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center"
                *ngIf="this.problemsForm.value.typeValue == 1">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Rare Disease" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs resolved" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Rare Disease' | translate" formControlName="rareDiseaseValue"
                        [nzLoading]="isRdLoading" [nzOptions]="rdDropdown" nzNoAnimation>
                        <!-- <ng-container *ngFor="let et of rdDropdown">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container> -->
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Status Code" | translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs resolved" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Status Code' | translate" formControlName="statusCodeValue"
                        [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of problemsDropdown?.statusCode">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Diagnosis Assertion" | translate}}</label>

                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs resolved" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Diagnosis Assertion' | translate" formControlName="diagnosisAssertionValue"
                        [nzLoading]="isLoading">
                        <ng-container *ngFor="let et of problemsDropdown?.diagnosisAssertion">
                            <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Resolution Circumstances" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs resolved" type="text"
                        [placeholder]="'Resolution Circumstances Free Text...' | translate"
                        formControlName="resolutionCircumstancesTextValue" />
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Onset Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs resolved" type="date" [placeholder]="'Onset Date...' | translate"
                        formControlName="onSetDateValue"
                        [max]="this?.problemsForm?.value?.endDateValue|| getCurrentDate()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"End Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs resolved" type="date" [placeholder]="'End Date...' | translate"
                        formControlName="endDateValue" [min]="this?.problemsForm?.value?.onSetDateValue"
                        [max]="getCurrentDate()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Description" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs resolved" type="text" [placeholder]="'Description ...' | translate"
                        formControlName="descriptionValue" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>