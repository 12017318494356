import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IAddComplication } from 'src/app/interfaces/Complication/IAddComplication';
import { IComplication } from 'src/app/interfaces/Complication/IComplication';
import { GetComplication } from 'src/app/interfaces/Complication/IGetComplication';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { ComplicationService } from 'src/app/services/complication.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-complication',
  templateUrl: './complication.component.html',
  styleUrls: ['./complication.component.css'],
})
export class ComplicationComponent implements OnInit {
  initialComplicationData: any[] = [];
  loading: boolean = false;
  patiendId!: number;
  visitId!: number;
  eocId!: number;
  addComplicationObj: IAddComplication = { complicationId: 0 };
  getComplicationData: GetComplication[] = [];

  constructor(private complicationService: ComplicationService) {}

  ngOnInit(): void {
    this.fetchAllComplicationData();
    this.fetchVisitComplicationData();
    this.synchronizeCheckedStates();
  }

  // get all complication data from database
  fetchAllComplicationData(): void {
    this.loading = true;
    this.complicationService.getComplicationDataDropDown().subscribe({
      next: (response) => {
        this.initialComplicationData = response.data.complication.map(
          (item) => ({
            ...item,
            checked: false,
          })
        );
        this.loading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve complication data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });
  }

  // get the visit's only complication data
  fetchVisitComplicationData(): void {
    this.loading = true;
    this.complicationService.getComplication().subscribe({
      next: (response) => {
        if (response.succeed && Array.isArray(response.data)) {
          this.getComplicationData = response.data;
          this.synchronizeCheckedStates();
        }
        this.loading = false;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve complication data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        this.loading = false;
      },
    });
  }
  // update the initial complication data checked values based on the visit's only complication data
  synchronizeCheckedStates(): void {
    // Map through initialComplicationData to update their 'checked' property
    this.initialComplicationData = this.initialComplicationData.map(
      (initialItem) => {
        const isComplicationChecked = this.getComplicationData.some(
          (getComplicationItem) =>
            getComplicationItem.complicationId === initialItem.id &&
            getComplicationItem.isChecked
        );
        const isComplicationSubmitted = this.getComplicationData.some(
          (getComplicationItem) =>
            getComplicationItem.complicationId === initialItem.id &&
            getComplicationItem.isSubmitted
        );
        return {
          ...initialItem,
          checked: isComplicationChecked,
          isSubmitted: isComplicationSubmitted,
        };
      }
    );
  }
  // checked complications
  get checkedItems(): any[] {
    return this.initialComplicationData.filter((item) => item.checked);
  }
  // uncecked complications
  get uncheckedItems(): any[] {
    return this.initialComplicationData.filter((item) => !item.checked);
  }

  //add complication
  onCheckChange(item: any): void {
    this.loading = true;
    this.addComplicationObj.complicationId = item.id;
    this.complicationService
      .addComplication(this.addComplicationObj)
      .subscribe({
        next: () => {
          this.loading = false;
          Swal.fire({
            text: 'Complication added successfully!',
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });
          this.fetchVisitComplicationData();
        },
        error: (err) => {
          this.loading = false;

          let errorMessage = 'Unable to add complication!';

          //is an HttpErrorResponse and contains the error message
          if (err instanceof HttpErrorResponse && err.error?.message) {
            errorMessage += ` Error: ${err.error.message}`;
          }

          this.loading = false;
          Swal.fire({
            text: errorMessage,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
        },
      });
  }

  //delete complication
  onUncheckChange(item: any): void {
    this.loading = true;

    const matchingComplication = this.getComplicationData.find(
      (complication) => complication.complicationId === item.id
    );
    if (matchingComplication) {
      this.getComplicationData.push(matchingComplication);
      this.complicationService
        .deleteComplication(matchingComplication.id)
        .subscribe({
          next: () => {
            this.loading = false;
            Swal.fire({
              text: 'Complication deleted successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
            this.fetchVisitComplicationData();
          },
          error: (err) => {
            this.loading = false;

            let errorMessage = 'Unable to add complication!';

            //is an HttpErrorResponse and contains the error message
            if (err instanceof HttpErrorResponse && err.error?.message) {
              errorMessage += ` Error: ${err.error.message}`;
            }

            this.loading = false;
            Swal.fire({
              text: errorMessage,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
    }
  }
}
