import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IMedicationDetailed } from 'src/app/interfaces/Medication/IMedicationDetailed';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import Swal from 'sweetalert2';
import { MedicationExpandComponent } from '../medication-expand/medication-expand.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-past-medication',
  templateUrl: './past-medication.component.html',
  styleUrls: ['./past-medication.component.css'],
})
export class PastMedicationComponent {
  // public expandComp = PastMedicationExpandComponent;

  private destroy$ = new Subject<void>();

  expandComp = MedicationExpandComponent;

  pastMedication?: IMedicationDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly pastMedicationService: PastMedicationService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.pastMedicationService.pastMedicationLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.pastMedicationService.pastMedicationDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IMedicationDetailed[]) => {
        this.pastMedication = data;
      });

    this.pastMedicationService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalFun(item?: IMedicationDetailed) {
    this.currentMedicationService.medicationEditSub.next(item);

    this.currentMedicationService.medicationOpenSub.next(true);
  }

  deleteFun(medicationId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Past Medication')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.pastMedicationService
          .deletePastMedication(medicationId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Past Medication deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.pastMedicationService.deletePastMedicationFromTable(
                medicationId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Past Medication!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Past Medication is not deleted', '', 'info');
      }
    });
  }
}
