<nz-layout>
  <nz-header *ngIf="submenu?.length! <= 1"> </nz-header>
  <nz-header *ngIf="submenu?.length! > 1" class="section-header">
    {{ "Social History" | translate }}
  </nz-header>
  <nz-content>
    <!-- <app-social-history-questonaire /> -->

    <!-- <ng-container *ngFor="let cm of submenu"> -->
    <app-social-history />
    <!-- <app-dynamic-questionnaire *ngIf="cm.path==componentPaths.socialHistoryQuestionnaire" [id]="cm.path" /> -->
    <!-- </ng-container> -->
  </nz-content>
</nz-layout>
