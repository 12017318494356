<div>
    <div fxLayout="row" fxLayout.lt-lg="column">
        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" class="left-half">
            <img src="../../../assets/images/loginScreen/loginBuilding.png" class="left-image">
        </div>


        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" fxLayoutAlign="center center">

            <div fxLayout="column" fxFlex="60">
                <div fxLayout="row" class="w-100 forgot-line" fxLayoutAlign="center center">
                    <img src="../../../assets/images/loginScreen/3aHealth.png" class="logo">
                </div>


                <div fxLayout="row" class="w-100 login-text" fxLayoutAlign="start start">
                    {{"Login"|translate}}
                </div>

                <input nz-input [(ngModel)]="username" type="text" class="username" placeholder="Username" />

                <div class="password-input-container" style="position: relative; width: 100%;">
                    <input nz-input [(ngModel)]="password" [type]="passwordFieldType" class="password"
                        placeholder="Password" />
                    <i class="fa" [ngClass]="passwordFieldType === 'password' ? 'fa-eye' : 'fa-eye-slash'"
                        (click)="togglePasswordVisibility()"></i>
                </div>

                <button nz-button class="login-btn" (click)="login()">{{"Sign In"|translate}}</button>

                <div fxLayout="row" class="w-100 forgot-line" fxLayoutAlign="start center">
                    <div fxLayout="column" fxFlex="auto" class="w-100" fxLayoutAlign="center start">
                        <div><input type="checkbox"><span class="remember-me">{{"Remember me"|translate}}</span></div>
                    </div>
                    <div fxLayout="column" fxFlex="auto" class="w-100" fxLayoutAlign="center end">
                        <a href="/newforgot">{{"Forgot Password"|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>