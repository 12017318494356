import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import { saveAs } from 'file-saver';
import * as i2 from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Subject, takeUntil, filter, fromEvent } from 'rxjs';

/** Check the `Blob` existance only once. */
let isFileSaverSupported;
try {
    isFileSaverSupported = !!new Blob();
}
catch {
    isFileSaverSupported = false;
}
class FileSaverService {
    get isFileSaverSupported() {
        return isFileSaverSupported;
    }
    genType(fileName) {
        if (!fileName || fileName.lastIndexOf('.') === -1) {
            return 'text/plain';
        }
        const type = fileName.substring(fileName.lastIndexOf('.') + 1);
        switch (type) {
            case 'txt':
                return 'text/plain';
            case 'xml':
            case 'html':
                return `text/${type}`;
            case 'json':
                return 'octet/stream';
            default:
                return `application/${type}`;
        }
    }
    save(blob, fileName, filtType, option) {
        if (!blob) {
            throw new Error('Data argument should be a blob instance');
        }
        const file = new Blob([blob], { type: filtType || blob.type || this.genType(fileName) });
        saveAs(file, decodeURI(fileName || 'download'), option);
    }
    saveText(txt, fileName, option) {
        const blob = new Blob([txt]);
        this.save(blob, fileName, undefined, option);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverService, deps: [], target: i0.ɵɵFactoryTarget.Injectable }); }
    static { this.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverService, providedIn: 'root' }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverService, decorators: [{
            type: Injectable,
            args: [{ providedIn: 'root' }]
        }] });

/* eslint-disable @angular-eslint/no-output-native */
class FileSaverDirective {
    constructor(ngZone, el, fss, httpClient) {
        this.ngZone = ngZone;
        this.el = el;
        this.fss = fss;
        this.httpClient = httpClient;
        this.method = 'GET';
        this.success = new EventEmitter();
        this.error = new EventEmitter();
        this.destroy$ = new Subject();
        if (!fss.isFileSaverSupported) {
            el.nativeElement.classList.add(`filesaver__not-support`);
        }
    }
    ngOnInit() {
        this.ngZone.runOutsideAngular(() => this.setupClickListener());
    }
    ngOnDestroy() {
        this.destroy$.next();
    }
    getName(res) {
        return decodeURI(this.fileName || res.headers.get('filename') || res.headers.get('x-filename') || '');
    }
    setDisabled(status) {
        const el = this.el.nativeElement;
        el.disabled = status;
        el.classList[status ? 'add' : 'remove'](`filesaver__disabled`);
    }
    setupClickListener() {
        fromEvent(this.el.nativeElement, 'click')
            .pipe(filter(() => this.fss.isFileSaverSupported), takeUntil(this.destroy$))
            .subscribe(() => {
            let req = this.http;
            if (!req) {
                let params = new HttpParams();
                const query = this.query || {};
                for (const item in query) {
                    params = params.set(item, query[item]);
                }
                req = this.httpClient.request(this.method, this.url, {
                    observe: 'response',
                    responseType: 'blob',
                    headers: this.header,
                    params,
                });
            }
            this.setDisabled(true);
            req.pipe(takeUntil(this.destroy$)).subscribe({
                next: (response) => {
                    if (response.status !== 200 || response.body.size <= 0) {
                        this.emitIfHasObservers(this.error, response);
                        return;
                    }
                    this.fss.save(response.body, this.getName(response), undefined, this.fsOptions);
                    this.emitIfHasObservers(this.success, response);
                },
                error: (error) => this.emitIfHasObservers(this.error, error),
                complete: () => this.setDisabled(false),
            });
        });
    }
    emitIfHasObservers(emitter, value) {
        if (hasObservers(emitter)) {
            // Re-enter the Angular zone only if there're any `error` or `success` listeners
            // on the directive, for instance, `(success)="..."`.
            this.ngZone.run(() => emitter.emit(value));
        }
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverDirective, deps: [{ token: i0.NgZone }, { token: i0.ElementRef }, { token: FileSaverService }, { token: i2.HttpClient }], target: i0.ɵɵFactoryTarget.Directive }); }
    static { this.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "17.0.1", type: FileSaverDirective, isStandalone: true, selector: "[fileSaver]", inputs: { method: "method", http: "http", query: "query", header: "header", url: "url", fileName: "fileName", fsOptions: "fsOptions" }, outputs: { success: "success", error: "error" }, exportAs: ["fileSaver"], ngImport: i0 }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[fileSaver]',
                    exportAs: 'fileSaver',
                    standalone: true,
                }]
        }], ctorParameters: () => [{ type: i0.NgZone }, { type: i0.ElementRef }, { type: FileSaverService }, { type: i2.HttpClient }], propDecorators: { method: [{
                type: Input
            }], http: [{
                type: Input
            }], query: [{
                type: Input
            }], header: [{
                type: Input
            }], url: [{
                type: Input,
                args: [{ required: true }]
            }], fileName: [{
                type: Input
            }], fsOptions: [{
                type: Input
            }], success: [{
                type: Output
            }], error: [{
                type: Output
            }] } });
function hasObservers(subject) {
    // Note: The `observed` property is available only in RxJS@7.2.0, which means it's
    // not available for users running the lower version.
    return subject.observed ?? subject.observers.length > 0;
}

class FileSaverModule {
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule }); }
    static { this.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "17.0.1", ngImport: i0, type: FileSaverModule, imports: [FileSaverDirective], exports: [FileSaverDirective] }); }
    static { this.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverModule }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "17.0.1", ngImport: i0, type: FileSaverModule, decorators: [{
            type: NgModule,
            args: [{
                    imports: [FileSaverDirective],
                    exports: [FileSaverDirective],
                }]
        }] });

/**
 * Generated bundle index. Do not edit.
 */

export { FileSaverDirective, FileSaverModule, FileSaverService };

