/* src/app/components/ehr/patients/profile/profile.component.css */
.patient-profile-container {
  padding-top: 0px;
  display: flex;
  height: 100%;
  margin-top: 120px;
}
.profile-container {
  min-height: calc(100vh - 66px);
  background-color: #f4f4f6;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.inner-layout {
  border-top: 2px solid #f4f4f6;
  flex: 1;
}
nz-content {
  background-color: #f4f4f6;
}
.sider-menu {
  background-color: white !important;
  height: 100%;
}
.ant-menu-item-selected {
  background-color: rgba(118, 195, 188, 0.2) !important;
  color: #161d20 !important;
  font-weight: 500;
}
.ant-menu {
  background-color: rgba(118, 195, 188, 0.01) !important;
  color: #161d20 !important;
}
::ng-deep .anticon {
  scale: 1.5 !important;
}
::ng-deep .ant-layout-sider-collapsed {
  min-width: 5% !important;
}
::ng-deep .ant-menu-light:hover,
::ng-deep .ant-menu-item {
  color: #161d20 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
::ng-deep .ant-menu-submenu-selected {
  color: #161d20 !important;
  background-color: rgba(118, 195, 188, 0.4) !important;
}
::ng-deep .ant-menu-item:hover {
  background-color: rgba(49, 125, 136, 0.15) !important;
  color: #161d20 !important;
}
::ng-deep .ant-menu-submenu-active {
  color: #161d20 !important;
}
::ng-deep .ant-menu-item::after {
  border-right: 3px solid rgba(49, 125, 136, 1) !important;
}
::ng-deep .ant-menu-submenu-title {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  background-color: rgba(118, 195, 188, 0.1) !important;
  border-bottom: 1px solid rgba(118, 195, 188, 0.5) !important;
  font-weight: 600;
  white-space: normal !important;
  height: auto !important;
}
::ng-deep .ant-menu-submenu-title.ng-star-inserted {
  white-space: normal !important;
  height: auto !important;
  margin-right: 20px;
}
::ng-deep .ant-menu-submenu-title:hover {
  background-color: rgba(118, 195, 188, 0.2) !important;
  color: #161d20 !important;
}
::ng-deep .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #161d20 !important;
}
::ng-deep li.ant-menu-item {
  border-left: 1px solid #d8d8d8 !important;
  margin-left: 30px !important;
  width: calc(100% + 1px - 30px) !important;
  white-space: normal !important;
  height: auto !important;
  padding-left: 10px !important;
}
::ng-deep li.ant-menu-item {
  border-top: 0.5px solid rgba(77, 97, 95, 0.2) !important;
}
::ng-deep .ant-menu-sub.ant-menu-inline {
  background-color: white !important;
  background: white !important;
  white-space: normal !important;
}
::ng-deep .ant-layout-sider {
  background: white;
}
.collapse-menu {
  background-color: #0d9488;
  scale: 0.5;
  position: fixed;
  margin-top: 3px;
}
.collapse-menu-icon {
  scale: 1.5;
}
::ng-deep .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  display: flex !important;
  height: 70px !important;
  align-items: center;
}
nz-sider {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  position: fixed;
  width: 100%;
  height: calc(100vh - 120px - 66px);
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.5);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.5);
}
@media only screen and (max-width: 1024px) {
  nz-sider:not(.ant-layout-sider-collapsed) {
    flex: 0 0 30% !important;
    max-width: 30% !important;
    min-width: 30% !important;
    width: 30% !important;
  }
  .inner-content {
    padding-bottom: 3em !important;
    margin-left: 30% !important;
  }
}
.inner-content {
  padding-bottom: 3em;
  margin-left: 15%;
}
.inner-content-collapse {
  padding-bottom: 3em;
  margin-left: 80px !important;
}
.single-menu {
  display: flex !important;
  align-items: center !important;
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
  line-height: 40px !important;
  width: calc(100% + 1px) !important;
  padding: 0 22px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  background-color: rgba(118, 195, 188, 0.1) !important;
  border-bottom: 1px solid rgba(118, 195, 188, 0.5) !important;
  font-weight: 600 !important;
  white-space: normal !important;
  height: auto !important;
  color: #161d20 !important;
  border-left: 0px solid #d8d8d8 !important;
  margin-left: 0px !important;
}
::ng-deep .ant-menu-title-content {
  width: 100% !important;
}
.single-menu-icon {
  line-height: 40px;
  width: 100%;
}
.single-menu-icon-nc {
  padding-left: 3px !important;
}
.single-menu-collapse {
  height: 70px !important;
  justify-content: center;
}
.ant-menu-item-selected.single-menu {
  background-color: rgba(118, 195, 188, 0.4) !important;
}
.label-single {
  display: none !important;
}
.pilot-study {
  display: flex !important;
  align-items: center !important;
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
  line-height: 40px !important;
  width: calc(100% + 1px) !important;
  padding: 0px 0px 0px 0px !important;
  text-align: center;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  background-color: white !important;
  border-top: 3px solid #0d9488 !important;
  border-bottom: 3px solid #0d9488 !important;
  font-weight: 600 !important;
  white-space: normal !important;
  height: auto !important;
  color: #161d20 !important;
  margin-left: 0px !important;
}
/*# sourceMappingURL=profile.component-UK5KTONM.css.map */
