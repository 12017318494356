import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css'],
})
export class InsuranceComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() insuranceForm: FormGroup = new FormGroup({});

  @Input() insuranceList: ICoding[] = [];

  constructor(private formBuilder: FormBuilder) {
    this.insuranceForm.addControl('insurance', this.formBuilder.control(''));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['insuranceList']) {
      // GESY ID - 30
      this.insuranceForm.patchValue({
        insurance: [this.insuranceList.find((i) => i.id == 30)?.id],
      });
    }
  }
}
