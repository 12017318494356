import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { IAddTubeCharacteristics } from 'src/app/interfaces/TubeCharacteristics/IAddTubeCharacteristics';
import { ITubeCharacteristicsDropdown } from 'src/app/interfaces/TubeCharacteristics/ITubeCharacteristicsDropdown';
import { TubeCharacteristicsService } from 'src/app/services/tubeCharacteristics.service';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-tube-characteristics-add-edit',
  templateUrl: './tube-characteristics-add-edit.component.html',
  styleUrls: ['./tube-characteristics-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class TubeCharacteristicsAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;
  isDropdownLoading = false;

  isEdit = false;
  editTubeCharacteristicsId?: number;

  tubeCharacteristicsSubmitted = false;

  dropdown: ITubeCharacteristicsDropdown = {};

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  tubeCharacteristicsForm: FormGroup = new FormGroup({});

  constructor(
    private readonly tubeCharacteristicsService: TubeCharacteristicsService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = true;
    this.isDropdownLoading = true;

    this.tubeCharacteristicsForm = this.formBuilder.group({
      tubeLengthValue: [null, Validators.required],
      outerDiameterValue: [null, Validators.required],
      innerDiameterValue: [null, Validators.required],
      cuffDiameterValue: [null, Validators.required],
      consistencyOfTubeValue: [null, Validators.required],
      tubeTypeValue: [null, Validators.required],
    });

    this.tubeCharacteristicsService.getTubeCharacteristicsDropdown().subscribe({
      next: (pro) => {
        this.isDropdownLoading = false;
        this.dropdown = pro?.data;
      },
      error: (err) => {},
    });

    this.tubeCharacteristicsService.tubeCharacteristicsEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.isLoading = false;

          this.editTubeCharacteristicsId = data?.id;

          this.tubeCharacteristicsForm.patchValue({
            tubeLengthValue: data?.tubeLengthId,
            outerDiameterValue: data?.outerDiameterId,
            innerDiameterValue: data?.innerDiameterId,
            cuffDiameterValue: data?.cuffDiameterId,
            consistencyOfTubeValue: data?.consistencyOfTubeId,
            tubeTypeValue: data?.tubeTypeId,
          });
        } else {
          this.isEdit = false;
          this.isLoading = false;

          this.editTubeCharacteristicsId = undefined;

          this?.tubeCharacteristicsForm.reset();
        }
      });

    this.tubeCharacteristicsService.tubeCharacteristicsModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.tubeCharacteristicsForm.reset();

    this.tubeCharacteristicsService.tubeCharacteristicsModalSub.next(false);

    this.tubeCharacteristicsSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.tubeCharacteristicsSubmitted = true;

    if (this.tubeCharacteristicsForm.valid) {
      const tubeCharacteristicsObj: IAddTubeCharacteristics = {};

      tubeCharacteristicsObj.id = this.editTubeCharacteristicsId;

      tubeCharacteristicsObj.tubeLengthId =
        this.tubeCharacteristicsForm.get('tubeLengthValue')?.value;
      tubeCharacteristicsObj.outerDiameterId =
        this.tubeCharacteristicsForm.get('outerDiameterValue')?.value;
      tubeCharacteristicsObj.innerDiameterId =
        this.tubeCharacteristicsForm.get('innerDiameterValue')?.value;
      tubeCharacteristicsObj.cuffDiameterId =
        this.tubeCharacteristicsForm.get('cuffDiameterValue')?.value;
      tubeCharacteristicsObj.consistencyOfTubeId =
        this.tubeCharacteristicsForm.get('consistencyOfTubeValue')?.value;
      tubeCharacteristicsObj.tubeTypeId =
        this.tubeCharacteristicsForm.get('tubeTypeValue')?.value;

      if (this.isEdit) {
        this.tubeCharacteristicsService
          .editTubeCharacteristics(tubeCharacteristicsObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'TubeCharacteristics record edited successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.tubeCharacteristicsService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to edit TubeCharacteristics record!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });

              this.handleCancel();
            },
          });
      } else {
        this.tubeCharacteristicsService
          .addTubeCharacteristics(tubeCharacteristicsObj)
          .subscribe({
            next: () => {
              Swal.fire({
                text: 'TubeCharacteristics record added successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.tubeCharacteristicsService.getData();

              this.handleCancel();
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to add TubeCharacteristics record!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      }
    } else {
      this.isLoading = false;
    }
  }
}
