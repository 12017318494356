import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ICoding } from 'src/app/interfaces/ICoding';
import { IDoctor } from '../../../../interfaces/IDoctor';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { AddPatientService } from '../../../../services/addpatient.service';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css'],
})
export class PersonalInfoComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() personalInfoForm: FormGroup = new FormGroup({});

  @Input() gendersList: ICoding[] = [];

  @Input() profilePicture?: File;

  //Extended profile inputs
  @Input() placeOfBirthList: ICoding[] = [];
  @Input() closestRelativesList: ICoding[] = [];
  @Input() educationLevelList: ICoding[] = [];
  @Input() familyStatusList: ICoding[] = [];
  @Input() sourceOfIncomeList: ICoding[] = [];
  @Input() religionList: ICoding[] = [];

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  // Form fields
  public firstName?: string;
  public lastName?: string;
  public dateOfBirth?: Date;
  public phone?: string;
  public gender?: string = '';

  avatarImageUrl?: string;

  isPlaceOfBirthCyprus: boolean = false;

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];

  //Extended profile (amen)
  public dateOfAdmission?: Date;
  public occupation?: string;
  public amountOfIncome?: string;
  public placeOfBirthId?: number;
  public closestRelativesId?: number;
  public educationLevelId?: number;
  public familyStatusId?: number;
  public sourceOfIncomeId?: number;
  public religionId?: number;
  public registrationAgentId?: number;

  constructor(
    private readonly formBuilder: FormBuilder,
    private dialog: MatDialog,
    private addPatientService: AddPatientService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.personalInfoForm.addControl(
      'firstName',
      this.formBuilder.control('', Validators.required)
    );
    this.personalInfoForm.addControl(
      'lastName',
      this.formBuilder.control('', Validators.required)
    );
    this.personalInfoForm.addControl(
      'dateOfBirth',
      this.formBuilder.control('', [
        Validators.required,
        this.minDateValidator(new Date('1900-01-01')),
        this.maxDateValidator(new Date()),
      ])
    );
    this.personalInfoForm.addControl(
      'phone',
      this.formBuilder.control(undefined)
    );
    this.personalInfoForm.addControl(
      'gender',
      this.formBuilder.control('', Validators.required)
    );
    //extended profile controls
    this.personalInfoForm.addControl(
      'dateOfAdmission',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'occupation',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'amountOfIncome',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'districtOfBirth',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'placeOfBirthId',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'closestRelativesId',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'closestRelativesOther',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'educationLevelId',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'educationLevelOther',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'familyStatusId',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'familyStatusOther',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'sourceOfIncomeId',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'religionId',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'religionOther',
      this.formBuilder.control('')
    );
    this.personalInfoForm.addControl(
      'registrationAgentId',
      this.formBuilder.control('')
    );
  }
  // Method to handle place of birth change
  onPlaceOfBirthChange(placeId: number): void {
    this.isPlaceOfBirthCyprus = placeId === 55; // Cyprus id is 55
    // If not Cyprus, reset the placeOfBirth field
    if (!this.isPlaceOfBirthCyprus) {
      this.personalInfoForm.get('districtOfBirth')?.setValue('');
    }
  }
  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  uploadAndOpenImageCropper(): void {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.dialog.open(ImageCropperComponent, {
          width: '600px',
          data: { imageChangedEvent: { target: { files: [file] } } },
        });

        this.dialog.afterAllClosed.subscribe({
          next: () => {
            if (this.addPatientService.getProfilePicture() != null) {
              const UrlFromBlob = this.addPatientService.getProfilePicture();
              this.avatarImageUrl = URL.createObjectURL(UrlFromBlob!);
            } else {
              this.avatarImageUrl = undefined;
            }
          },
        });
      };
      reader.readAsDataURL(file);
    });
    fileInput.click();
  }

  // Custom validator for minimum date
  minDateValidator(minDate: Date) {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputDate = new Date(control.value);
      if (inputDate < minDate) {
        return { minDate: true };
      }
      return null;
    };
  }

  // Custom validator for maximum date
  maxDateValidator(maxDate: Date) {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputDate = new Date(control.value);
      if (inputDate > maxDate) {
        return { maxDate: true };
      }
      return null;
    };
  }

  enter(event: any) {
    event.preventDefault();

    const nextButton = document.getElementById(
      'personal-info-next-btn'
    ) as HTMLButtonElement;
    if (nextButton) {
      nextButton.click();
    } else {
      console.warn('Button not found.');
    }
  }
}
