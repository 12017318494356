/* src/app/components/ehr/dashboard-amen/dashboard-inpatient-by-older-age/dashboard-inpatient-by-older-age.component.css */
#divChart {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30vh;
  justify-content: center;
}
.chart-container {
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px;
  background-color: white;
}
#inPatientOlderAge {
  width: 100%;
  height: 30vh;
}
/*# sourceMappingURL=dashboard-inpatient-by-older-age.component-BPKI7HYT.css.map */
