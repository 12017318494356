/* src/app/components/ehr/workspace/incoming-referrals/add-calendar-event/add-calendar-event.component.css */
.inputs {
  width: 100%;
}
textarea {
  width: 100%;
  box-sizing: border-box;
}
.nz-switch {
  margin-top: 8px;
}
input,
textarea,
.nz-select {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
.form-row {
  margin-top: 1em;
}
.footer {
  margin-top: 2em;
}
.cancel-btn {
  background: #f3f3f3 !important;
  border: 1px solid gray !important;
}
.cancel-btn:hover {
  background: lightgray !important;
}
.save-btn {
  margin-right: 1em;
}
.add-btn {
  margin-bottom: 1em;
}
::ng-deep .ant-modal {
  min-width: 520px !important;
  width: 50% !important;
}
::ng-deep .ant-modal-content {
  border-radius: 10px;
}
::ng-deep .ant-modal-header {
  border-radius: 10px 10px 0px 0px;
}
::ng-deep .ant-btn {
  color: black;
  background: rgba(118, 195, 188, 0.4) !important;
  border-color: #0d9488 !important;
}
::ng-deep .ant-btn:focus {
  color: black;
}
::ng-deep .ant-btn:hover {
  color: black;
  background: rgba(118, 195, 188, 0.8) !important;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
.nz-select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
@media only screen and (max-width: 1279.99px) {
  .add-title {
    display: none !important;
  }
  .add-icon {
    margin-right: 0em !important;
  }
}
.material-icons {
  font-size: 22px;
}
.title {
  padding-left: 1em;
  padding-right: 1em;
}
.left-margin {
  margin-left: 1em;
}
.reactions-container {
  margin-top: 2em;
  padding: 1.5em;
  border: 2px solid rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}
.no-known {
  padding-left: 1em;
  padding-right: 1em;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}
.no-known-content {
  display: flex !important;
}
.no-known.ant-layout {
  display: flex !important;
  width: 100% !important;
}
::ng-deep .ant-switch-checked {
  background: #0d9488 !important;
}
::ng-deep .ant-pagination-item-active a {
  color: black !important;
  border-color: black !important;
}
::ng-deep .ant-pagination-item-active {
  border-color: black !important;
}
.add-icon {
  margin-right: 0.2em;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
.asterisk {
  color: red;
}
.color-options {
  display: flex;
  gap: 10px;
}
.color-options label {
  position: relative;
  cursor: pointer;
}
.color-options input[type=radio] {
  display: none;
}
.color-options .color-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}
.color-options input[type=radio]:checked + .color-circle {
  border-color: black;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.info-message {
  color: #505253;
  font-size: 12px;
  margin-top: 5px;
}
::ng-deep .ant-switch,
nz-switch {
  flex-shrink: 0 !important;
  width: auto !important;
}
.color-circle.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
/*# sourceMappingURL=add-calendar-event.component-XJTRPHLX.css.map */
