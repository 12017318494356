import { Component, Input } from '@angular/core';
import { IModule } from 'src/app/interfaces/IModule';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-hysteroscopy-section',
  templateUrl: './hysteroscopy-section.component.html',
  styleUrls: ['./hysteroscopy-section.component.css']
})
export class HysteroscopySectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;
  constructor(private readonly eocService: EpisodeOfCareService) {}

  ngOnInit() {}
}
