<nz-layout>
    <nz-header class="section-header">
        {{'Medical Problems'|translate}}
    </nz-header>
    <nz-content>
        <ng-container *ngFor="let cm of submenu">
            <app-current-problems *ngIf="cm.path==componentPaths.currentProblems" [id]="cm.path" />
            <app-medical-device *ngIf="cm.path==componentPaths.devicesAndImplants" [id]="cm.path" />
            <app-procedure *ngIf="cm.path==componentPaths.procedures" [id]="cm.path" />
        </ng-container>
    </nz-content>
</nz-layout>