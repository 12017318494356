import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IPatientDetailed,
  IAssignedDoctor,
} from '../../../../interfaces/Detailed/IPatientDetailed';

@Component({
  selector: 'app-detailed-profile',
  templateUrl: './detailed-profile.component.html',
  styleUrls: ['./detailed-profile.component.css'],
})
export class DetailedProfileComponent {
  patient: IPatientDetailed | undefined;

  constructor(
    public dialogRef: MatDialogRef<DetailedProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { patient: IPatientDetailed }
  ) {
    this.patient = data.patient; // Assign the received patient data
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getPrimaryDoctor(): IAssignedDoctor | undefined {
    return this.patient?.assignedDoctors?.find(
      (doctor) => doctor.primaryDoctor
    );
  }

  getNonPrimaryDoctors(): IAssignedDoctor[] {
    return (
      this.patient?.assignedDoctors?.filter(
        (doctor) => !doctor.primaryDoctor
      ) || []
    );
  }
}
