/* src/app/components/ehr/register-patient/personal-info/personal-info.component.css */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
::ng-deep .mat-vertical-content {
  padding: 15px 24px 24px 24px !important;
}
.add-patient-row {
  margin-bottom: 1.5em;
}
.add-patient-col {
  margin-left: 1em;
  margin-right: 2em;
  height: 35px !important;
}
.add-patient-profile {
  margin-right: 1em;
  margin-left: 1em;
  height: 100px !important;
}
.add-patient-input {
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
.add-patient-inputZorro {
  width: 100%;
  color: #c2c2c2 !important;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
.ant-select-single {
  font-family: "Inter", sans-serif !important;
  color: black !important;
}
select.ng-valid.ng-touched {
  color: black;
}
input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: #c2c2c2;
}
.add-patient-button {
  height: 100%;
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-patient-picture-btn {
  border: 1px solid #317d88;
  border-radius: 4px;
  background-color: #317d88 !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
input.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
.submitted .ng-invalid {
  border: red 1px solid;
  border-radius: 2px;
}
.optional {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 12px;
}
.assigned-doctors-list {
  margin-left: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.assigned-doctors-list h3 {
  margin-bottom: 0.5em;
}
.primary-doctor-item {
  margin-bottom: 0.5em;
}
.primary-doctor-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.primary-doctor-label input[type=checkbox] {
  margin-right: 0.5em;
  accent-color: #0d9488;
}
.radio-button-list label {
  display: block;
  padding: 5px 0;
}
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.radio-label input {
  margin-right: 8px;
}
input[type=radio] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #c2c2c2;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}
input[type=radio]:checked {
  background-color: #0d9488;
  border: 2px solid #0d9488;
}
input[type=radio]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}
::ng-deep section ::ng-deep .tel-form {
  height: 35px !important;
}
::ng-deep section ::ng-deep .tel-form ::ng-deep mat-form-field ::ng-deep .mdc-text-field {
  height: 35px !important;
  align-items: center !important;
}
::ng-deep ngx-intl-tel-input input {
  height: 35px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::ng-deep ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}
::ng-deep ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}
::ng-deep ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}
::ng-deep ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
::ng-deep .ant-select-selector {
  height: 100% !important;
}
/*# sourceMappingURL=personal-info.component-ZQJRDRJV.css.map */
