import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ComorbidityService } from 'src/app/services/comorbidity.service';
import Swal from 'sweetalert2';
import { IAddComorbidity } from 'src/app/interfaces/Comorbidity/IAddComorbidity';
import { IComorbidityDetailed } from 'src/app/interfaces/Comorbidity/IComorbidityDetailed';
import { ComorbidityAddEditComponent } from '../comorbidity-add-edit/comorbidity-add-edit.component';
import { ComorbidityExpandComponent } from '../comorbidity-expand/comorbidity-expand.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comorbidity',
  templateUrl: './comorbidity.component.html',
  styleUrls: ['./comorbidity.component.css'],
})
export class ComorbidityComponent {
  public addComp = ComorbidityAddEditComponent;
  public expandComp = ComorbidityExpandComponent;

  private destroy$ = new Subject<void>();

  comorbidity?: IComorbidityDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly comorbidityService: ComorbidityService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: IAddComorbidity) {
    this.comorbidityService.comorbidityModalSub.next(true);
    this.comorbidityService.comorbidityEditSub.next(item);
  }

  deleteFun(comorbidityId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Comorbidity?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.comorbidityService.deleteComorbidity(comorbidityId).subscribe({
          next: (d) => {
            Swal.fire({
              text: 'Comorbidity deleted successfully!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            this.comorbidityService.deleteComorbidityFromTable(
              comorbidityId.toString()
            );
          },
          error: (err) => {
            Swal.fire({
              text: 'Unable to delete comorbidity!',
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire('Comorbidity is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.comorbidityService.comorbidityLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.comorbidityService.comorbidityDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IComorbidityDetailed[]) => {
        this.comorbidity = data;
      });

    this.comorbidityService.getData();
  }
}
