import {
  Component,
  Input,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TableRow } from '../table-row.interface';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { AllergiesExpandComponent } from 'src/app/components/ehr/patients/components/alerts-section/allergies/allergies-expand/allergies-expand.component';
import { VisitExpandComponent } from 'src/app/components/ehr/patients/components/eoc-section/visit-expand/visit-expand.component';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { MatIconModule } from '@angular/material/icon';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { Router } from '@angular/router';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgModule } from '@angular/core';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import moment from 'moment';
import { IReferenceValue } from 'src/app/interfaces/IReferenceValue';
import { DynamicService } from '../../services/dynamic.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import { CapnographyService } from 'src/app/services/capnography.service';
import { ComorbidityService } from 'src/app/services/comorbidity.service';

@Component({
  standalone: true,
  selector: 'app-reusable-reverse-table',
  templateUrl: './reusable-reverse-table.component.html',
  styleUrls: ['./reusable-reverse-table.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    AllergiesExpandComponent,
    VisitExpandComponent,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    TooltipComponent,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NzPaginationModule,
    NgTemplateOutlet,
  ],
  providers: [NgModule],
})
export class ReusableReverseTableComponent<T extends TableRow> {
  currentPageIndex: number = 1;
  pageSize: number = 10;

  @Input() noKnownComponent: Type<any> | null = null;
  @Input() addComponent: Type<any> | null = null;

  @Input() isLoading?: boolean = false;
  @Input() isNoKnownLoading?: boolean;
  @Input() pagination?: boolean = true;

  @Input() title: string = '';
  @Input() colIdentifier: string = '';

  @Input() rowNames: string[] = [];
  @Input() rowFields: string[] = [];

  @Input() data: T[] = [];
  @Input() dataGetList?: IGetList<T>;

  @Input() isPreview?: boolean;

  @Input() deleteFun?: Function;
  @Input() openModalFun?: Function;
  @Input() handleToggleFun?: Function;

  filteredData: T[] = [];

  @ViewChild('expandContainer', { read: ViewContainerRef, static: false })
  expandCont!: ViewContainerRef;

  // DO NOT REMOVE LAB RESULTS,... SERVICES!!!
  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly dynamicService: DynamicService,
    private readonly capnographyService: CapnographyService,
    private readonly vitalSignService: VitalSignService,
    private readonly comorbidityService: ComorbidityService,
    private readonly translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.getData();
    }
  }

  handleToggle() {
    this.handleToggleFun?.(
      this.isPreview,
      !this.dataGetList?.noKnown,
      this.dataGetList?.getUnknown?.id?.toString()!
    );
  }

  getData() {
    if (this.data?.length! > 0) {
      if (this.pagination) {
        const startIndex = (this.currentPageIndex - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.filteredData = this?.data?.slice(startIndex, endIndex) || [];
      } else {
        this.filteredData = this?.data || [];
      }
    } else {
      this.filteredData = [];
    }
  }

  handlePageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;

    this.getData();
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPageIndex = 1; // Reset to the first page when changing page size

    this.getData();
  }

  expandSet: Set<number> = new Set<number>();

  getUnknownText(): string {
    if (this.dataGetList?.noInfo || this.dataGetList?.noKnown) {
      for (const key in this.dataGetList?.getUnknown) {
        if (key !== 'id') {
          return this.dataGetList?.getUnknown[key];
        }
      }
    }

    return 'No data available';
  }

  openModal(item?: T) {
    this?.openModalFun?.(item);
  }

  onExpandChange(id: number): void {
    if (this.expandSet) {
      if (!this.expandSet.has(id)) {
        this.expandSet.add(id);
      } else {
        this.expandSet.delete(id);
      }
    }
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  getValue(field: string, value?: string) {
    if (!value) {
      return '-';
    }

    if (field?.toLowerCase()?.includes('datetime')) {
      return moment(value).format('YYYY-MM-DD h:mm a');
    } else if (
      field?.toLowerCase()?.includes('date') ||
      field?.toLowerCase()?.includes('onset')
    ) {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return value;
    }
  }

  checkReference(value: any, refValue?: IReferenceValue): boolean {
    if (typeof value == 'number' && refValue) {
      if (value < refValue?.min! || value > refValue?.max!) {
        return true;
      }
    }
    return false;
  }

  checkRV(value: any, refValue?: IReferenceValue): string {
    if (typeof value == 'number' && refValue) {
      if (!refValue?.mid) {
        if (value < refValue?.min! || value > refValue?.max!) {
          return 'red';
        }
      } else {
        if (value > refValue?.max!) {
          return 'red';
        } else if (value > refValue?.mid!) {
          return 'orange';
        }
      }
    }
    return '';
  }
}
