<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table title="Health Care Plans"
                          [columns]="['Description']"
                          [rowFields]="['description']"
                          [data]="carePlans" [addComponent]="addComp" [deleteFun]="deleteFun"
                          [isLoading]="isLoading" [openModalFun]="openModalFun" [isPreview]="false" />
    </ng-container>
  </nz-content>

</nz-layout>
