<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Pregnancy History') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="pregnancyHistoryForm" (ngSubmit)="submitForm()"
            [ngClass]="{'history-submitted': pregnancyHistorySubmitted, 'history-not-submitted': !pregnancyHistorySubmitted}">
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Status" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs history" type="text" placeholder="{{'Status ...' | translate}}"
                        formControlName="statusValue" />
                </div>
            </div>


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Description" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs history" type="text" placeholder="{{'Description ...' | translate}}"
                        formControlName="descriptionValue" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Outcome Date" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <input nz-input class="inputs history" type="date" placeholder="{{'Outcome Date' | translate}}"
                        formControlName="outcomeDateValue" [max]="getCurrentDate()" />
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
</nz-modal>