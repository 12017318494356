<nz-layout class="table-margin">
    <nz-header *ngIf="title" class="table-header">
        <div class="table-header-row margin-lr" *ngIf="(isEOC()||isInpatientView()) || isPreview || data?.length! > 0">
            <div class="table-header-cell">
                {{ title | translate }}
                <span class="margin-llr"
                    *ngIf="!isLoading && (((isEOC()||isInpatientView())) || isPreview) && ((this.dataGetList?.noKnown || this.dataGetList?.noInfo)
                || (this.dataGetList?.noKnown==false && this.dataGetList?.noInfo==false && this.dataGetList?.getAll==null && this.dataGetList?.getUnknown==null))">
                    <div class="no-known-content">
                        <nz-switch [ngModel]="this.dataGetList?.noKnown"
                            [nzDisabled]="this.dataGetList?.isSubmitted || isPreview" [nzLoading]="isNoKnownLoading"
                            (ngModelChange)="handleToggle()"></nz-switch>
                        <div>{{"No known" | translate}}</div>
                        <div class="add-title">&nbsp;</div>
                        <div class="add-title">{{title | translate}}</div>
                    </div>
                </span>
            </div>
            <div class="table-header-cell margin-lrr"><button nz-button nzShape="round" (click)="openModal()"
                    *ngIf="(isEOC()||isInpatientView()) && addComponent && openModalFun && !isPreview">
                    <div fxLayout="row">
                        <div fxLayout="col"><mat-icon class="add-icon">add_circle_outline</mat-icon>
                        </div>
                        <div fxLayout="col" class="add-title">
                            {{"Add" | translate}} {{title | translate}}
                        </div>
                    </div>
                </button>
            </div>
        </div>

        <div fxLayout="row" class="table-header-row"
            *ngIf="!(isEOC()||isInpatientView()) && !isPreview && !(data?.length! > 0)">
            <div fxLayout="col" class="title" *ngIf="!isLoading">
                {{ title | translate }} - {{getUnknownText() | translate}}
            </div>
        </div>
    </nz-header>
    <nz-content *ngIf="((data?.length! > 0 && !this.dataGetList?.noKnown) || isLoading) && !isNoKnownLoading">
        <nz-table #nzTable nzTableLayout="fixed" nzShowTitle="false" nzSize="middle"
            nzNoResult="{{getUnknownText() | translate}}" [nzLoading]="isLoading" [nzData]="data || []"
            [nzShowPagination]="pagination" [nzShowSizeChanger]="pagination" nzPaginationType="default"
            [nzFrontPagination]="pagination" [nzPageIndex]="currentPageIndex"
            (nzPageIndexChange)="handlePageIndexChange($event)" (nzPageSizeChange)="handlePageSizeChange($event)"
            [nzPageSizeOptions]="[5, 10]" [nzPageSize]="pageSize">
            <thead>
                <tr>
                    <th class="bold">{{title | translate}}</th>
                    <ng-container *ngFor="let item of filteredData; let i = index">
                        <th class="bold"
                            [ngClass]="{'draft-entry': !item?.['isSubmitted'], 'first-draft-entry': !item?.['isSubmitted'] && i === 0}">
                            {{getValue(colIdentifier, item[colIdentifier]) |
                            translate}}
                            <span *ngIf="(isEOC()||isInpatientView()) && !item['isSubmitted']  && !isPreview"
                                class="actions">
                                <i nz-icon nzType="icons:delete" nzTheme="outline" class="delete-btn" *ngIf="deleteFun"
                                    (click)="deleteFun(item?.id); $event.stopPropagation()"></i>

                                <i nz-icon nzType="icons:form" nzTheme="outline" class="edit-btn" *ngIf="openModalFun"
                                    (click)="openModal(item); $event.stopPropagation()"></i>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let field of rowFields; let i = index">
                    <tr>
                        <td class="bold">{{rowNames[i] | translate}}
                            <app-tooltip [fieldName]="field" [area]="title" />
                        </td>
                        <ng-container *ngFor="let item of filteredData">
                            <td [ngClass]="{'draft-entry-item': !item?.['isSubmitted']}">
                                <span *ngIf="getValue(field, item[field]) as value">
                                    {{ value | translate }}
                                </span>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>

<div *ngComponentOutlet="addComponent"></div>