<div fxLayout="row" class="allergy-expanded">
  <div fxLayout="col" fxFlex="100" class="allergy-details">
    <nz-layout>
      <nz-header>{{ "Details" | translate }}</nz-header>

      <nz-content>
        <!-- Positive End-Expiratory Pressure (PEEP) -->
        <div
          fxLayout="row"
          class="detail-row"
          *ngIf="item.positiveEndExpiratoryPressure"
        >
          <div fxLayout="col" fxFlex="50" class="detail-label">
            {{ "Positive end-expiratory pressure (PEEP)" | translate }}
          </div>
          <div fxLayout="col" fxFlex="50" class="detail-info">
            {{ item.positiveEndExpiratoryPressure || "-" }}
          </div>
        </div>

        <!-- End Tidal CO2 -->
        <div fxLayout="row" class="detail-row" *ngIf="item.endTidal">
          <div fxLayout="col" fxFlex="50" class="detail-label">
            {{ "End Tidal CO2" | translate }}
          </div>
          <div fxLayout="col" fxFlex="50" class="detail-info">
            {{ item.endTidal || "-" }}
          </div>
        </div>

        <!-- Fraction of Inhaled Oxygen (FiO2) -->
        <div
          fxLayout="row"
          class="detail-row"
          *ngIf="item.fractionInhaledOxygen"
        >
          <div fxLayout="col" fxFlex="50" class="detail-label">
            {{ "Fraction of inhaled oxygen (FiO2)" | translate }}
          </div>
          <div fxLayout="col" fxFlex="50" class="detail-info">
            {{ item.fractionInhaledOxygen || "-" }}
          </div>
        </div>

        <!-- Tidal Volume (Vt) -->
        <div fxLayout="row" class="detail-row" *ngIf="item.tidalVolume">
          <div fxLayout="col" fxFlex="50" class="detail-label">
            {{ "Tidal Volume (Vt)" | translate }}
          </div>
          <div fxLayout="col" fxFlex="50" class="detail-info">
            {{ item.tidalVolume || "-" }}
          </div>
        </div>

        <!-- Respiratory Rate (RR) -->
        <div fxLayout="row" class="detail-row" *ngIf="item.respiratoryRate">
          <div fxLayout="col" fxFlex="50" class="detail-label">
            {{ "Respiratory Rate (RR)" | translate }}
          </div>
          <div fxLayout="col" fxFlex="50" class="detail-info">
            {{ item.respiratoryRate || "-" }}
          </div>
        </div>

        <!-- Pressure of Inhaled Oxygen (Paw) -->
        <div
          fxLayout="row"
          class="detail-row"
          *ngIf="item.pressureInhaledOxygen"
        >
          <div fxLayout="col" fxFlex="50" class="detail-label">
            {{ "Pressure of inhaled oxygen (Paw)" | translate }}
          </div>
          <div fxLayout="col" fxFlex="50" class="detail-info">
            {{ item.pressureInhaledOxygen || "-" }}
          </div>
        </div>
      </nz-content>
    </nz-layout>
  </div>
</div>
