import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { EpisodeOfCareService } from '../services/episodeOfCare.service';
import { IModule } from '../interfaces/IModule';
import { select, Store } from '@ngrx/store';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { MenuService } from '../services/menu.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly EOCservice: EpisodeOfCareService,
    private readonly store: Store,
    private readonly menuService: MenuService
  ) {}

  // TO BE REVIEWED - DUPLICATE MENU CALL
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(selectMenu).pipe(
      switchMap((data) => {
        if (!data || data.length === 0) {
          // If selectMenu is empty, fetch it from menuService
          return this.menuService.getMenu().pipe(
            switchMap((d) => {
              return this.handleNavigation(next, state, d.data);
            }),
            catchError(() => {
              this.router.navigate(['/ehr/error']);
              return of(false);
            })
          );
        } else {
          return this.handleNavigation(next, state, data);
        }
      }),
      catchError(() => {
        this.router.navigate(['/ehr/error']);
        return of(false);
      })
    );
  }

  private handleNavigation(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    data: any
  ): Observable<boolean | UrlTree> {
    const urlSegment = next.url[next.url.length - 1].path;
    if (data && urlSegment) {
      const lastSegment = state.url.split('/').pop();
      if (this.isPathInModules(lastSegment!, data)) {
        return of(true); // Allow navigation
      } else {
        this.router.navigate(['/ehr/error']);
        return of(false);
      }
    } else {
      this.router.navigate(['/ehr/error']);
      return of(false);
    }
  }

  private isPathInModules(path: string, modules: IModule[]): boolean {
    for (const module of modules) {
      if (module.path === path) {
        return true;
      }
      if (module.childModules && module.childModules.length > 0) {
        if (this.isPathInModules(path, module.childModules)) {
          return true;
        }
      }
    }
    return false;
  }
}
