import {
  Component,
  Input,
  SimpleChanges,
  OnInit,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAdmissionAddressInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionAddressInfo';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-admission-address-info',
  templateUrl: './admission-address-info.component.html',
  styleUrls: ['./admission-address-info.component.css'],
})
export class AdmissionAddressInfoComponent implements OnInit, OnChanges {
  @Input() admissionAddressInfoData?: IAdmissionAddressInfo;
  @Input() formSubmitted?: boolean = false;
  @Input() gendersList: ICoding[] = [];
  @Input() bloodTypesList: ICoding[] = [];
  @Input() doctors: NzSelectOptionInterface[] = [];
  @Input() placeOfBirthList: ICoding[] = [];
  @Input() closestRelativesList: ICoding[] = [];
  @Input() educationLevelList: ICoding[] = [];
  @Input() familyStatusList: ICoding[] = [];
  @Input() sourceOfIncomeList: ICoding[] = [];
  @Input() religionList: ICoding[] = [];
  @Input() countriesList: ICoding[] = [];
  @Input() isEditMode: boolean = false;

  addressInfoForm: FormGroup = this.formBuilder.group({});
  isCountryCyprus: boolean = false;

  // Define the Cyprus cities
  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];
  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.addressInfoForm = this.formBuilder.group({
      addressStreet: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      addressTown: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      addressPostCode: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      addressDistrict: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      addressCountryId: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      addressStreetNumber: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
      addressApartmentNumber: [
        {
          value: '',
          disabled: !this.isEditMode,
        },
      ],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['admissionAddressInfoData']) {
      this.initializeForm();
    }
    if (changes['isEditMode']) {
      this.setFormControlsState();
    }
  }

  initializeForm() {
    this.addressInfoForm.patchValue({
      addressStreet: this.admissionAddressInfoData?.addressStreet,
      addressTown: this.admissionAddressInfoData?.addressTown,
      addressPostCode: this.admissionAddressInfoData?.addressPostCode,
      addressDistrict: this.admissionAddressInfoData?.addressDistrict,
      addressCountryId: this.admissionAddressInfoData?.addressCountryId,
      addressStreetNumber: this.admissionAddressInfoData?.addressStreetNumber,
      addressApartmentNumber:
        this.admissionAddressInfoData?.addressApartmentNumber,
    });
  }

  setFormControlsState() {
    if (this.isEditMode) {
      this.addressInfoForm.enable();
    } else {
      this.addressInfoForm.disable();
    }
  }

  // Method to handle place of birth change
  onCountryChange(placeId: number): void {
    this.isCountryCyprus = placeId === 55; // Cyprus id is 55
  }
}
