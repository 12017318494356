import { Component, Input } from '@angular/core';
import { IAdmissionPatientDetailed } from 'src/app/interfaces/AdmissionPatient/IAdmissionPatientDetailed';

@Component({
  selector: 'app-admission-popup',
  templateUrl: './admission-popup.component.html',
  styleUrl: './admission-popup.component.css',
})
export class AdmissionPopupComponent {
  @Input() openModalID: number = -1;
  @Input() patient?: IAdmissionPatientDetailed;

  handleCancel() {
    this.openModalID = -1;
  }
}
