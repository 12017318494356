/* src/app/components/ehr/patients/components/inital-assessment-amen-section/social-history-questonaire/social-history-questonaire.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-layout {
  background: #f4f4f6;
  height: 100%;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
.comorbidity {
}
.content {
  width: 100%;
}
/*# sourceMappingURL=social-history-questonaire.component-FRBEPAQ2.css.map */
