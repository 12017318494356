/* src/app/components/ehr/admission/admission-profile/admission-profile.component.css */
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: rgba(118, 195, 188, 0.8);
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}
.custom-button:hover {
  background-color: #317d88;
}
.information-icon {
  font-size: 24px;
  color: white;
}
.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}
nz-layout {
  background: #f4f4f6;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
::ng-deep .ant-tabs-tab-btn {
  color: black !important;
}
::ng-deep .ant-tabs-card > .ant-tabs-nav ::ng-deep .ant-tabs-tab {
  background: rgba(118, 195, 188, 0.5);
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: 500;
}
::ng-deep .ant-tabs-card > .ant-tabs-nav ::ng-deep .ant-tabs-tab-active {
  background: white;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: 500;
}
::ng-deep .ant-tabs-tab.ant-tabs-tab-active ::ng-deep .ant-tabs-tab-btn {
  color: black !important;
}
::ng-deep .ant-tabs-tab {
  min-width: 200px;
  margin-right: 3px !important;
}
::ng-deep .ant-tabs {
  line-height: 2;
  min-height: 70vh;
}
::ng-deep .ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}
::ng-deep .ant-tabs-content-holder {
  height: 100%;
  padding: 2em 1em;
  border: 1px solid black;
}
.full-height {
  min-height: calc(100vh - 66px) !important;
}
::ng-deep .anticon {
  scale: 1.5 !important;
}
.admission-accept-button {
  background-color: #0d9488 !important;
  border: 1px solid #0d9488 !important;
  color: white !important;
}
.admission-waitinglist-button {
  background-color: rgb(56, 157, 224) !important;
  border: 1px solid rgb(56, 157, 224) !important;
  color: white !important;
}
.admission-edit-button {
  border: 1px solid #317d88 !important;
  background-color: white !important;
  color: #317d88 !important;
  margin-left: 2em;
}
.admission-edit-button:hover {
  color: white !important;
  background-color: #317d88 !important;
}
.admission-reject-button {
  background-color: rgb(202, 79, 79) !important;
  border: 1px solid rgb(202, 79, 79) !important;
  color: white !important;
}
.admission-cancel-button {
  border: 1px solid #ca4f4f !important;
  background-color: white !important;
  color: #ca4f4f !important;
}
.admission-cancel-button:hover {
  color: white !important;
  background-color: #ca4f4f !important;
}
::ng-deep .ant-tabs-content-holder {
  background-color: white;
}
nz-tabset {
  margin-bottom: 3em;
}
::ng-deep .ant-btn {
  border-radius: 4px;
  cursor: pointer;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0d9488;
}
@media only screen and (max-width: 840px) {
  .btn-text {
    display: none !important;
  }
}
.custom-icon:hover svg {
  fill: white !important;
}
.spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
::ng-deep .ant-spin-dot-item {
  background-color: #0d9488 !important;
}
/*# sourceMappingURL=admission-profile.component-BPUXCCK3.css.map */
