import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { ICoding } from 'src/app/interfaces/ICoding';

@Component({
  selector: 'app-medical-info',
  templateUrl: './medical-info.component.html',
  styleUrl: './medical-info.component.css',
})
export class MedicalInfoComponent {
  @Input() formSubmitted?: boolean = false;

  @Input() medicalForm: FormGroup = new FormGroup({});

  @Input() immobilityList: NzSelectOptionInterface[] = [];

  @Input() bloodTypesList: ICoding[] = [];

  @Input() doctors: NzSelectOptionInterface[] = [];

  selectedDoctors: any[] = [];

  constructor(private formBuilder: FormBuilder) {
    this.medicalForm = this.formBuilder.group({
      mohValue: [false],
      immobilityValue: ['', Validators.required],
      bloodtype: [undefined],
      height: [undefined],
      weight: [undefined],
      assignedDoctors: [[], Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['immobilityList']) {
      // None ID - 1
      this.medicalForm.patchValue({
        immobilityValue: this.immobilityList.find((i) => i.value == 1)?.value,
      });
    }
  }

  onDoctorSelectionChange(selectedDoctors: number[]) {
    this.selectedDoctors = selectedDoctors.map((id) => {
      const doctor = this.doctors.find((d) => d.value === id);
      return {
        id: id,
        label: doctor ? doctor.label : '',
        primary: false,
      };
    });
    if (this.selectedDoctors.length > 0) {
      this.selectedDoctors[0].primary = true;
    }
  }

  setPrimaryDoctor(doctorId: number, event: any) {
    this.selectedDoctors.forEach((doc) => {
      doc.primary = doc.id === doctorId;
    });

    if (this.formSubmitted) {
      this.checkPrimaryDoctorSelection();
    }
  }

  checkPrimaryDoctorSelection() {
    const hasPrimaryDoctor = this.selectedDoctors.some((doc) => doc.primary);
    if (!hasPrimaryDoctor) {
      alert('Please select at least one primary doctor.');
    }
  }

  enter(event: any) {
    event.preventDefault();

    const nextButton = document.getElementById(
      'medical-info-next-btn'
    ) as HTMLButtonElement;
    if (nextButton) {
      nextButton.click();
    } else {
      console.warn('Button not found.');
    }
  }
}
