<nz-layout>
  <nz-header class="section-header">
    {{'Medical History'|translate}}
  </nz-header>
  <nz-content>
    <ng-container *ngFor="let cm of submenu">
      <app-vaccination *ngIf="cm.path==componentPaths.vaccinations" [id]="cm.path" />
      <app-resolved-problems *ngIf="cm.path==componentPaths.resolvedProblems" [id]="cm.path" />
      <app-medical-history *ngIf="cm.path==componentPaths.medicalHistory" [id]="cm.path" />
    </ng-container>
  </nz-content>
</nz-layout>