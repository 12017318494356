/* src/app/components/ehr/patients/components/laboratory-section/laboratory-result-section/laboratory-result-section.component.css */
.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #f4f4f6;
  background: #f4f4f6;
  padding: 0px !important;
}
nz-layout {
  background: #f4f4f6;
}
.margint {
  margin-top: 1em;
}
/*# sourceMappingURL=laboratory-result-section.component-2VCTZ3JQ.css.map */
