/* src/app/components/ehr/dashboard/dashboard-bed-availability-by-day/dashboard-bed-availability-by-day.component.css */
#divChart {
  display: flex;
  width: 100% !important;
  height: 40vh;
}
.chart-container {
  border-radius: 8px;
  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 10px;
  background-color: white;
}
#bedAvailabilityByDay {
  width: 100%;
  height: 40vh;
}
/*# sourceMappingURL=dashboard-bed-availability-by-day.component-N6VBNTQ6.css.map */
