<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
  [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Comorbidities') | translate" (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class cormobidities-wrap">
  <ng-container *nzModalContent>
    <form [formGroup]="comorbidityForm" (ngSubmit)="submitForm()"
      [ngClass]="{'comorbidity-submitted': comorbiditySubmitted, 'comorbidity-not-submitted': !comorbiditySubmitted}">

      <!-- Date -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Date" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs laboratory" type="date" [placeholder]="'Date' | translate" formControlName="dateValue"
            [max]="getCurrentDate()">
        </div>
      </div>

       <!-- Median (IQR) -->
       <!-- <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Median (IQR)" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs laboratory" type="number" [placeholder]="'Median (IQR) ...' | translate"
            formControlName="iqrValue" />
        </div>
      </div> -->

      <!-- No Comorbidities -->
      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <label nz-checkbox formControlName="noComorbiditiesValue">{{"No Comorbidities" | translate}}</label>
        </div>
      </div>
        
      <!-- Respiratory disease -->
      <div fxLayout="column">
        <!-- Pair 1: Respiratory disease and Hypertension -->
        <div fxLayout="row" fxLayoutAlign="space-between">
          <!-- Column 1 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="respiratoryDiseaseValue">{{"Respiratory disease" | translate}}</label>
              </div>
            </div>
          </div>
          <!-- Column 2 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="hypertensionValue">{{"Hypertension" | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <!-- Pair 2: COPD and Heart Disease -->
        <div fxLayout="row" fxLayoutAlign="space-between">
          <!-- Column 1 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="copdValue">{{"COPD" | translate}}</label>
              </div>
            </div>
          </div>
          <!-- Column 2 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="heartDiseaseValue">{{"Heart Disease" | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <!-- Pair 3: Diabetes mellitus type II and Malignancy -->
        <div fxLayout="row" fxLayoutAlign="space-between">
          <!-- Column 1 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="diabetesMellitusValue">{{"Diabetes mellitus type II" | translate}}</label>
              </div>
            </div>
          </div>
          <!-- Column 2 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="malignancyValue">{{"Malignancy" | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <!-- Pair 4: Stroke and Immunosuppression -->
        <div fxLayout="row" fxLayoutAlign="space-between">
          <!-- Column 1 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="strokeValue">{{"Stroke" | translate}}</label>
              </div>
            </div>
          </div>
          <!-- Column 2 -->
          <div fxLayout="column" fxFlex="calc(50% - 10px)"> <!-- Adjust width as needed -->
            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                <label nz-checkbox formControlName="immunosuppressionValue">{{"Immunosuppression" | translate}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

            

      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
          [disabled]="isLoading">{{"Save" | translate}}</button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
          [disabled]="isLoading">{{"Cancel" | translate}}</button>
      </div>
    </form>
  </ng-container>
</nz-modal>
