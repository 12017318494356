<form [formGroup]="formGroup">
  <ng-container *ngFor="let element of elements">
    <div *ngIf="element.type === 'text'">
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div
          fxLayout="col"
          fxFlex="100"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <!--  <b class="title">{{ element.title }}</b> -->
          <b class="title">{{ element.title }}</b>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="100" class="add-patient-col">
          <input
            type="text"
            class="add-patient-input"
            [formControlName]="element.name"
          />
        </div>
      </div>
    </div>

    <div *ngIf="element.type === 'boolean'">
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div
          fxLayout="col"
          fxFlex="100"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <b class="title">{{ element.title }}</b>
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="start start"
        class="add-patient-row radio-button-list"
      >
        <div fxLayout="col" fxFlex="100" class="add-patient-col">
          <label class="radio-label">
            <input
              type="radio"
              [formControlName]="element.name"
              [value]="false"
            /><span>{{ element.labelFalse }}</span>
          </label>
        </div>
        <div fxLayout="col" fxFlex="100" class="add-patient-col">
          <label class="radio-label">
            <input
              type="radio"
              [formControlName]="element.name"
              [value]="true"
            /><span>{{ element.labelTrue }}</span>
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="element.type === 'comment'">
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div
          fxLayout="col"
          fxFlex="100"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <b class="title">{{ element.title }}</b>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="100" class="add-patient-col">
          <textarea
            class="add-patient-input textarea-input"
            [formControlName]="element.name"
          ></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="element.type === 'radiogroup'">
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div
          fxLayout="col"
          fxFlex="100"
          class="add-patient-col"
          fxLayoutAlign="start center"
        >
          <b class="title">{{ element.title }}</b>
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="start start"
        class="add-patient-row radio-button-list"
      >
        <div
          fxLayout="col"
          fxFlex="100"
          class="add-patient-col"
          *ngFor="let choice of element.choices"
        >
          <label class="radio-label">
            <input
              type="radio"
              [formControlName]="element.name"
              [value]="choice.value"
            /><span>{{ choice.text }}</span>
          </label>
        </div>
      </div>
    </div>
  </ng-container>
</form>
