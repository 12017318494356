<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
  [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Travel History') | translate" (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="travelHistoryForm" (ngSubmit)="submitForm()"
      [ngClass]="{'travelHistory-submitted': travelHistorySubmitted, 'travelHistory-not-submitted': !travelHistorySubmitted}">

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Arrival Date" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs travelHistory" type="date" [placeholder]="'Arrival Date...' | translate"
            formControlName="arrivalDateValue" [max]="this.travelHistoryForm.value?.departureDateValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Departure Date" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs travelHistory" type="date" [placeholder]="'Departure Date...' | translate"
            formControlName="departureDateValue" [min]="this.travelHistoryForm.value?.arrivalDateValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Travel Country" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs travelHistory" nzShowSearch nzAllowClear [nzPlaceHolder]="'Travel Country' | translate"
            formControlName="countryValue" [nzLoading]="isLoading">
            <ng-container *ngFor="let sp of travelHistoryDropdown?.countries">
              <nz-option [nzLabel]="sp.name" [nzValue]="sp.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
          [disabled]="isLoading">{{"Save" | translate}}</button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
          [disabled]="isLoading">{{"Cancel" | translate}}</button>
      </div>
    </form>
  </ng-container>
  <ng-template>
    <button type="submit">{{"Save" | translate}}</button>
  </ng-template>
</nz-modal>