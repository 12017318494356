import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IServiceResponse } from '../interfaces/IServiceResponse';

@Injectable()
export class AccountService {
  constructor(private _http: HttpClient) {}

  forgotUsername(email: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post<IServiceResponse>(
      `${environment.BACKEND_URL}Keycloak/ForgotUsername/${email}`,
      {
        headers: headers,
      }
    );
  }
}
