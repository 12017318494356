/* src/app/components/ehr/logout/logout.component.css */
.layout {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.resetPassword {
  background-color: #ffd470;
}
.resetPassword,
.logout {
  display: flex;
  align-items: center;
  width: 100%;
  color: #333333 !important;
}
.resetPassword:hover {
  background-color: #d9d9d9 !important;
}
.logout:hover {
  background-color: #fcd6d6 !important;
}
.mat-icon {
  margin-right: 0.5em;
}
::ng-deep .mat-mdc-menu-panel {
  padding: 10px 10px !important;
}
/*# sourceMappingURL=logout.component-NVJBLGAU.css.map */
