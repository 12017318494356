<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Tube Characteristics') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="tubeCharacteristicsForm" (ngSubmit)="submitForm()"
            [ngClass]="{'tubeCharacteristics-submitted': tubeCharacteristicsSubmitted, 'tubeCharacteristics-not-submitted': !tubeCharacteristicsSubmitted}">

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Tube Length" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs tubeCharacteristics" nzShowSearch nzAllowClear [nzPlaceHolder]="'Tube Length' | translate"
                        formControlName="tubeLengthValue" [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let eo of dropdown?.tubeLength">
                            <nz-option [nzLabel]="eo.value" [nzValue]="eo.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Outer Diameter" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs tubeCharacteristics" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Outer Diameter' | translate" formControlName="outerDiameterValue"
                        [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let vr of dropdown?.outerDiameter">
                            <nz-option [nzLabel]="vr.value" [nzValue]="vr.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Inner Diameter" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs tubeCharacteristics" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Inner Diameter' | translate" formControlName="innerDiameterValue"
                        [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let vr of dropdown?.innerDiameter">
                            <nz-option [nzLabel]="vr.value" [nzValue]="vr.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Cuff Diameter" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs tubeCharacteristics" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Cuff Diameter' | translate" formControlName="cuffDiameterValue"
                        [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let vr of dropdown?.cuffDiameter">
                            <nz-option [nzLabel]="vr.value" [nzValue]="vr.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Consistency of Tube" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs tubeCharacteristics" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Consistency of Tube ' | translate" formControlName="consistencyOfTubeValue"
                        [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let vr of dropdown?.consistencyOfTube">
                            <nz-option [nzLabel]="vr.value" [nzValue]="vr.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Tube Type" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs tubeCharacteristics" nzShowSearch nzAllowClear [nzPlaceHolder]="'Tube Type' | translate"
                        formControlName="tubeTypeValue" [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let vr of dropdown?.tubeType">
                            <nz-option [nzLabel]="vr.name" [nzValue]="vr.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
    <ng-template>
        <button type="submit">{{"Save" | translate}}</button>
    </ng-template>
</nz-modal>