<nz-layout>
    <nz-header *ngIf="submenu?.length!<=1">
    </nz-header>
    <nz-header *ngIf="submenu?.length!>1" class="section-header">
        {{'Laboratory'|translate}}
    </nz-header>
    <nz-content>
        <ng-container *ngFor="let cm of submenu">
            <app-laboratory-file *ngIf="cm.path==componentPaths.laboratoryFiles" [id]="cm.path" />
            <app-laboratory-result-section *ngIf="cm.path==componentPaths.laboratoryResults" [id]="cm.path" />
        </ng-container>
    </nz-content>
</nz-layout>