<nz-layout>
    <nz-header>
        <div nzLayout="row">
            <div nzLayout="col" fxFlex="50">{{"Visits" | translate}}</div>
            <div nzLayout="col" fxFlex="50" fxLayoutAlign="end center" *ngIf="item.status=='Active' && !isReadOnly">
                <button nz-button (click)="item.id &&createNewVisit(item.id)" [nzLoading]="isVisitloading"><span nz-icon
                        nzType="icons:plus" nzTheme="outline"></span>
                    {{"New Visit" | translate}}</button>
            </div>
        </div>
    </nz-header>
    <nz-content>
        <nz-table #nzTable [nzData]="item.visits || []" nzTableLayout="fixed" nzShowTitle="false" nzSize="middle"
            nzNoResult="{{'No information for' | translate}} {{'Visits' | translate}}" class="visits-table">
            <thead>
                <tr>
                    <ng-container>
                        <th>{{"Potential Diagnosis" | translate}}
                        </th>

                        <th>{{"Date" | translate}}
                        </th>

                        <th>{{"Notes" | translate}}
                        </th>

                        <th>{{"Status" | translate}}
                        </th>


                        <th *ngIf="isAnyVisitIncomplete() && !isReadOnly" nzWidth="90px">{{"Actions" | translate}}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let visit of item.visits">
                    <tr>
                        <ng-container>
                            <td>{{ visit["potentialDiagnosis"] || "-"}}</td>
                        </ng-container>

                        <ng-container>
                            <td>{{ visit["date"] || "-"}}</td>
                        </ng-container>
                        <ng-container>
                            <td>{{ visit["notes"] || "-"}}</td>
                        </ng-container>

                        <ng-container>
                            <td>{{ visit["isCompleted"] ? "Completed" : "Draft"}}</td>
                        </ng-container>

                        <ng-container *ngIf="!visit.isCompleted && visit.isEditable  && !isReadOnly">
                            <td><i nz-icon nzType="icons:delete" nzTheme="outline" class="discard-visit"
                                    (click)="discardVisit(visit.id!)"></i>
                                <i nz-icon nzType="icons:form" nzTheme="outline" class="open-visit"
                                    (click)="openVisit(item.id!, visit.id!)"></i>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>