<div class="multiple-sclerosis" #scrollContainer>
    <nz-layout>
        <nz-header>
            {{'Multiple Sclerosis Segmentation'|translate}}
        </nz-header>
        <nz-content>
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="70">
                    <nz-select type="multiple" nzShowSearch nzAllowClear
                        [nzPlaceHolder]="'Select Imaging Record to be segmented' | translate"
                        [nzOptions]="imagingRecords" [(ngModel)]="selectedImaging">
                    </nz-select>
                </div>

                <div fxLayout="column" fxFlex="10">

                </div>
                <div fxLayout="column" fxFlex="20">
                    <button nz-button (click)="segment()"
                        [disabled]="!selectedImaging || isLoading">{{'Segment'|translate}}</button>
                </div>
            </div>

            <div>
                <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center" class="ms-spinner"><nz-spin
                        nzSimple></nz-spin>
                </div>
                <div *ngIf="segmentationResult?.creationDate" class="creation-date" fxLayoutAlign="center center">
                    <div>{{'Creation Date'|translate}}: {{segmentationResult?.creationDate | date:'yyyy/MM/dd'}}</div>
                </div>
                <ng-container *ngFor="let result of segmentationResult?.predictionAiResponse">
                    <div fxLayout="row" fxLayoutAlign="center center" class="segmentation-results">
                        <div fxLayout="column" fxFlex="30">
                            <div fxLayoutAlign="center center">{{"Processed Image"|translate}}</div>
                            <img nz-image [nzSrc]="'data:image/png;base64,' + result.processedImage" nzWidth="auto"
                                nzHeight="auto" class="result-img" />
                        </div>
                        <div fxLayout="column" fxFlex="5">
                        </div>
                        <div fxLayout="column" fxFlex="30">
                            <div fxLayoutAlign="center center">{{"Prediction Mask"|translate}}</div>
                            <img nz-image [nzSrc]="'data:image/png;base64,' + result.predictionImage" nzWidth="auto"
                                nzHeight="auto" class="result-img" />
                        </div>
                        <div fxLayout="column" fxFlex="5">
                        </div>
                        <div fxLayout="column" fxFlex="30">
                            <div fxLayoutAlign="center center">{{"Overlay"|translate}}</div>
                            <img nz-image [nzSrc]="'data:image/png;base64,' + result.contourImage" nzWidth="auto"
                                nzHeight="auto" class="result-img" />
                        </div>
                    </div>
                </ng-container>
            </div>

        </nz-content>
    </nz-layout>
</div>