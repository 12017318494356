<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
    [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Glasgow Coma Scale') | translate" (nzOnCancel)="handleCancel()"
    nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
    <ng-container *nzModalContent>
        <form [formGroup]="glasgowForm" (ngSubmit)="submitForm()"
            [ngClass]="{'glasgow-submitted': glasgowSubmitted, 'glasgow-not-submitted': !glasgowSubmitted}">


            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Onset Date & Time" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70" fxLayoutAlign="start center">
                    <input class="inputs glasgow" type="datetime-local" [placeholder]="'Date' | translate" formControlName="dateValue"
                        [max]="getCurrentDateTime()" />
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Eye Opening" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs glasgow" nzShowSearch nzAllowClear [nzPlaceHolder]="'Eye Opening' | translate"
                        formControlName="eyeOpeningValue" [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let eo of dropdown?.eyeOpening">
                            <nz-option [nzLabel]="eo.name" [nzValue]="eo.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Verbal Response" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs glasgow" nzShowSearch nzAllowClear [nzPlaceHolder]="'Verbal Response' | translate"
                        formControlName="verbalResponseValue" [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let vr of dropdown?.verbalResponse">
                            <nz-option [nzLabel]="vr.name" [nzValue]="vr.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
                    <label>{{"Motor Response" | translate}}</label>
                </div>
                <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
                    <nz-select class="inputs glasgow" nzShowSearch nzAllowClear [nzPlaceHolder]="'Motor Response' | translate"
                        formControlName="motorResponseValue" [nzLoading]="isDropdownLoading">
                        <ng-container *ngFor="let vr of dropdown?.motorResponse">
                            <nz-option [nzLabel]="vr.name" [nzValue]="vr.id"></nz-option>
                        </ng-container>
                    </nz-select>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                    [disabled]="isLoading">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
                    [disabled]="isLoading">{{"Cancel" | translate}}</button>
            </div>
        </form>
    </ng-container>
    <ng-template>
        <button type="submit">{{"Save" | translate}}</button>
    </ng-template>
</nz-modal>