import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { IDynamicDropdown } from 'src/app/interfaces/DynamicTable/IDynamicDropdown';
import { DynamicService } from 'src/app/services/dynamic.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-laboratory-result-section',
  templateUrl: './laboratory-result-section.component.html',
  styleUrls: ['./laboratory-result-section.component.css'],
})
export class LaboratoryResultSectionComponent {
  endpoint = 'Laboratory';

  public data: IDynamicDropdown[] = [];

  constructor(private readonly dynamicService: DynamicService) {}

  ngOnInit() {
    this.dynamicService.getDynamicDropdown(this.endpoint).subscribe({
      next: (data) => {
        this.data = data.data;
      },
      error: (err) => {
        Swal.fire({
          text: 'Unable to retrieve laboratory data!',
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
      },
    });
  }
}
