import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IAdmissionPatientDetailed } from '../interfaces/AdmissionPatient/IAdmissionPatientDetailed';
import { IAdmissionDropdown } from '../interfaces/AdmissionPatient/IAdmissionDropdown';
import { ISearchAdmission } from '../interfaces/AdmissionPatient/ISearchAdmission';
import { IAdmissionFile } from '../interfaces/AdmissionPatient/AdmissionProfile/IAdmissionFile';
import { IRejectPatientObj } from '../interfaces/AdmissionPatient/IRejectPatientObj';
import { INotes } from '../interfaces/AdmissionPatient/INotes';
import { IAddInPatientEOC } from '../interfaces/AdmissionPatient/IAddInPatientEOC';
import { ICreateEpisodeOfCare } from '../interfaces/ICreateEpisodeOfCare';

@Injectable({
  providedIn: 'root',
})
export class AdmissionService {
  constructor(private _http: HttpClient) {}

  getAdmissionPatients(
    paramsObj: ISearchAdmission,
    pageSize: number = 10,
    pageNumber: number = 1
  ): Observable<IGenericServiceResponse<IAdmissionPatientDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IAdmissionPatientDetailed[]>>(
      `${environment.BACKEND_URL}PatientRegistration/SearchAdmission/${pageNumber}/${pageSize}`,
      {
        headers: headers,
        params: new HttpParams({ fromObject: paramsObj as any }),
      }
    );
  }

  getDropdownData(): Observable<IGenericServiceResponse<IAdmissionDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IAdmissionDropdown>>(
      `${environment.BACKEND_URL}PatientRegistration/AdmissionDropdownData`,
      {
        headers: headers,
      }
    );
  }

  waitlistPatient(patientId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}PatientRegistration/WaitingList/${patientId}`,
      {
        headers: headers,
      }
    );
  }

  rejectPatient(patientId: number, rejectObj: IRejectPatientObj) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}PatientRegistration/Reject/${patientId}`,
      rejectObj,
      {
        headers: headers,
      }
    );
  }

  admitPatient(
    patientId: number,
    formObj: IAddInPatientEOC
  ): Observable<IGenericServiceResponse<ICreateEpisodeOfCare>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put<IGenericServiceResponse<ICreateEpisodeOfCare>>(
      `${environment.BACKEND_URL}PatientRegistration/Approved/${patientId}`,
      formObj,
      {
        headers: headers,
      }
    );
  }
}
