/* src/app/components/ehr/not-found/not-found.component.css */
.not-found-container {
  background-color: #f4f4f6;
  min-height: calc(100vh - 66px);
}
::ng-deep .not-found-icon > svg {
  height: 100px;
  width: 100%;
}
.not-found-text {
  font-size: 18px;
}
/*# sourceMappingURL=not-found.component-V7FN3ZJC.css.map */
