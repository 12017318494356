<div>
    <div fxLayout="row" fxLayout.lt-lg="column">
        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" class="left-half">
            <img src="../../../assets/images/loginScreen/loginBuilding.png" class="left-image">
        </div>


        <div fxLayout="column" fxLayout.lt-lg="row" fxFlex="50" fxFlex.lt-lg="100" fxLayoutAlign="center center">

            <div fxLayout="column" fxFlex="60">
                <div fxLayout="row" class="w-100 forgot-line" fxLayoutAlign="center center">
                    <img src="../../../assets/images/loginScreen/3aHealth.png" class="logo">
                </div>


                <div fxLayout="row" class="w-100 login-text" fxLayoutAlign="start start">
                    {{"Forgot Password"|translate}}
                </div>

                <input nz-input type="text" class="email" placeholder="Email" />

                <div fxLayout="row" fxLayoutGap="20px">
                    <div fxLayout="column" fxFlex="50">
                        <a href="/newlogin">
                            <button nz-button class="cancel-btn">{{"Cancel"|translate}}</button></a>
                    </div>
                    <div fxLayout="column" fxFlex="50">
                        <button nz-button class="submit-btn">{{"Submit"|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>