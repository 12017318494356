import { Component, input, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAddDocument } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAddDocument';
import { IAdmissionFile } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionFile';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrl: './document.component.css',
})
export class DocumentComponent {
  @Input() document?: IAdmissionFile;
  @Input() fileTypeId?: string;
  @Input() isEditMode: boolean = false;
  patientId: string = '';

  constructor(
    private readonly patientService: PatientService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.patientId = params['patientId'];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['admissionPersonalInfoData']) {
      // this.initializeForm();
    }
    if (changes['isEditMode']) {
      // this.setFormControlsState();
    }
  }

  downloadDocument() {
    this.patientService.downloadDocument(
      this.document?.filePath!,
      this?.document?.id.toString()!,
      this.patientId
    );
  }

  deleteDocument() {
    Swal.fire({
      title: `${this.translate.instant(
        'Are you sure you want to delete this document?'
      )}`,
      text: `${this.translate.instant(
        'You will not be able to revert this change.'
      )}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0d9488',
      cancelButtonColor: '#d33',
      confirmButtonText: `${this.translate.instant('Delete Document')}`,
      cancelButtonText: `${this.translate.instant('Keep File or Cancel')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.patientService
          .deleteDocument(this?.document?.id.toString()!, this.patientId)
          .subscribe({
            next: (res) => {
              Swal.fire(
                {
                  title: `${this.translate.instant(
                    "Deleted!"
                  )}`,
                  text: `${this.translate.instant(
                    "Document has been deleted."
                  )}`,
                  icon: 'success'
                }
              );
              this.patientService.getFiles(this.patientId); // Refresh the files list
            },
            error: (err) => {
              Swal.fire(
                {
                  title: `${this.translate.instant(
                    "Error!"
                  )}`,
                  text: `${this.translate.instant(
                    "There was an error deleting the document."
                  )}`,
                  icon: 'error'
                }
              );
              console.error('Error deleting document:', err);
            },
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: `${this.translate.instant(
            "Cancelled"
          )}`,
          text: `${this.translate.instant(
            "Your document has NOT been deleted"
          )}`,
          icon: 'info',
          confirmButtonColor: '#0d9488',
        });
      }
    });
  }

  onFileSelected(event: any) {
    const file: File | null = event.target.files[0];

    if (file) {
      const documentObj: IAddDocument = {};
      documentObj.file = file;
      documentObj.fileTypeId = this.fileTypeId;
      documentObj.patientId = this.patientId;

      this.patientService.addDocument(documentObj).subscribe({
        next: (res) => {
          Swal.fire(
          {
            text: `${this.translate.instant(
              "Document added successfully!"
            )}`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#0d9488',
            timer: 3000,
          });

          this.patientService.getFiles(this.patientId);
        },
        error: (err) => {
          Swal.fire({
            text: `${this.translate.instant(
              "Unable to upload document!"
            )}`,
            toast: true,
            position: 'bottom-end',
            showCancelButton: false,
            showConfirmButton: false,
            color: 'white',
            background: '#ff6969',
            timer: 3000,
          });
          console.error('Error adding document:', err);
        },
      });
    }
  }
}
