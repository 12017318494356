import { Component } from '@angular/core';

@Component({
  selector: 'app-newreset',
  templateUrl: './newreset.component.html',
  styleUrl: './newreset.component.css',
})
export class NewresetComponent {
  passwordFieldType: string = 'password';
  passwordFieldType2: string = 'password';

  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  togglePasswordVisibility2(): void {
    this.passwordFieldType2 =
      this.passwordFieldType2 === 'password' ? 'text' : 'password';
  }
}
