<nz-layout>
    <nz-header>
        {{"Tracheostomy" | translate}}
    </nz-header>
    <nz-content>
        <nz-tabset nzType="card">

            <nz-tab [nzTitle]="titleTemplate1" *ngIf="isInpatientView()">
                <ng-template #titleTemplate1>
                    <i nz-icon nzType="icons:assessment"></i>{{"Assessment" | translate}}
                </ng-template>
                <app-assessment-section />
            </nz-tab>


            <nz-tab [nzTitle]="titleTemplate">
                <ng-template #titleTemplate>
                    <i nz-icon nzType="icons:patientCharacteristics"></i>{{"Patient Characteristics" | translate}}
                </ng-template>
                <app-social-history />
                <app-comorbidity />
            </nz-tab>



            <nz-tab [nzTitle]="titleTemplate2" *ngIf="isInpatientView()">
                <ng-template #titleTemplate2>
                    <i nz-icon nzType="icons:etiology"></i>{{"Etiology" | translate}}
                </ng-template>
                <app-etiology />
            </nz-tab>

            <nz-tab [nzTitle]="titleTemplate3">
                <ng-template #titleTemplate3>
                    <i nz-icon nzType="icons:laboratory"></i>
                    {{"Laboratory" | translate}}
                </ng-template>
                <app-laboratory-file />
                <app-laboratory-result-section />
            </nz-tab>

            <nz-tab [nzTitle]="titleTemplate4">
                <ng-template #titleTemplate4>
                    <i nz-icon nzType="icons:parameters"></i>
                    {{"3D-CT Parameters" | translate}}
                </ng-template>
                <app-ct-parameters />
            </nz-tab>

            <nz-tab [nzTitle]="titleTemplate5">
                <ng-template #titleTemplate5>
                    <i nz-icon nzType="icons:tube"></i>{{"Tube Characteristics" | translate}}
                </ng-template>
                <app-tube-characteristics />
            </nz-tab>

            <nz-tab [nzTitle]="titleTemplate6">
                <ng-template #titleTemplate6>
                    <i nz-icon nzType="icons:mri"></i>{{"Imaging" | translate}}
                </ng-template>
                <app-imaging-files />
                <app-multiple-sclerosis-segmentation />
            </nz-tab>
        </nz-tabset>

    </nz-content>
</nz-layout>