import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { MedicalAlertService } from '../../../../../../services/medicalAlert.service';
import { IMedicalInfoDetailed } from '../../../../../../interfaces/MedicalInfo/IMedicalInfoDetailed';
import { MedicalAlertAddEditComponent } from './medical-alert-add-edit/medical-alert-add-edit.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-medical-alert',
  templateUrl: './medical-alert.component.html',
  styleUrls: ['./medical-alert.component.css'],
})
export class MedicalAlertComponent {
  public addComp = MedicalAlertAddEditComponent;

  private destroy$ = new Subject<void>();

  medicalAlerts?: IMedicalInfoDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly medicalAlertService: MedicalAlertService,
    private readonly translate: TranslateService
  ) {}

  openModalFun(item?: IMedicalInfoDetailed) {
    this.medicalAlertService.medicalAlertModalSub.next(true);
    this.medicalAlertService.medicalAlertEditSub.next(item);
  }

  deleteFun(medicalAlertId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Medical Alert?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.medicalAlertService
          .deleteMedicalAlert(medicalAlertId.toString())
          .subscribe({
            next: (d) => {
              Swal.fire({
                text: 'Medical Alert deleted successfully!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });

              this.medicalAlertService.deleteMedicalAlertFromTable(
                medicalAlertId.toString()
              );
            },
            error: (err) => {
              Swal.fire({
                text: 'Unable to delete Medical Alert!',
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire('Medical alert is not deleted', '', 'info');
      }
    });
  }
  ngOnInit() {
    this.medicalAlertService.medicalAlertLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.medicalAlertService.medicalAlertDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IMedicalInfoDetailed[]) => {
        this.medicalAlerts = data;
      });

    this.medicalAlertService.getData();
  }
}
