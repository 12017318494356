import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IModule } from '../interfaces/IModule';
import { ILoginResponse } from '../interfaces/Keycloak/ILoginResponse';
import { ILogin } from '../interfaces/Keycloak/ILogin';

@Injectable()
export class OurKeycloakService {
  constructor(private _http: HttpClient) {}

  login(obj: ILogin): Observable<IGenericServiceResponse<ILoginResponse>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post<IGenericServiceResponse<ILoginResponse>>(
      `${environment.BACKEND_URL}Keycloak/Login`,
      obj,
      {
        headers: headers,
      }
    );
  }
}
