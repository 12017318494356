<nz-layout>
  <nz-content>
    <ng-container>
      <survey
        [model]="model"
        style="position: relative; top: 0; left: 0; right: 0; bottom: 0"
      ></survey>
    </ng-container>
  </nz-content>
</nz-layout>
