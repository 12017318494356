import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Subject, combineLatest, debounceTime } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { CurrentProblemsService } from 'src/app/services/currentProblems.service';
import { MedicalDeviceService } from 'src/app/services/medicalDevice.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-medical-problems-section',
  templateUrl: './medical-problems-section.component.html',
  styleUrls: ['./medical-problems-section.component.css'],
})
export class MedicalProblemsSectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: number;
  @Output() selectedOptionChange = new EventEmitter<number>();

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.currentProblemsService.currentProblemLoadingSub$,
        this.medicalDeviceService.medicalDeviceLoadingSub$,
        this.procedureService.procedureLoadingSub$,
      ]).subscribe(([value1, value2, value3]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.id == this.selectedOption
        )?.path;
        if (!value1 && !value2 && !value3 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const cp = document.getElementById(paths.currentProblems);
                const di = document.getElementById(paths.devicesAndImplants);
                const pr = document.getElementById(paths.procedures);

                if (pr) {
                  const rect = pr.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.procedures
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (di) {
                  const rect = di.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.devicesAndImplants
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (cp) {
                  const rect = cp.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.currentProblems
                    )?.id;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly procedureService: ProcedureService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
