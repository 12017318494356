<nz-modal #modalRef nzSize [(nzVisible)]="isVisible"
  [nzTitle]="((isEdit ? 'Edit' : 'Add') + ' Social History') | translate" (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null" class="custom-modal-class">
  <ng-container *nzModalContent>
    <form [formGroup]="socialHistoryForm" (ngSubmit)="submitForm()"
      [ngClass]="{'social-submitted': socialHistorySubmitted, 'social-not-submitted': !socialHistorySubmitted}">

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Social History" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs social" nzShowSearch nzAllowClear [nzPlaceHolder]="'Social History' | translate"
            formControlName="socialHistoryIdValue" [nzLoading]="isLoading">
            <ng-container *ngFor="let et of socialHistoryDropdown">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>


      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Timing Unit" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <nz-select class="inputs social" nzShowSearch nzAllowClear [nzPlaceHolder]="'Timing Unit' | translate"
            formControlName="customTimingUnitIdValue" [nzLoading]="isLoading">
            <ng-container *ngFor="let et of timingUnitDropdown">
              <nz-option [nzLabel]="et.name" [nzValue]="et.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Unit Amount" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <input nz-input class="inputs social" type="number" [placeholder]="'Unit Amount ...' | translate"
            formControlName="unitAmountValue" />
        </div>
      </div>

      <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="30">
          <label>{{"Description" | translate}}</label>
        </div>
        <div fxLayout="col" fxLayoutAlign="start center" fxFlex="70">
          <textarea nz-input rows="4" class="inputs social" [placeholder]="'Description ...' | translate"
            formControlName="descriptionValue"></textarea>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end" class="footer">
        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
          [disabled]="isLoading">{{"Save" | translate}}</button>
        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleCancel()"
          [disabled]="isLoading">{{"Cancel" | translate}}</button>
      </div>
    </form>
  </ng-container>
</nz-modal>